import { faArrowLeft, faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import noPicture from 'assets/images/no_picture.svg';
import Flex from 'components/ui/Flex';
import Image from 'components/ui/Image';
import Text from 'components/ui/Text';
import useAppSelector from 'hooks/useAppSelector';
import useBreakPoints from 'hooks/useBreakPoints';
import { ImageExtension, ProductImage } from 'microshop-api';
import { useRef, useState } from 'react';
import ReactImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { selectActiveImages, selectProductDetails } from 'store/reducers/productSlice';
import styled from 'styled-components';
import getImage, { ImageType } from 'utils/getImage';

type ReactImageGalleryState = {
    isFullscreen: boolean;
};

interface ProductImagesProps {
    onImageChange?: (currentIndex: number) => void;
    showVariation?: boolean;
}

const ProductImages: React.FC<ProductImagesProps> = ({ onImageChange, showVariation }) => {
    const productDetails = useAppSelector(selectProductDetails);
    const images = useAppSelector(selectActiveImages);
    const [showThumbs, setShowThumbs] = useState(false);
    const galleryRef = useRef<ReactImageGallery>(null);
    const bp = useBreakPoints();

    const { variation, product } = productDetails;

    const handleClick = (e: any) => {
        const galleryState = galleryRef.current?.state as ReactImageGalleryState;

        if (bp.md && !galleryState.isFullscreen) {
            galleryRef.current?.fullScreen();
        } else if (galleryState.isFullscreen) {
            galleryRef.current?.exitFullScreen();
        }
    };
    const handleAngleClick = (img: GalleryLargeThumb) => {
        galleryRef.current?.slideToIndex(img.galleryIndex);
    };

    const handleBigImageClick = (img: GalleryLargeThumb) => {
        galleryRef.current?.fullScreen();
        galleryRef.current?.slideToIndex(img.galleryIndex);
    };

    if (!images?.length) return <Image alt="" src={noPicture} />;

    const [galleryImages, angleImages, bigImage] = formatImages(images, !!variation);

    return (
        <GalleryContainer isFullscreenAllowed={bp.md} hideAngleThumbs={angleImages.length > 1}>
            {showVariation && variation && (
                <ColorName className="py-3 px-4">
                    <Text light itemProp="" className="f1-500">
                        {variation.color || ' '}
                    </Text>
                    <Text small light>
                        {variation.colorCode && `(${variation?.colorCode})`}
                    </Text>
                </ColorName>
            )}

            <ReactImageGallery
                disableKeyDown
                ref={galleryRef}
                showBullets={images.length > 1}
                onScreenChange={(full) => setShowThumbs(full)}
                showThumbnails={showThumbs}
                onClick={handleClick}
                onBeforeSlide={(currentIndex: number) => (onImageChange ? onImageChange(currentIndex) : null)}
                items={galleryImages}
                showFullscreenButton={false}
                showPlayButton={false}
                lazyLoad
                disableThumbnailScroll={!showThumbs}
                renderLeftNav={(onClick, disabled) => {
                    return (
                        <NavButton onClick={onClick}>
                            <FontAwesomeIcon size="2x" icon={faArrowLeft} />
                        </NavButton>
                    );
                }}
                renderRightNav={(onClick, disabled) => {
                    return (
                        <NavButton onClick={onClick} right>
                            <FontAwesomeIcon size="2x" icon={faArrowRight} />
                        </NavButton>
                    );
                }}
            />
            {angleImages.length > 1 && (
                <AngleImages>
                    {bp.md &&
                        angleImages.map((img, i) =>
                            i < 4 && img.fileName ? (
                                <AngleImage
                                    className="pointer"
                                    key={img.galleryIndex}
                                    align="center"
                                    justify="center"
                                    onClick={() => handleAngleClick(img)}
                                >
                                    <Image
                                        alt={product?.productName || '' + variation?.variationNumber || '' + img.angle}
                                        src={getImage(img, ImageType.ThumbNail, ImageExtension.Jpg)}
                                    ></Image>
                                </AngleImage>
                            ) : null,
                        )}
                </AngleImages>
            )}
            {bp.md && bigImage?.fileName && (
                <BigImage
                    className="w-100"
                    alt={'Environment'}
                    onClick={() => handleBigImageClick(bigImage)}
                    src={getImage(bigImage, ImageType.Preview, ImageExtension.Jpg)}
                ></BigImage>
            )}
        </GalleryContainer>
    );
};

const ColorName = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
    text-align: right;
`;

const GalleryContainer = styled.div<{ isFullscreenAllowed: boolean; hideAngleThumbs: boolean }>`
    .image-gallery-bullets {
        width: calc(100% - 34px);
    }

    .image-gallery-bullets-container {
        display: flex;
        justify-content: end;
        align-items: baseline;

        .image-gallery-bullet {
            height: 12px;
            width: 45px;
            border-radius: 0;
            border: 0;
            outline: 0;
            margin: 0;
            box-shadow: 0 4px 0 ${({ theme }) => theme.colors.border};
            background: none !important;
            border: 0 !important;
            outline: 0 !important;
            margin: 0 5px;
            position: relative;

            &.active {
                box-shadow: 0 4px 0 ${({ theme }) => theme.colors.accent};
                transform: none;
            }

            &:hover,
            &:active,
            &:focus {
                transform: none;
            }

            &:hover {
                box-shadow: 0 7px 0 ${({ theme }) => theme.colors.accent};
            }

            &:after {
                position: absolute;
                top: 100%;
                left: 0;
                content: '';
                height: 10px;
                width: 100%;
            }
        }
    }

    .image-gallery-thumbnails {
        padding: 0;

        .image-gallery-thumbnails-container {
            display: flex;
            flex-wrap: wrap;
            text-align: left;
            cursor: default;
            background: white;
            border-top: 2px solid ${({ theme }) => theme.colors.fill} !important;
        }

        .image-gallery-thumbnail {
            vertical-align: top;
            margin: 0;
            padding: 3px;
            border: 0 !important;
            height: 40px;
            width: 40px;
            background: white;

            .image-gallery-thumbnail-inner {
                height: 100%;
                width: 100%;
            }

            .image-gallery-thumbnail-image {
                height: 100%;
                width: 100%;
                object-fit: contain;
                transition: transform 0.3s ease;
            }

            &.image-picture {
                .image-gallery-thumbnail-image {
                    object-fit: cover;
                }
            }
        }
    }

    .image-gallery-content.fullscreen .image-gallery-thumbnail {
        height: 80px;
        width: 80px;
    }

    ${({ hideAngleThumbs }) =>
        hideAngleThumbs &&
        `
  .image-gallery-content:not(.fullscreen) .hide-thumb {
    display: none;
  }
  `}

    .image-gallery-image {
        height: 68vh;
        background: white;
        padding: 1em;
    }

    .image-picture .image-gallery-image {
        padding: 0;
    }

    .image-gallery-image {
        cursor: ${({ isFullscreenAllowed }) => (isFullscreenAllowed ? 'crosshair' : 'default')};
    }

    .fullscreen .image-gallery-image {
        height: calc(100vh - 62px);
    }
`;

const ImageGalleryButton = styled.button`
    width: 40px;
    height: 40px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    border: none;
    outline: none;
    background-color: transparent;
`;

const NavButton = styled(ImageGalleryButton)<{ right?: boolean }>`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    color: ${(props) => props.theme.colors.border};

    &:hover {
        color: ${(props) => props.theme.colors.borderLight};
    }
    ${(props) =>
        props.right
            ? `
    right: 15px;
  `
            : `
  left: 15px;`};
`;

const AngleImage = styled(Flex)`
    background: #fff;
    padding: 1rem;
    width: 25%;
    height: 200px;

    img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
    }
`;

const AngleImages = styled(Flex)`
    border-top: 1px solid ${({ theme }) => theme.colors.fill};
    background: #f8f8f8;
`;

const BigImage = styled.img`
    cursor: crosshair;
`;

export default ProductImages;

type GalleryLargeThumb = ProductImage & {
    galleryIndex: number;
};
function formatImages(
    images: ProductImage[],
    variation: boolean,
): [ReactImageGalleryItem[], GalleryLargeThumb[], GalleryLargeThumb | null] {
    const imageObjects: ReactImageGalleryItem[] = [];
    const angleImages: GalleryLargeThumb[] = [];
    let bigImage: GalleryLargeThumb | null = null;

    images?.forEach((image, i) => {
        let hideThumb = false;

        if (
            variation &&
            (image.type === 'Productpicture' || image.type === 'Media') &&
            image.angle !== 'none' &&
            angleImages.length < 4
        ) {
            hideThumb = true;
            angleImages.push({ ...image, galleryIndex: i });
        }

        if (!bigImage && image.type === 'Imagepicture') {
            bigImage = { ...image, galleryIndex: i };
        }

        const imageClass = image.type === 'Productpicture' ? 'product-picture' : 'image-picture';
        const imgObject: ReactImageGalleryItem = {
            original: getImage(image, ImageType.Preview, ImageExtension.Jpg),
            thumbnail: getImage(image, ImageType.ThumbNail, ImageExtension.Jpg),
            thumbnailClass: `${imageClass} thumbnail ${hideThumb ? 'hide-thumb' : ''}`,
            originalClass: `${imageClass} ${image.angle ? 'angle-' + image.angle : ''}`,
        };

        imageObjects.push(imgObject);
    });

    return [
        imageObjects,
        angleImages, //.sort((a, b) => (getSort(a.angle) < getSort(b.angle) ? 1 : -1)),
        bigImage ? bigImage : null,
    ];
}

// function getSort(angle: string | null | undefined): number {
//     switch (angle) {
//         case 'front':
//             return 4;
//         case 'back':
//             return 3;
//         case 'left':
//             return 2;
//         case 'right':
//             return 1;
//         default:
//             return 0;
//     }
// }
