import Input from 'components/ui/Input';
import ThemeButton from 'components/ui/ThemeButton';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getAndSetAnonymousToken, setSecretError } from 'store/reducers/shopSlice';
import ShopLoadLayout from './ShopLoadLayout';

const ShopSecret = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { secretError, loading } = useAppSelector(({ shop }) => shop);

    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [secret, setSecret] = useState<string | null>(null);

    const loginWithSecret = () => dispatch(getAndSetAnonymousToken({ token: secret ?? '' }));

    const secretKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') loginWithSecret();
    };

    useEffect(() => {
        if (!!secretError) setErrorMessage(t('shop_secret_wrong'));
        else setErrorMessage(null);
    }, [secretError, t]);

    return (
        <ShopLoadLayout>
            {errorMessage && (
                <div className="alert alert-danger alert-dismissible" role="alert">
                    <span>{errorMessage}</span>
                    <button
                        type="button"
                        onClick={() => dispatch(setSecretError(false))}
                        className="btn-close ms-2"
                        data-dismiss="alert"
                        aria-label="Close"
                    ></button>
                </div>
            )}
            <p className="mb-2">{t('shop_secret_required')}</p>

            <Input
                type="text"
                value={secret ?? ''}
                label={t('shop_secret')}
                onKeyUp={(e) => secretKeyUp(e)}
                onChange={(e) => setSecret(e.target.value)}
            />

            <div className="text-center">
                <ThemeButton
                    loading={loading}
                    className={`mt-5`}
                    onClick={loginWithSecret}
                    text={t('shop_secret_enter')}
                />
            </div>
        </ShopLoadLayout>
    );
};
export default ShopSecret;
