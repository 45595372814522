"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomTagToJSON = exports.CustomTagFromJSONTyped = exports.CustomTagFromJSON = exports.instanceOfCustomTag = void 0;
var runtime_1 = require("../runtime");
var TagPosition_1 = require("./TagPosition");
var TagType_1 = require("./TagType");
/**
 * Check if a given object implements the CustomTag interface.
 */
function instanceOfCustomTag(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfCustomTag = instanceOfCustomTag;
function CustomTagFromJSON(json) {
    return CustomTagFromJSONTyped(json, false);
}
exports.CustomTagFromJSON = CustomTagFromJSON;
function CustomTagFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': !(0, runtime_1.exists)(json, 'type') ? undefined : (0, TagType_1.TagTypeFromJSON)(json['type']),
        'value': !(0, runtime_1.exists)(json, 'value') ? undefined : json['value'],
        'position': !(0, runtime_1.exists)(json, 'position') ? undefined : (0, TagPosition_1.TagPositionFromJSON)(json['position']),
        'name': !(0, runtime_1.exists)(json, 'name') ? undefined : json['name'],
    };
}
exports.CustomTagFromJSONTyped = CustomTagFromJSONTyped;
function CustomTagToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, TagType_1.TagTypeToJSON)(value.type),
        'value': value.value,
        'position': (0, TagPosition_1.TagPositionToJSON)(value.position),
        'name': value.name,
    };
}
exports.CustomTagToJSON = CustomTagToJSON;
