import {
    CartAddChangePOSTRequest,
    CartAddChangeSkuCommentPOSTRequest,
    CartApi,
    CartCheckoutPOSTRequest,
    CartRemovePOSTRequest,
    CartSetSkuDispatchDatePOSTRequest,
    CartSummary,
    Checkout,
    CheckoutConfirm,
} from 'microshop-api';
import { config } from './api';

const cartApi = new CartApi(config);

const cart = {
    removeSku: (skuData: CartRemovePOSTRequest): Promise<CartSummary> => cartApi.cartRemovePOST(skuData),
    changeSkuQuantity: (skuData: CartAddChangePOSTRequest): Promise<CartSummary> => cartApi.cartAddChangePOST(skuData),
    changeSkuComment: (skuData: CartAddChangeSkuCommentPOSTRequest): Promise<CartSummary> =>
        cartApi.cartAddChangeSkuCommentPOST(skuData),

    fetch: (): Promise<CartSummary | undefined> => cartApi.cartGet(),
    checkout: (): Promise<Checkout> => cartApi.cartGetCheckout(),
    checkoutConfirm: (checkout: CartCheckoutPOSTRequest): Promise<CheckoutConfirm> =>
        cartApi.cartCheckoutPOST(checkout),
    cartSetRequestedDispatchDate: (skuDate: CartSetSkuDispatchDatePOSTRequest): Promise<void> =>
        cartApi.cartSetSkuDispatchDatePOST(skuDate),
};

export { cart };
