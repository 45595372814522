import Flex from 'components/ui/Flex';
import Loader from 'components/ui/Loader';
import Text from 'components/ui/Text';
import useAppSelector from 'hooks/useAppSelector';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

const ShopLoad = ({
    message,
    login,
    logout,
    loggedout,
}: {
    message?: string;
    login?: boolean;
    logout?: boolean;
    loggedout?: boolean;
}) => {
    const info = useAppSelector(({ shop }) => shop.info);
    const { t } = useTranslation();

    let loaderMessage = message ? message : t('loading');
    if (login) loaderMessage = t('signin_inprogress');
    if (logout) loaderMessage = t('signout_inprogress');
    if (loggedout) loaderMessage = t('signout_confirmed');
    return (
        <StyledBackground
            style={info?.backgroundImage ? { backgroundImage: 'url("' + info.backgroundImage + '")' } : {}}
        >
            <StyledContainer className={`h-100 d-flex align-items-center justify-content-center`}>
                <Flex column>
                    <Text className="text-center my-4 display-4 text-break">{info?.name}</Text>
                    <LoaderContainer className="d-flex align-items-center justify-content-center me-4">
                        <Loader label={loaderMessage} loading={true} />
                    </LoaderContainer>
                </Flex>
            </StyledContainer>
        </StyledBackground>
    );
};

const StyledBackground = styled.div`
    flex-grow: 2;
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
`;

const StyledContainer = styled.div`
    background: rgba(255, 255, 255, 0.5);
`;

const LoaderContainer = styled.div``;

export default ShopLoad;
