"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.FixedFiltersToJSON = exports.FixedFiltersFromJSONTyped = exports.FixedFiltersFromJSON = exports.instanceOfFixedFilters = void 0;
var runtime_1 = require("../runtime");
/**
 * Check if a given object implements the FixedFilters interface.
 */
function instanceOfFixedFilters(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfFixedFilters = instanceOfFixedFilters;
function FixedFiltersFromJSON(json) {
    return FixedFiltersFromJSONTyped(json, false);
}
exports.FixedFiltersFromJSON = FixedFiltersFromJSON;
function FixedFiltersFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'active': !(0, runtime_1.exists)(json, 'active') ? undefined : json['active'],
        'filter': !(0, runtime_1.exists)(json, 'filter') ? undefined : json['filter'],
        'values': !(0, runtime_1.exists)(json, 'values') ? undefined : json['values'],
    };
}
exports.FixedFiltersFromJSONTyped = FixedFiltersFromJSONTyped;
function FixedFiltersToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'active': value.active,
        'filter': value.filter,
        'values': value.values,
    };
}
exports.FixedFiltersToJSON = FixedFiltersToJSON;
