import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import Flex from 'components/ui/Flex';
import IconButton from 'components/ui/IconButton';
import Text from 'components/ui/Text';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import VariationInput from './VariationInput';
import { CollectionVariation, ProductCollectionVariation } from 'store/reducers/productSlice';

export type VariationOfferSelectType = {
    variations: Array<{ colorCode: string; variationNumber: string }>;
    productNumber: string;
};

type VariationSelectorProps = {
    className?: string;
    variations: CollectionVariation[];
    selectedVariation?: CollectionVariation | null;
    onSelect: (v: CollectionVariation) => void;
};
const VariationSelector: React.FC<VariationSelectorProps> = ({
    className,
    variations,
    selectedVariation,
    onSelect,
}) => {
    const [showAllColors, setShowAllColors] = useState(false);
    const { t } = useTranslation();

    return (
        <>
            <Flex justify="between" className={className}>
                <Text className="mb-2 f3-700" light>
                    {t('article_one')}
                </Text>
            </Flex>
            <ColorsContainer align="start" className={`${className ? className : ''}`}>
                {variations?.map((v, i) => {
                    return (
                        (showAllColors || i < 6) && (
                            <StyledVariationInput
                                key={`${v.colorCode}${i}`}
                                variation={v}
                                selected={isSelected(v, selectedVariation)}
                                onSelect={onSelect}
                                className="me-1"
                                image
                            ></StyledVariationInput>
                        )
                    );
                })}
                {variations.length > 6 && (
                    <IconButton
                        className="w-100 mb-3"
                        bgColor="fillMedium"
                        icon={faPlus}
                        onClick={() => setShowAllColors(!showAllColors)}
                    >
                        {showAllColors
                            ? t('product.showLessColors', 'Show less colors')
                            : variations.length - 6 + ' ' + t('colors', 'colors')}
                    </IconButton>
                )}
            </ColorsContainer>
        </>
    );
};

const ColorsContainer = styled(Flex)`
    flex-wrap: wrap;
`;

const StyledVariationInput = styled(VariationInput)``;

export default VariationSelector;

function isSelected(variation: CollectionVariation, selected?: ProductCollectionVariation | null) {
    return (
        selected?.variationNumber === variation.variationNumber &&
        selected?.collectionId === variation.collectionId &&
        selected?.split === variation.split
    );
}
