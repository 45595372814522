import { useEffect, useState } from 'react';
import { ThemeBreakpoints, useTheme } from 'styled-components/macro';
import { BreakPoint, BreakPointSize } from 'types/styled';
import useWindowSize from './useWindowSize';

type BreakPoints = {
  [key in BreakPoint]: boolean;
};
export default function useBreakPoints(forceMaxSize?: BreakPointSize): BreakPoints {
  const theme = useTheme();
  const windowSize = useWindowSize();
  const [bp, setBp] = useState<BreakPoints>(
    getBreakPoints(getWidth(windowSize.width, theme.breakpoints, forceMaxSize), theme.breakpoints),
  );

  useEffect(() => {
    setBp(getBreakPoints(getWidth(windowSize.width, theme.breakpoints, forceMaxSize), theme.breakpoints));
  }, [theme, forceMaxSize, windowSize.width]);

  return bp;
}

function getBreakPoints(width: number, themeBps: ThemeBreakpoints): BreakPoints {
  return {
    min: true,
    xs: width >= themeBps.xs,
    sm: width >= themeBps.sm,
    md: width >= themeBps.md,
    lg: width >= themeBps.lg,
    xl: width >= themeBps.xl,
    xsBelow: width < themeBps.xs,
    smBelow: width < themeBps.sm,
    mdBelow: width < themeBps.md,
    lgBelow: width < themeBps.lg,
    xlBelow: width < themeBps.xl,
  };
}

// If window width is more than maxSize then return max
function getWidth(windowWidth: number, themeBps: ThemeBreakpoints, forceMaxSize?: BreakPointSize) {
  return forceMaxSize && windowWidth > themeBps[forceMaxSize] ? themeBps[forceMaxSize] : windowWidth;
}
