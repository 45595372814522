import { LoginActions } from 'components/ApiAuthorization/ApiAuthorizationConstants';
import { Login } from 'components/ApiAuthorization/Login';
import useAppSelector from 'hooks/useAppSelector';
import ShopError from 'pages/ShopError';
import { useState } from 'react';
import { ShopSecurityType } from 'store/reducers/shopSlice';
import ShopMustLogin from './ShopMustLogin';
import ShopSecret from './ShopSecret';

const ShopSecurity = ({ securityType }: { securityType?: ShopSecurityType }) => {
    const { firstVisit } = useAppSelector(({ shop }) => shop);
    const [redirect, setRedirect] = useState<string | null>(null);
    const redirectToLogin = () => setRedirect(LoginActions.Login);
    const redirectToRegister = () => setRedirect(LoginActions.Register);

    if (redirect) return <Login action={redirect}></Login>;
    switch (securityType) {
        case ShopSecurityType.Secret:
            return <ShopSecret />;
        case ShopSecurityType.Registred:
        case ShopSecurityType.RegistredSecret:
            if (firstVisit) {
                return <ShopMustLogin login={redirectToLogin} register={redirectToRegister} />;
            } else {
                return <Login action={LoginActions.Login}></Login>;
            }
        default:
            return <ShopError errorKey={'error_occurred'} />;
    }
};
export default ShopSecurity;
