import Button from 'components/ui/Button';
import Spinner from 'components/ui/Spinner';
import React from 'react';
import styled, { ThemeColors } from 'styled-components/macro';

export type ThemeButtonProps = {
    onClick?: (event: any) => void;
    bgColor?: keyof ThemeColors;
    color?: keyof ThemeColors;
    text: string;
    loading?: boolean;
    disabled?: boolean;
    borderRadius?: string;
    className?: string;
};

const ThemeButton: React.FC<ThemeButtonProps> = ({
    onClick,
    text,
    bgColor,
    color,
    borderRadius,
    loading = false,
    disabled,
    className,
}) => {
    return (
        <StyledThemeButton
            onClick={onClick}
            className={`f1-500 ${className ?? 'mt-4'}`}
            fullwidth
            pill
            attention
            bgColor={bgColor ? bgColor : 'accent'}
            color={color ? color : 'textWhite'}
            disabled={disabled || loading}
            borderRadius={borderRadius}
        >
            {loading && <Spinner className="me-1" />} {text}
        </StyledThemeButton>
    );
};

export default ThemeButton;

const StyledThemeButton = styled(Button)<{ borderRadius?: string }>`
    font-size: 14px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : '')};
`;
