import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/ui/Flex';

import React from 'react';
import { Valid } from 'store/reducers/cartSlice';
import styled from 'styled-components/macro';
import Validate from './Validate';

interface AsideSectionProps {
    header?: string | React.ReactNode;
    headerIcon?: IconProp;
    validate?: boolean;
    valid?: Valid;
    validText?: string | null;
    errorText?: string;
    locked?: boolean;
    headerLink?: React.ReactNode;
    children: React.ReactNode;
    align?: 'center' | 'start' | 'end' | 'stretch' | undefined;
}

const AsideSection: React.FC<AsideSectionProps> = ({
    header,
    headerIcon,
    validate,
    valid,
    children,
    validText,
    errorText,
    locked,
    headerLink,
    align,
}) => (
    <SummaryItem locked={locked}>
        {(header ?? headerIcon ?? validate) && (
            <SummaryHead
                className={
                    locked ?? (valid === Valid.Valid && validText) ? '' : valid === Valid.Valid ? 'mb-2' : 'mb-4'
                }
                justify="between"
                align={align}
            >
                <SummaryHeading locked={locked} className="f3-700">
                    {header}
                    {headerIcon && <SummaryIcon className="ms-2" icon={headerIcon} />}
                </SummaryHeading>
                {validate && <Validate valid={valid} errorText={errorText} validText={validText} />}
                {headerLink && headerLink}
            </SummaryHead>
        )}
        {children}
    </SummaryItem>
);

export default AsideSection;

export const SummaryIcon = styled(FontAwesomeIcon)`
    font-size: 16px;
`;

type SummaryOptions = {
    locked?: boolean;
    hidden?: boolean;
};
export const SummaryItem = styled.div<SummaryOptions>`
  ${({ hidden }) => hidden && 'visibility: hidden;'}
  border-bottom: 2px solid ${({ theme }) => theme.colors.fill};
  padding: 20px;
  ${({ theme, locked }) =>
      locked &&
      `
      background-color: ${theme.colors.fillMedium};
      color: ${theme.colors.textFaded};
      `}
`;

export const SummaryHead = styled(Flex)``;

export const SummaryHeading = styled.h2<SummaryOptions>`
    font-size: 16px;
    line-height: 22px;
    ${({ theme, locked }) =>
        locked &&
        `
      color: ${theme.colors.text};
    `}
`;
