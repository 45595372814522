import HTMLHead from 'HtmlHead';
import ApiAuthorizationRoutes from 'components/ApiAuthorization/ApiAuthorizationRoutes';
import StyledToastContainer from 'components/Cart/ToastContainer';
import Shop from 'components/Shop/Shop';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import { darken, lighten } from 'polished';
import { useEffect } from 'react';
import { Slide } from 'react-toastify';
import { initiateShop } from 'store/reducers/shopSlice';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import { ReactDatepicker } from 'styles/reactDatepicker';
import 'styles/styles.scss';

const App = () => {
    const dispatch = useAppDispatch();
    const { theme } = useAppSelector(({ shop }) => shop);

    // Init info - initialize, get security type and verify tokens
    useEffect(() => {
        dispatch(initiateShop(window.location.hostname));
    }, [dispatch]);

    return (
        <>
            <HTMLHead />
            <ThemeProvider theme={theme}>
                <Global />
                <FontStyle />
                <ApiAuthorizationRoutes />
                <ReactDatepicker />
                <StyledToastContainer
                    position="top-right"
                    transition={Slide}
                    autoClose={false}
                    hideProgressBar={true}
                    newestOnTop={true}
                    closeButton={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    icon={false}
                />
                <Shop />
            </ThemeProvider>
        </>
    );
};

const Global = createGlobalStyle`
  #root {
    background:${({ theme }) => theme.colors.fill};
  }

  ::selection {
    background: ${({ theme }) => theme.colors.accent};
    color: ${({ theme }) => theme.colors.accentText};
  }

  h1 {
    font-size: 38px;
    line-height: 45px;
  }
  h2 {
    font-size: 28px;
    line-height: 32px;
  }
  h3 {
    font-size: 19px;
  }
  .pointer {
    cursor: pointer;
  }
  .visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0;
    border: 0;
    white-space: nowrap;
  }
  .float-left {
    float: left;
  }
  .z-context {
    position: relative;
    z-index: 0;
  }

  hr {
    border: 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  }

  a {

    color: ${({ theme }) => darken(0.2, theme.colors.accent)};
    text-decoration: none;
    &:hover, &:focus, &:visited {
      color: ${({ theme }) => darken(0.3, theme.colors.accent)};
    }
    &:focus-visible {
      outline: 1px solid ${({ theme }) => theme.colors.accent};
    }
  }
  iframe {
    max-width:100%;
  }

  ${({ theme }) => theme.media.md} {
    h1 {
      font-size: 48px;
      line-height: 55px;
    }
    h2 {
      font-size: 38px;
      line-height: 45px;
    }
  }

  ${({ theme }) => theme.media.lg} {
    h1 {
      font-size: 58px;
      line-height: 1.2em;
    }
  }
  ${({ theme }) => theme.media.xl} {
    h1 {
      font-size: 72px;
      line-height: 1.2em;
    }
    h2 {
      font-size: 48px;
      line-height: 52px;
    }
  }

`;

const FontStyle = createGlobalStyle`
  body {
    font-size: ${({ theme }) => theme.typography.fontSize};
    font-family: ${({ theme }) => theme.typography.f1400family}, helvetica, sans-serif;
  }
  h1, h2, h3 {
    font-family: ${({ theme }) => theme.typography.f2700family}, helvetica, sans-serif;
    font-weight: 700;
  }
  h3 {
    font-family: ${({ theme }) => theme.typography.f1700family}, helvetica, sans-serif;
    font-weight: 700;
  }
  h4 {
    font-family: ${({ theme }) => theme.typography.f1500family}, helvetica, sans-serif;
    font-weight: 500;
  }
  .f1-400 {
    font-family: ${({ theme }) => theme.typography.f1400family}, helvetica, sans-serif;
    font-weight: 400;
  }
  .f1-500 {
    font-family: ${({ theme }) => theme.typography.f1500family}, helvetica, sans-serif;
    font-weight: 500;
  }
  .f1-700 {
    font-family: ${({ theme }) => theme.typography.f1700family}, helvetica, sans-serif;
    font-weight: 700;
  }
  .f2-400 {
    font-family: ${({ theme }) => theme.typography.f2400family}, helvetica, sans-serif;
    font-weight: 400;
  }
  .f2-700 {
    font-family: ${({ theme }) => theme.typography.f2700family}, helvetica, sans-serif;
    font-weight: 700;
  }
  .f3-300 {
    font-family: ${({ theme }) => theme.typography.f3300family}, helvetica, sans-serif;
    font-weight: 300;
  }
  .f3-400 {
    font-family: ${({ theme }) => theme.typography.f3400family}, helvetica, sans-serif;
    font-weight: 400;
  }
  .f3-500 {
    font-family: ${({ theme }) => theme.typography.f3500family}, helvetica, sans-serif;
    font-weight: 500;
  }
  .f3-700 {
    font-family: ${({ theme }) => theme.typography.f3700family}, helvetica, sans-serif;
    font-weight: 700;
  }
`;

export default App;
