import Text from 'components/ui/Text';
import { StockLevel } from 'microshop-api';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import StockIcon from './StockIcon';
type LegendProps = {
    className?: string;
    inStock: boolean;
    noStock: boolean;
    lowStock: boolean;
};
const StockLegend: FC<LegendProps> = ({ className, inStock, noStock, lowStock }) => {
    const { t } = useTranslation();

    return (
        <Wrapper className={`${className ? className : ''}`}>
            <Text light small>
                {inStock && (
                    <>
                        <StyledStockIcon className="mx-1" stockLevel={StockLevel.InStock} />
                        {'='} <span>{t('stockLevel.in', 'In stock')}</span>
                    </>
                )}
                {lowStock && (
                    <>
                        <StyledStockIcon className="mx-1" stockLevel={StockLevel.LowStock} />
                        {'='} <span>{t('stockLevel.low', 'Low stock')}</span>
                    </>
                )}
                {noStock && (
                    <>
                        <StyledStockIcon className="mx-1" stockLevel={StockLevel.OutOfStock} />
                        {'='} <span>{t('stockLevel.out', 'Out of stock')}</span>
                    </>
                )}
            </Text>
        </Wrapper>
    );
};

export default StockLegend;

const Wrapper = styled.div`
    * {
        vertical-align: middle;
    }
`;

const StyledStockIcon = styled(StockIcon)`
    font-size: 16px;

    &:not(:first-of-type) {
        margin-left: 12px !important;
    }
`;
