"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.FilterItemToJSON = exports.FilterItemFromJSONTyped = exports.FilterItemFromJSON = exports.instanceOfFilterItem = void 0;
var runtime_1 = require("../runtime");
/**
 * Check if a given object implements the FilterItem interface.
 */
function instanceOfFilterItem(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfFilterItem = instanceOfFilterItem;
function FilterItemFromJSON(json) {
    return FilterItemFromJSONTyped(json, false);
}
exports.FilterItemFromJSON = FilterItemFromJSON;
function FilterItemFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': !(0, runtime_1.exists)(json, 'name') ? undefined : json['name'],
        'fieldName': !(0, runtime_1.exists)(json, 'fieldName') ? undefined : json['fieldName'],
        'key': !(0, runtime_1.exists)(json, 'key') ? undefined : json['key'],
        'sortOrder': !(0, runtime_1.exists)(json, 'sortOrder') ? undefined : json['sortOrder'],
    };
}
exports.FilterItemFromJSONTyped = FilterItemFromJSONTyped;
function FilterItemToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'fieldName': value.fieldName,
        'key': value.key,
        'sortOrder': value.sortOrder,
    };
}
exports.FilterItemToJSON = FilterItemToJSON;
