"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserOptionsToJSON = exports.UserOptionsFromJSONTyped = exports.UserOptionsFromJSON = exports.instanceOfUserOptions = void 0;
var runtime_1 = require("../runtime");
var Address_1 = require("./Address");
var AppContextOrderType_1 = require("./AppContextOrderType");
var AppContextTransportType_1 = require("./AppContextTransportType");
/**
 * Check if a given object implements the UserOptions interface.
 */
function instanceOfUserOptions(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfUserOptions = instanceOfUserOptions;
function UserOptionsFromJSON(json) {
    return UserOptionsFromJSONTyped(json, false);
}
exports.UserOptionsFromJSON = UserOptionsFromJSON;
function UserOptionsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'deliveryAddresses': !(0, runtime_1.exists)(json, 'deliveryAddresses') ? undefined : (json['deliveryAddresses'] === null ? null : json['deliveryAddresses'].map(Address_1.AddressFromJSON)),
        'invoiceAdress': !(0, runtime_1.exists)(json, 'invoiceAdress') ? undefined : (0, Address_1.AddressFromJSON)(json['invoiceAdress']),
        'acceptSplitDelivery': !(0, runtime_1.exists)(json, 'acceptSplitDelivery') ? undefined : json['acceptSplitDelivery'],
        'userIsBlocked': !(0, runtime_1.exists)(json, 'userIsBlocked') ? undefined : json['userIsBlocked'],
        'deliveryCountry': !(0, runtime_1.exists)(json, 'deliveryCountry') ? undefined : json['deliveryCountry'],
        'campaignEnabled': !(0, runtime_1.exists)(json, 'campaignEnabled') ? undefined : json['campaignEnabled'],
        'orderTypes': !(0, runtime_1.exists)(json, 'orderTypes') ? undefined : (json['orderTypes'] === null ? null : json['orderTypes'].map(AppContextOrderType_1.AppContextOrderTypeFromJSON)),
        'transportTypes': !(0, runtime_1.exists)(json, 'transportTypes') ? undefined : (json['transportTypes'] === null ? null : json['transportTypes'].map(AppContextTransportType_1.AppContextTransportTypeFromJSON)),
        'splitDelivery': !(0, runtime_1.exists)(json, 'splitDelivery') ? undefined : json['splitDelivery'],
        'selectedTransportType': !(0, runtime_1.exists)(json, 'selectedTransportType') ? undefined : json['selectedTransportType'],
        'selectedOrderType': !(0, runtime_1.exists)(json, 'selectedOrderType') ? undefined : json['selectedOrderType'],
        'selectedDeliveryAddress': !(0, runtime_1.exists)(json, 'selectedDeliveryAddress') ? undefined : json['selectedDeliveryAddress'],
        'priceEnabled': !(0, runtime_1.exists)(json, 'priceEnabled') ? undefined : json['priceEnabled'],
        'stockEnabled': !(0, runtime_1.exists)(json, 'stockEnabled') ? undefined : json['stockEnabled'],
        'orderEnabled': !(0, runtime_1.exists)(json, 'orderEnabled') ? undefined : json['orderEnabled'],
    };
}
exports.UserOptionsFromJSONTyped = UserOptionsFromJSONTyped;
function UserOptionsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'deliveryAddresses': value.deliveryAddresses === undefined ? undefined : (value.deliveryAddresses === null ? null : value.deliveryAddresses.map(Address_1.AddressToJSON)),
        'invoiceAdress': (0, Address_1.AddressToJSON)(value.invoiceAdress),
        'acceptSplitDelivery': value.acceptSplitDelivery,
        'userIsBlocked': value.userIsBlocked,
        'deliveryCountry': value.deliveryCountry,
        'campaignEnabled': value.campaignEnabled,
        'orderTypes': value.orderTypes === undefined ? undefined : (value.orderTypes === null ? null : value.orderTypes.map(AppContextOrderType_1.AppContextOrderTypeToJSON)),
        'transportTypes': value.transportTypes === undefined ? undefined : (value.transportTypes === null ? null : value.transportTypes.map(AppContextTransportType_1.AppContextTransportTypeToJSON)),
        'splitDelivery': value.splitDelivery,
        'selectedTransportType': value.selectedTransportType,
        'selectedOrderType': value.selectedOrderType,
        'selectedDeliveryAddress': value.selectedDeliveryAddress,
    };
}
exports.UserOptionsToJSON = UserOptionsToJSON;
