import { useEffect, useRef } from 'react';

const useOnClickOutside = <T extends HTMLElement = HTMLDivElement>(
    onClickOutside: () => void,
    isActive: boolean = true,
    withinClassName?: string,
    dontUseEsc?: boolean,
) => {
    const ref = useRef<T>(null);

    useEffect(() => {
        if (!isActive) return;

        const currentRef = ref.current;

        const handleEsc = (event: KeyboardEvent) => {
            if (event.key === 'Escape' && !dontUseEsc) {
                onClickOutside();
            }
        };

        const handleClickOutside = (event: Event) => {
            let clickedWithinClassName: boolean = false;
            if (withinClassName) {
                const slideInElements: any = document.getElementsByClassName(withinClassName);
                clickedWithinClassName =
                    Array.prototype.findIndex.call(slideInElements, (e: HTMLDivElement) =>
                        e.contains(event.target as Node),
                    ) >= 0;
            }

            if (
                !currentRef?.contains(event.target as Node) &&
                !(event.target as HTMLElement).getAttribute('data-exclude-click') &&
                !clickedWithinClassName
            ) {
                onClickOutside();
            }
        };

        document.addEventListener('keydown', handleEsc, true);
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('keydown', handleEsc, true);
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [onClickOutside, isActive, withinClassName, dontUseEsc]);

    return ref;
};

export default useOnClickOutside;
