import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import Flex from 'components/ui/Flex';
import IconButton from 'components/ui/IconButton';
import Loader from 'components/ui/Loader';
import Text from 'components/ui/Text';
import useAppSelector from 'hooks/useAppSelector';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

const Confirm = () => {
    const { t } = useTranslation();
    const { confirm } = useAppSelector(({ cart }) => cart);

    return (
        <Row className="my-5 py-5 justify-content-center align-items-center">
            {!confirm && (
                <Flex className="justify-content-center align-items-center" column>
                    <Loader label={t('loading')} loading={true} />
                </Flex>
            )}
            {confirm && confirm.success && (
                <Flex column className="justify-content-center align-items-center">
                    <Col lg="5">
                        <h3 className="h1 display-5">{t('confirm_success')}</h3>
                        <p>{t('confirm_thanks')}</p>
                        <p>
                            {t('order_number')}: {confirm.orderNumber}
                        </p>
                        <Link className="link-dark" to="/products">
                            <Flex className="justify-content-end align-items-center">
                                <Text large light className="me-3">
                                    {t('confirm_continue')}
                                </Text>
                                <IconButton icon={faArrowRight} bgColor="fillWhite" />
                            </Flex>
                        </Link>
                    </Col>
                </Flex>
            )}

            {confirm && !confirm.success && (
                <Col sm="6" className="text-center">
                    <h3 className="h1 display-3">{t('confirm_error')}</h3>
                    <hr className="my-2" />
                    <h4 className="h1 display-3">{t('errors')}</h4>
                    {(confirm.errors?.length ?? 0) > 0 && confirm?.errors?.map((e: any) => <p>{e}</p>)}
                </Col>
            )}
        </Row>
    );
};
export default Confirm;
