import ThemeButton from 'components/ui/ThemeButton';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Container, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { acceptCookie } from 'store/reducers/shopSlice';
import styled from 'styled-components/macro';
import Flex from './Flex';
import Text from './Text';

const Cookies = () => {
    const { t } = useTranslation();
    const [isOpen, toggleCookies] = useState(false);

    const dispatch = useAppDispatch();
    const { cookieAgree } = useAppSelector(({ shop }) => shop);

    return !cookieAgree ? (
        <StyledCookie style={{ backgroundColor: '#FFFFFF' }} className="fixed-bottom border-top">
            <Container className="w-100">
                <Col size="11" className="text-center">
                    <Flex className="py-3 align-items-center">
                        <Text>{t('cookies_text')}</Text>
                        <Flex>
                            <ThemeButton
                                className="ms-2"
                                onClick={() => toggleCookies(true)}
                                text={t('cookies_read_more')}
                                loading={false}
                            />
                            <ThemeButton
                                loading={false}
                                className="ms-2"
                                onClick={() => dispatch(acceptCookie())}
                                text={t('ok')}
                            />
                        </Flex>
                    </Flex>
                </Col>
            </Container>

            <Modal isOpen={isOpen} toggle={() => toggleCookies} size="lg">
                <ModalHeader>{t('cookies')}</ModalHeader>
                <ModalBody>
                    <span style={{ whiteSpace: 'pre-wrap' }}>{t('cookies_extended')}</span>
                </ModalBody>
                <ModalFooter>
                    <ThemeButton onClick={() => toggleCookies(false)} loading={false} text={t('close')} />
                </ModalFooter>
            </Modal>
        </StyledCookie>
    ) : null;
};

const StyledCookie = styled.div``;
export default Cookies;
