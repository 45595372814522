import { faCheckCircle, faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Valid } from 'store/reducers/cartSlice';
import styled from 'styled-components/macro';

type ValidIconProps = {
    valid?: Valid;
};
const ValidIcon: React.FC<ValidIconProps> = ({ valid }) => {
    return <StyledIcon $valid={valid} color="" icon={valid === Valid.Invalid ? faTimesCircle : faCheckCircle} />;
};

export default ValidIcon;

type StyledIconProps = {
    $valid?: Valid;
};
const StyledIcon = styled(FontAwesomeIcon)<StyledIconProps>`
    font-size: 20px;
    color: ${({ theme, $valid }) => {
        switch ($valid) {
            case Valid.Valid:
                return theme.colors.success;
            case Valid.Invalid:
                return theme.colors.error;
            case Valid.Unknown:
            default:
                return theme.colors.textDisabled;
        }
    }};
`;

export { Valid };
