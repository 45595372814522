import ProductPrice from 'components/Product/ProductPrice';
import Flex from 'components/ui/Flex';
import Tags from 'components/ui/Tag/Tags';
import Text from 'components/ui/Text';
import Truncate from 'components/ui/Truncate';
// import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useBreakPoints from 'hooks/useBreakPoints';
import Skeleton from 'react-loading-skeleton';
// import { useLocation } from 'react-router-dom';
import { selectProductDetails, selectProductDetailsTags } from 'store/reducers/productSlice';
import styled from 'styled-components/macro';
import ProductBuyTypeSelect from './ProductBuyTypeSelect';
import getImage, { ImageType } from 'utils/getImage';
// import ProductBuyTypeSelect from './ProductBuyTypeSelect';

const ProductDetailPageInfo = () => {
    // const { t } = useTranslation();
    // const dispatch = useAppDispatch();
    const bp = useBreakPoints();
    // const location = useLocation();

    const { product, variation, loading } = useAppSelector(selectProductDetails);
    const showBrand = useAppSelector(({ assortment }) => assortment.showBrand);
    const isB2b = useAppSelector(({ shop }) => shop.shop?.isB2BShop);

    const price = variation?.price || product?.price;

    const brand = product?.marks?.brand?.length ? product.marks.brand[0] : undefined;
    const tags = useAppSelector(selectProductDetailsTags);
    const hideProductBrandImage = false;

    return (
        <ProductDetailsContainer>
            <ProductDetailsInner className="ps-4 pe-3 py-2 py-md-3 position-relative">
                {loading ? (
                    <>
                        <Flex justify="between">
                            <Skeleton height={40} width={100}></Skeleton>
                            <Skeleton height={30} width={180}></Skeleton>
                        </Flex>
                        <Skeleton height={54} width={500} className="mt-3 mb-4"></Skeleton>
                        <Skeleton height={100} className="mb-4"></Skeleton>
                        <Skeleton height={48} width={200} className="mb-3"></Skeleton>
                    </>
                ) : (
                    <>
                        <Flex justify="between" className="py-3 py-md-0">
                            <Flex column justify="center" align="start">
                                {showBrand && (
                                    <Text className="f1-700" uppercase>
                                        {product?.productBrandName}
                                        <meta itemProp="brand" content={`${product?.productBrandName}`} />
                                    </Text>
                                )}
                                <Text small light>
                                    {product?.productNumber}
                                </Text>
                                <Text itemProp="productID" small light>
                                    {product?.productInternalNumber}
                                    <meta itemProp="productID" content={`${product?.productInternalNumber}`} />
                                </Text>
                            </Flex>
                            <Flex align="start">
                                {showBrand && bp.md && !hideProductBrandImage && (
                                    <ProductBrandIcon src={getImage(brand?.image, ImageType.LargeThumbNail)} />
                                )}
                            </Flex>
                        </Flex>
                        <Flex className="justify-content-md-between flex-md-wrap">
                            <ProductDescription itemProp="description" className="mt-3 mt-md-3 mb-4">
                                <Text as="h1" itemProp="name" className="text-break mb-0" fontSize={bp.lg ? 42 : 32}>
                                    {product?.productName}
                                    <meta itemProp="name" content={`${product?.productName}`} />
                                    <meta itemProp="description" content={`${product?.productName}`} />
                                </Text>
                                {tags.badgeTags.length > 0 && <Tags tags={tags.badgeTags} />}
                                <div className="mt-3">
                                    <Truncate>
                                        {getProductDescriptionString(product?.description, product?.customDescription)}
                                    </Truncate>
                                </div>

                                <div className="f1-700 mt-3">{price && <ProductPrice price={price} />}</div>
                            </ProductDescription>
                        </Flex>
                    </>
                )}
                {product && isB2b && (
                    <BuyTypeSelectWrapper className="position-absolute" justify="end">
                        <ProductBuyTypeSelect hasVariations={product?.hasVariations || false} />
                    </BuyTypeSelectWrapper>
                )}
            </ProductDetailsInner>
        </ProductDetailsContainer>
    );
};

function getProductDescriptionString(description?: string | null, customDescription?: string | null): string {
    if (description && customDescription) {
        return `${description}
        
        ${customDescription}`;
    }
    return description ?? customDescription ?? '';
}

export default ProductDetailPageInfo;

const BuyTypeSelectWrapper = styled(Flex)`
    bottom: -16px;
    right: 0;
`;
const ProductDescription = styled.span`
    max-width: 540px;
    line-height: 1.7;
    white-space: pre-line;
`;

const ProductDetailsContainer = styled.div`
    width: 200%;
    padding-right: 100%;
    background-color: ${(props) => props.theme.colors.fillMedium};
`;

const ProductDetailsInner = styled.div`
    width: 100%;
`;

const ProductBrandIcon = styled.img`
    height: 36px;
`;

// const BuyTypeSelectWrapper = styled(Flex)`
//     bottom: -16px;
//     right: 0;
// `;

// const RelatedWrapper = styled.div`
//     position: absolute;
//     right: 1em;
// `;
