"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CartItemSkuToJSON = exports.CartItemSkuFromJSONTyped = exports.CartItemSkuFromJSON = exports.instanceOfCartItemSku = void 0;
var runtime_1 = require("../runtime");
var CartItemSkuAddOn_1 = require("./CartItemSkuAddOn");
var PriceInformation_1 = require("./PriceInformation");
/**
 * Check if a given object implements the CartItemSku interface.
 */
function instanceOfCartItemSku(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfCartItemSku = instanceOfCartItemSku;
function CartItemSkuFromJSON(json) {
    return CartItemSkuFromJSONTyped(json, false);
}
exports.CartItemSkuFromJSON = CartItemSkuFromJSON;
function CartItemSkuFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'split': !(0, runtime_1.exists)(json, 'split') ? undefined : json['split'],
        'collectionId': !(0, runtime_1.exists)(json, 'collectionId') ? undefined : json['collectionId'],
        'sku': !(0, runtime_1.exists)(json, 'sku') ? undefined : json['sku'],
        'comment': !(0, runtime_1.exists)(json, 'comment') ? undefined : json['comment'],
        'size': !(0, runtime_1.exists)(json, 'size') ? undefined : json['size'],
        'quantity': !(0, runtime_1.exists)(json, 'quantity') ? undefined : json['quantity'],
        'total': !(0, runtime_1.exists)(json, 'total') ? undefined : json['total'],
        'addOns': !(0, runtime_1.exists)(json, 'addOns') ? undefined : (json['addOns'] === null ? null : json['addOns'].map(CartItemSkuAddOn_1.CartItemSkuAddOnFromJSON)),
        'skuTotal': !(0, runtime_1.exists)(json, 'skuTotal') ? undefined : json['skuTotal'],
        'price': !(0, runtime_1.exists)(json, 'price') ? undefined : (0, PriceInformation_1.PriceInformationFromJSON)(json['price']),
        'dispatchDate': !(0, runtime_1.exists)(json, 'dispatchDate') ? undefined : (json['dispatchDate'] === null ? null : new Date(json['dispatchDate'])),
        'requestedDispatchDate': !(0, runtime_1.exists)(json, 'requestedDispatchDate') ? undefined : (json['requestedDispatchDate'] === null ? null : new Date(json['requestedDispatchDate'])),
    };
}
exports.CartItemSkuFromJSONTyped = CartItemSkuFromJSONTyped;
function CartItemSkuToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'split': value.split,
        'collectionId': value.collectionId,
        'addOns': value.addOns === undefined ? undefined : (value.addOns === null ? null : value.addOns.map(CartItemSkuAddOn_1.CartItemSkuAddOnToJSON)),
        'skuTotal': value.skuTotal,
        'price': (0, PriceInformation_1.PriceInformationToJSON)(value.price),
    };
}
exports.CartItemSkuToJSON = CartItemSkuToJSON;
