import { faSlidersH } from '@fortawesome/pro-solid-svg-icons';
import FilterBox from 'components/ui/FilterBox';
import Flex from 'components/ui/Flex';
import IconButton from 'components/ui/IconButton';
import Spinner from 'components/ui/Spinner';
import Text from 'components/ui/Text';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import { useTranslation } from 'react-i18next';
// import { useSearchParams } from 'react-router-dom';
import {
    clearFilters,
    selectAssortmentPage,
    selectFlattenCompleteActiveFilters,
    showFilterToggled,
    toggleFilter,
} from 'store/reducers/assortmentSlice';
import styled from 'styled-components';

const ProductListHeader = () => {
    // const { showHeader } = useAppSelector(({ navigation }) => navigation);
    const { loading, assortmentPageInfo, filters, filtersShow } = useAppSelector(({ assortment }) => assortment);
    const selectedCategory = useAppSelector(selectAssortmentPage);
    const totalProducts = assortmentPageInfo?.totalItems ?? null;
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    return (
        <ProductListHeaderWrapper stuck={true}>
            <Flex align="center" justify="between" $wrap>
                {(filters.flatMap((fo) => fo.filter).length > 0 || (selectedCategory?.children?.length ?? 0) > 0) && (
                    <IconButton
                        large
                        bgColor="transparent"
                        onClick={() => dispatch(showFilterToggled())}
                        className="pointer f1-400"
                        icon={faSlidersH}
                        square
                    >
                        {filtersShow ? t('hide', 'Hide') : t('show')} {t('filters').toLowerCase()}
                    </IconButton>
                )}
                <ActionsWrapper align="center" justify="end">
                    <ProductAmountText light className="">
                        {loading ? (
                            <Spinner />
                        ) : (
                            totalProducts != null && `${totalProducts} ${t('products').toLowerCase()}`
                        )}
                    </ProductAmountText>
                    {/* <OrderBySelect /> */}
                    {/* <ProductPdfExportButton products={products} portal={true} /> */}
                    {/* <Flex>
            <ListFormatButton>
              <Flex center>
                <FormatButton>
                  <FontAwesomeIcon icon={faBars} />
                </FormatButton>
                <FormatButton>
                  <FontAwesomeIcon icon={faTh} />
                </FormatButton>
              </Flex>
            </ListFormatButton>
            <Button height={35} backgroundcolor="white" textcolor="#2f2f2f">
              <FontAwesomeIcon icon={faDownload} /> {te('download')} PDF
            </Button>
          </Flex> */}
                </ActionsWrapper>
            </Flex>
            <FilterBoxes />
        </ProductListHeaderWrapper>
    );
};

const FilterBoxes = () => {
    const flatFilters = useAppSelector(selectFlattenCompleteActiveFilters);
    // const [searchParams, setSearchParams] = useSearchParams();

    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    if (flatFilters === null) return null;

    return (
        <FilterFlexBoxesWrapper>
            <Fade align="left" />
            <FilterBoxesWrapper>
                {flatFilters?.map((filter) => (
                    <FilterBox
                        key={filter.value.key}
                        text={filter.value.value}
                        onRemove={() => {
                            dispatch(toggleFilter(filter));
                        }}
                    />
                ))}

                {flatFilters && flatFilters.length > 1 && (
                    <ClearFilterBox
                        className="f1-500"
                        text={`${t('action.clear', 'Clear')} ${t('filter.filters', 'Filters').toLowerCase()}`}
                        onRemove={() => {
                            dispatch(clearFilters());
                            // setSearchParams('');
                        }}
                    />
                )}
            </FilterBoxesWrapper>
            <Fade align="right" />
        </FilterFlexBoxesWrapper>
    );
};

const ProductListHeaderWrapper = styled.div<{ stuck?: boolean }>`
    position: sticky;
    top: -150px;
    transition: top 0.2s ease;
    background-color: ${({ theme }) => theme.colors.fill};
    padding-top: 4px;
    padding-bottom: 4px;
    margin: 1px;
    z-index: 5;

    ${({ stuck, theme }) =>
        stuck &&
        `
      top: 71px;
      transition-duration: 0.5s;

      ${theme.media.lg} {
        // top: 81px;
      }
    `}
`;

const ActionsWrapper = styled(Flex)`
    flex-grow: 1;
    gap: 8px;
`;

const ProductAmountText = styled(Text)`
    @media screen and (max-width: 450px) {
        display: none;
    }
`;

// const FillContainer = styled(Container)`
//     background-color: ${({ theme }) => theme.colors.fill};
//     ${({ theme }) => theme.media.smBelow} {
//         padding: 4px;
//     }
// `;

// const FilterHeader = styled.div``;

// const FilterHeaderCategoryBlurb = styled(Flex)`
//     max-width: 50%;
// `;

// const FilterHeadingWrapper = styled.div``;

// const FilterHeading = styled.h2`
//     font-size: 32px;
// `;

const ClearFilterBox = styled(FilterBox)`
    margin-left: 12px;
`;

// const SelectedCategoryDescription = styled(Text)`
//     line-height: 1.6;
//     font-size: 14px;
// `;
// const SelectedCategoryImage = styled(Image)`
//     width: 110px;
//     height: 110px;
//     object-fit: cover;
//     flex-shrink: 0;
// `;

const FilterFlexBoxesWrapper = styled(Flex)`
    background-color: ${({ theme }) => theme.colors.fillMedium};
    margin-top: 4px;
`;

const FilterBoxesWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    overflow: auto;
    white-space: nowrap;
    max-width: 100%;
    padding: 0 15px;
    padding: 2px 15px 2px 15px;
    margin: 0 -15px 0 -15px;
`;

const Fade = styled.div<{ align: 'left' | 'right' }>`
    position: relative;
    height: 35px;
    background: ${({ theme, align }) =>
        `linear-gradient(to ${align}, rgba(255, 255, 255, 0), ${theme.colors.fillMedium})`};
    min-width: 15px;
    width: 15px;
    z-index: 1;
`;

// const ListFormatButton = styled.div`
//   height: 35px;
//   line-height: 35px;
//   background-color: white;
//   border-radius: 3px;
//   margin-right: 15px;
// `;

// const FormatButton = styled.div`
//   width: 40px;
//   text-align: center;
//   border-right: 1px solid ${(props) => props.theme.colors.fill};

//   &:last-child {
//     border-right: 0;
//   }
// `;

export default ProductListHeader;
