import { faBoxes, faExclamation } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/ui/Flex';
import Text from 'components/ui/Text';
import useAppSelector from 'hooks/useAppSelector';
import { TFunction } from 'i18next';
import { ProductStock } from 'microshop-api';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import {
    StockInfoType,
    StockSettings,
    getStockType,
    selectNumFormat,
    selectStockSettings,
} from 'store/reducers/productSlice';
import styled from 'styled-components';
import { InfoRow, InfoTBody, InfoTable, InfoTd } from './SkuInfo';

type StockDetail = {
    value: string;
    label: string;
    description?: string;
};

type StockProps = {
    stock: ProductStock[];
    refillDate?: string | null;
    className?: string;
    compact?: boolean;
};

const StockInfo = ({ stock, refillDate, className, compact }: StockProps) => {
    const { t } = useTranslation();
    // const hideRefillDate = true;
    const stockSettings = useAppSelector(selectStockSettings);
    const numFormat = useAppSelector(selectNumFormat);
    const pcs = t('stock_pcs', 'pcs');
    const stockDetails = getStockDetails(t, numFormat, stock, stockSettings?.stockInfo);
    const showOutOfStockInfo = !stockDetails.length;

    return true ? ( //TODO enable StockInfo and uncomment
        <></>
    ) : (
        <div className={`${className ? className : ''} f3-400`}>
            <Flex className="f3-700  mb-2" align="center">
                <FontAwesomeIcon icon={faBoxes} />
                <Text small className="ms-3">
                    {t('stock', 'Stock')}
                </Text>
            </Flex>
            <InfoTable className="me-6 w-100">
                <InfoTBody>
                    {!compact && (
                        <>
                            {stockDetails.map((s) => (
                                <Fragment key={s.label}>
                                    {!!s.value && (
                                        <InfoRow>
                                            <InfoTd>{s.label}</InfoTd>
                                            <InfoTd>
                                                {s.value} {pcs}
                                            </InfoTd>
                                        </InfoRow>
                                    )}
                                    {!!s.description && (
                                        <InfoRow>
                                            <InfoTd colSpan={2} small>
                                                {s.description}
                                            </InfoTd>
                                        </InfoRow>
                                    )}
                                </Fragment>
                            ))}
                            {showOutOfStockInfo && (
                                <InfoRow className="f3-700">
                                    <InfoTd className="py-2 pe-0" colSpan={12}>
                                        <WarnIcon icon={faExclamation} />
                                        <Text color="error" small className="ml-3 d-inline-block">
                                            {t('stockLevel_out', 'Out of stock')}
                                        </Text>
                                        <Text color="textLight" fontSize={10} className="f1-400">
                                            {t(
                                                'contact.customerContactUs',
                                                'Contact customer service for more information!',
                                            )}
                                        </Text>
                                    </InfoTd>
                                </InfoRow>
                            )}
                        </>
                    )}
                    {/* {refillDate && refillDate.length > 0 && !hideRefillDate && (
                        <InfoRow>
                            <InfoTd className="">{t('stock.estRefill', 'Est. refill')}</InfoTd>
                            <InfoTd className="">{refillDate}</InfoTd>
                        </InfoRow>
                    )} */}
                </InfoTBody>
            </InfoTable>
        </div>
    );
};

export default StockInfo;

const WarnIcon = styled(FontAwesomeIcon)`
    color: ${({ theme }) => theme.colors.error};
`;

function getStockDetails(
    t: TFunction<'translation', undefined>,
    numFormat: Intl.NumberFormat,
    stock: ProductStock[],
    stockSettings: StockSettings[],
): StockDetail[] {
    let total = 0;
    const stockDetails: StockDetail[] = [];

    for (const setting of stockSettings) {
        if (setting.visible) {
            const s = stock.find((st) => st.type === getStockType(setting.type));

            if (!s?.value) continue;

            stockDetails.push({
                value: numFormat.format(s?.value ?? 0),
                label: setting.label ?? getDefaultLabel(t, setting.type),
                description: setting.description,
            });
        }
    }
    if (stockDetails.length > 1 && total > 0) {
        stockDetails.push({
            value: numFormat.format(total),
            label: t('stock.total', 'Total'),
        });
    }

    return stockDetails;
}

function getDefaultLabel(t: TFunction<'translation', undefined>, type: StockInfoType): string {
    switch (type) {
        case 'Local':
            return t('stock_local', 'Local');
        case 'Region':
            return t('stock_region', 'Region');
        case 'Global':
            return t('stock_global', 'Global');
        case 'General':
            return 'general'; // ! General should be removed
        default:
            return '';
    }
}
