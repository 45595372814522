import { DefaultTheme } from 'styled-components/macro';

export type ThemeSections = 'colors' | 'typography' | 'webColors';

const defaultTheme: DefaultTheme = {
    typography: {
        fontSize: '15px',
        fontBody: 'urw-form',
        fontHeading: 'urw-din-semi-condensed',
        f1400family: 'urw-form',
        f1500family: 'urw-form',
        f1700family: 'urw-form',
        f2400family: 'urw-din-semi-condensed',
        f2700family: 'urw-din-semi-condensed',
        f3300family: 'Roboto',
        f3400family: 'Roboto',
        f3500family: 'Roboto',
        f3700family: 'Roboto',
    },

    colors: {
        main: '#231F20',
        mainText: '#fcfcfc',
        accent: '#BC9B6A',
        accentText: '#FFF',
        attention: '#648F78',
        attentionText: '#FFF',

        // Additional text colors
        text: '#2f2f2f',
        textLight: '#757575',
        textWhite: '#fcfcfc',
        textFaded: '#9E9B97',
        textDisabled: '#cfcecc',

        // Fill colors
        fill: '#F4F4F4',
        fillMedium: '#ECEBE8',
        fillWhite: '#fff',
        fillBlack: '#231F20',
        fillSecondary: '#231F20',
        fillSecondaryText: '#F4F4F4',
        fillHover: '#f5f5f5',
        fillGray: '#e9e9e9',

        transparent: 'transparent',
        transparentBg: '#dce2ed',

        border: '#CFCEC8',
        borderLight: '#e9e9e9',
        borderGray: '#ececec',
        borderDark: '#e2e2e2',

        success: '#7acf8b',
        error: '#ff6e6e',
        warning: '#eed447',
        sale: '#ff6e6e',
        buy: '#648F78',
        price: '#648F78',
        sustainable: '#648F78',
        disabled: '#EFEFEF',
        socialMedia: '#BC9B6A', //Icons
    },
    webColors: {
        beige: '#fde3a7',
        black: '#000',
        blue: '#3e94cf',
        brown: '#ba7e4a',
        gold: '#e0d7b0',
        green: '#3ead6c',
        grey: '#bdc3c7',
        navyblue: '#405870',
        orange: '#f29d3a',
        pink: '#f1a9a0',
        purple: '#a6589d',
        red: '#eb4b3b',
        silver: '#d2d7d3',
        white: '#ffffff',
        yellow: '#fff270',
    },
    shadows: {
        soft: 'box-shadow: 0 0px 14px rgb(0 0 0 / 6%);',
        medium: 'box-shadow: 0px 0px 16px 0 rgba(0,0,0,0.12);',
        high: 'box-shadow: 0px 0px 24px 0 rgba(0,0,0,0.16);',
    },
    media: {
        xs: '@media screen and (min-width: 480px)',
        sm: '@media screen and (min-width: 640px)',
        smBelow: '@media screen and (max-width: 639px)',
        md: '@media screen and (min-width: 768px)',
        mdBelow: '@media screen and (max-width: 767px)',
        lg: '@media screen and (min-width: 1025px)',
        lgBelow: '@media screen and (max-width: 1024px)',
        xl: '@media screen and (min-width: 1696px)',
        xlBelow: '@media screen and (max-width: 1696px)',
    },
    borders: {
        underline1: 'border-bottom: 1px solid',
        underline2: 'border-bottom: 2px solid',
        radius: '4px',
    },
    transitions: {
        bez: 'cubic-bezier(0.55, 0.085, 0.68, 0.53)',
    },
    breakpoints: {
        min: 0,
        xs: 480,
        sm: 640,
        md: 768,
        lg: 1025,
        xl: 1696,
    },
    main: 'blue-grey',
    accent: 'amber',
    lightness: 5,
    footer: '',
};

export default defaultTheme;
