import ThemeButton from 'components/ui/ThemeButton';
import useAppSelector from 'hooks/useAppSelector';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import styled from 'styled-components/macro';

const Agreement = () => {
    const { t } = useTranslation();
    const { shop } = useAppSelector(({ shop }) => shop);
    const [isOpen, toggleAgreement] = useState(false);
    return (
        <>
            <StyledAgreementButton
                $active={shop?.agreement ? true : false}
                onClick={shop?.agreement ? () => toggleAgreement(true) : undefined}
            >
                {t('agreement').toLowerCase()}
            </StyledAgreementButton>
            <Modal isOpen={isOpen} toggle={() => toggleAgreement} size="lg">
                <ModalHeader>{t('agreement')}</ModalHeader>
                <ModalBody className="preformatted">{shop?.agreement}</ModalBody>
                <ModalFooter>
                    <Col lg="1">
                        <ThemeButton onClick={() => toggleAgreement(false)} text={t('close')} />
                    </Col>
                </ModalFooter>
            </Modal>
        </>
    );
};

const StyledAgreementButton = styled.div<{ $active: boolean }>`
    ${({ $active }) =>
        $active &&
        `
        cursor: pointer;
        text-decoration: underline;
        
    `}
`;

export default Agreement;
