import Button from 'components/ui/Button';
import Flex from 'components/ui/Flex';
import LinkStyle from 'components/ui/LinkStyle';
import Radio from 'components/ui/Radio';
import Text from 'components/ui/Text';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import { DeliveryAddressInput, Options } from 'microshop-api';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import {
    CartDelivery,
    Valid,
    deliverySet,
    selectedB2BDeliveryAddress,
    validateAddress,
} from 'store/reducers/cartSlice';
import styled from 'styled-components/macro';

interface DeliveryProps {
    newAddressInfoText?: string;
    newAddressInfoHeader?: string;
    onEditAddress?: () => void;
    className?: string;
}

export const Delivery: React.FC<DeliveryProps> = ({
    newAddressInfoText,
    newAddressInfoHeader,
    onEditAddress,
    ...props
}) => {
    const options = useAppSelector(({ user }) => user.userIdentity?.orderOptions);
    const valid = useAppSelector(({ cart }) => cart.b2bCheckout.valid.address);
    const delivery = useAppSelector(selectedB2BDeliveryAddress);

    const ref = useRef<HTMLDivElement>(null);
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const setAddress = (id: number) => {
        dispatch(deliverySet({ name: 'addressId', value: id }));
    };

    const handleOk = (e: any) => {
        e.preventDefault();
        dispatch(validateAddress());

        if (ref.current) {
            const element = ref.current.getBoundingClientRect().top + window.scrollY;

            window.scroll({
                top: element - 150,
                behavior: 'smooth',
            });
        }
    };

    const address = getSelectedAddress(delivery, true, options);
    return (
        <DeliveryWrapper ref={ref} {...props}>
            {valid === Valid.Valid || onEditAddress == null ? (
                <DeliveryAddressField address={address} onEditAddress={onEditAddress} />
            ) : (
                onEditAddress != null && (
                    <>
                        {<ListAddresses className="mb-3" onAddressSelect={(address) => setAddress(+address.id)} />}
                        <Row className="mt-4">
                            <Col sm="12" className="text-right d-flex flex-row-reverse">
                                <Button onClick={handleOk}>{t('action.continue', 'Continue')}</Button>
                            </Col>
                        </Row>
                    </>
                )
            )}
        </DeliveryWrapper>
    );
};
export default Delivery;

export const DeliveryAddressField: React.FC<{
    address: Address;
    onEditAddress?: () => void;
}> = ({ address, onEditAddress }) => {
    const { t } = useTranslation();
    return (
        <>
            <StyledText uppercase className="f3-700">
                {address?.name}
            </StyledText>
            {address?.recip && <Text>{address?.recip}</Text>}
            <StyledText light>
                {address?.street ?? address?.adressLine1}, {address?.zip ?? address?.postalCode} {address?.city}
            </StyledText>
            {onEditAddress != null && (
                <LinkStyle
                    onClick={() => onEditAddress()}
                    className="d-block mt-2 align"
                    fontSize="12px"
                    color="textFaded"
                >
                    {t('action.change', 'Change')}
                </LinkStyle>
            )}
        </>
    );
};

export const ListAddresses: React.FC<{
    onAddressSelect: (address: any) => void;
    useSelectedAddress?: boolean;
    className?: string;
}> = ({ onAddressSelect, useSelectedAddress = true, className }) => {
    const delivery = useAppSelector(({ cart }) => cart.b2bCheckout.delivery);
    const options = useAppSelector(({ user }) => user.userIdentity?.orderOptions);

    return (
        <>
            {options?.deliveryAddresses?.map((address) => (
                <AddressItem
                    align="center"
                    className="mb-3"
                    key={'address_' + address.id}
                    onClick={() => address.id && onAddressSelect(address)}
                >
                    <Radio
                        bgColor="fill"
                        defaultChecked={useSelectedAddress ? address.id === delivery?.addressId?.toString() : false}
                        name="agreement"
                        className="mb-1"
                        onChange={() => null}
                    />
                    <AddressText>
                        <Text fontSize={14} className="f3-700">
                            {address.name}
                        </Text>
                        <Text fontSize={14}>
                            {address.street}, {address.zipCode} {address.city}
                        </Text>
                        <Text fontSize={14}>{address.city}</Text>
                        <Text fontSize={14}>{address.country}</Text>
                    </AddressText>
                </AddressItem>
            ))}
        </>
    );
};

const DeliveryWrapper = styled.div`
    max-width: 100%;
`;

const StyledText = styled(Text)`
    white-space: break-spaces;
`;

type Address = DeliveryAddressInput & {
    name?: string | null;
    recip?: string | null;
    street?: string | null;
    street2?: string | null;
    zip?: string | null;
    city?: string | null;
    country?: string | null;
    adressLine1?: string | null;
    postalCode?: string | null;
};

export function getSelectedAddress(delivery: CartDelivery | undefined, b2b: boolean, options?: Options): Address {
    const input =
        delivery?.addressId && delivery.addressId !== 999
            ? options?.deliveryAddresses?.find((a) => a.id === delivery?.addressId?.toString())
            : delivery;

    if (!input) return {};

    const b2bAddress = isB2BAddress(input);

    if (b2bAddress) {
        return {
            name: input.name,
            recip: undefined,
            street: input.street,
            street2: input.street2,
            zip: input.zipCode,
            city: input.city,
            country: input.country,
        };
    } else {
        if (b2b) {
            return {
                name: input.company,
                recip: undefined,
                street: input.address1,
                street2: input.address2,
                zip: input.postalCode,
                city: input.city,
                country: undefined,
            };
        } else {
            return {
                name: input.company ? input.company : `${input.firstName} ${input.lastName}`,
                recip: input.company ? `${input.firstName} ${input.lastName}` : undefined,
                street: input.address1,
                street2: undefined,
                zip: input.postalCode,
                city: input.city,
                country: undefined,
            };
        }
    }

    // return {
    //   name: isB2BAddress(input) ? input.name : input.company ? input.company : `${input.firstName} ${input.lastName}`,
    //   recip: !isB2BAddress(input) && input.company ? input.firstName + ' ' + input.lastName : undefined,
    //   street: isB2BAddress(input) ? input.street : input.address1,
    //   zip: isB2BAddress(input) ? input.zipCode : input.postalCode,
    //   city: input.city,
    //   country: isB2BAddress(input) ? input.country : undefined,
    // };
}

export function isB2BAddress(input: CartDelivery | DeliveryAddressInput): input is DeliveryAddressInput {
    return !!input && input.hasOwnProperty('name');
}

const AddressItem = styled(Flex)`
    cursor: pointer;
`;

const AddressText = styled.div``;
