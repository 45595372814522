import Flex from 'components/ui/Flex';
import useAppSelector from 'hooks/useAppSelector';
import { Card, CardBody, CardHeader, Container } from 'reactstrap';
import styled from 'styled-components/macro';
import * as themeHelper from 'theme/functions';

const ShopLoadLayout = ({ children }: { children: React.ReactNode }) => {
    const info = useAppSelector(({ shop }) => shop.info);
    const theme = useAppSelector(({ shop }) => shop.theme);

    return (
        <StyledBackground
            className="justify-content-center align-items-center"
            style={info?.backgroundImage ? { backgroundImage: 'url("' + info.backgroundImage + '")' } : {}}
        >
            <Container>
                <StyledCard className="mx-auto">
                    <StyledCardHeader $color={theme.main} className={`${themeHelper.lightnessClass(theme)}`}>
                        <Flex className="justify-content-center pt-4 mb-4">
                            {info?.logoType && <StyledImage alt="" src={info?.logoType} />}
                        </Flex>
                        <div
                            className={`d-flex mt-2 justify-content-center align-items-center ${themeHelper.textClass(
                                theme,
                            )}`}
                        >
                            <h2>{info?.name}</h2>
                        </div>
                    </StyledCardHeader>
                    <CardBody className="p-5">{children}</CardBody>
                </StyledCard>
            </Container>
        </StyledBackground>
    );
};
const StyledCard = styled(Card)`
    max-width: 700px;
`;

const StyledCardHeader = styled(CardHeader)<{ $color: string }>`
    ${({ $color }) => $color && `background-color: ${$color};`}
`;

const StyledBackground = styled(Flex)`
    flex-grow: 2;
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
`;

const StyledImage = styled.img`
    max-height: 150px;
    max-width: 100%;
`;

export default ShopLoadLayout;
