import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/ui/Flex';
import Text from 'components/ui/Text';
import useAppSelector from 'hooks/useAppSelector';
import debounce from 'lodash.debounce';
import { ProductStock, ProductStockSku, ShopCompleteVariation, StockLevel } from 'microshop-api';
import { darken, lighten } from 'polished';
import React, { FC, Fragment, RefObject, createRef, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import findLastIndex from 'utils/findLastIndex';
import { QuantityChangedFunction } from './ProductBuy';

import {
    CollectionVariation,
    StockSettings,
    getStockType,
    selectNumFormat,
    selectStockSettings,
} from 'store/reducers/productSlice';
import SkuInfo from './SkuInfo';
import StockIcon from './StockIcon';
import StockLegend from './StockLegend';
import VariationInput from './VariationInput';
import { isSameCollectionVariation } from 'store/reducers/cartSlice';
type SkuCell = {
    sku: ProductStockSku;
    name?: string | null;
    variation?: CollectionVariation | null;
    skuIndex: number;
    ref: RefObject<HTMLInputElement>;
};

type ElRefs = Map<string, RefObject<HTMLInputElement>[]>;

type ProductMatrixProps = {
    className?: string;
    variations?: CollectionVariation[] | null;
    selectedVariation?: CollectionVariation | null;
    replacementProductNr?: string;
    onVariationSelect: (v: CollectionVariation) => void;
    onAddToCart: () => void;
    onQuantityChange: QuantityChangedFunction;
    cells: Map<string, number | undefined>;
    compact: boolean;
    addToCartEnabled: boolean;
    variationMinQts: {
        [key: string]: number;
    };
};

const ProductMatrix: FC<ProductMatrixProps> = ({
    className,
    variations,
    replacementProductNr,
    onVariationSelect,
    selectedVariation,
    onAddToCart,
    onQuantityChange,
    cells,
    compact,
    addToCartEnabled,
    variationMinQts,
}) => {
    const numFormat = useAppSelector(selectNumFormat);
    const [headers, setHeaders] = useState<[number, string][]>();
    const [selectedCell, setSelectedCell] = useState<SkuCell | undefined | null>();
    const [isFocused, setIsFocused] = useState(false);
    const [refs, setRefs] = useState<ElRefs>(new Map());
    const { t } = useTranslation();
    const disableNoStock = false;
    const compactRef = useRef<HTMLDivElement>(null);
    const compactScrollRef = useRef<HTMLDivElement>(null);
    const wideScrollRef = useRef<HTMLDivElement>(null);
    const [wideInfoScroll, setWideInfoScroll] = useState<{ scroll: number; maxWidth: number }>({
        scroll: 0,
        maxWidth: 0,
    });
    const [compactHeight, setCompactHeight] = useState<number>();
    const stockSettings = useAppSelector(selectStockSettings);

    const handleCellSelected = (
        sku: ProductStockSku,
        variation: CollectionVariation,
        skuIndex: number,
        ref: RefObject<HTMLInputElement>,
    ) => {
        setSelectedCell({ sku, name: sku.name, variation, skuIndex, ref });
    };

    const handleQtyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const qty = !Number.isNaN(+e.target.value) ? +e.target.value : 0;
        const sku = selectedCell?.sku.sku;
        const variation = selectedCell?.variation;

        if (!sku || !variation) return;

        if (!selectedVariation || !isSameCollectionVariation(variation, selectedVariation)) {
            onVariationSelect(variation);
        }

        onQuantityChange(sku, qty, variation, 1);
    };

    const hasMinQtyError = (variationNr?: string | null, minQty?: number) => {
        if (!variationNr) return false;

        return variationMinQts[variationNr] > 0 && variationMinQts[variationNr] !== minQty;
    };

    // Setup matrix headers and cell refs.
    useEffect(() => {
        let headers = variations?.reduce((acc, curr) => {
            curr.skus?.forEach((s) => {
                typeof s.sort === 'number' && acc.set(s.sort, s.name || '');
            });

            return acc;
        }, new Map<number, string>());
        if (headers?.size === 1) {
            // one size product, replace header (which is often "." or "0") with more useful header
            const sort = headers.keys().next().value;
            headers = new Map([[sort, t('cart.quantity', 'Quantity')]]);
        }

        const headerArray = headers ? [...headers?.entries()].sort((a, b) => a[0] - b[0]) : [];
        setHeaders(headerArray);
        if (!headerArray.length) return;

        const elRefs: ElRefs | undefined = variations?.reduce((acc, v) => {
            if (v.variationNumber) {
                const skuRefs = Array(headerArray.length)
                    .fill(undefined)
                    .map(() => createRef<HTMLInputElement>());

                acc.set(v.variationNumber, skuRefs);
            }
            return acc;
        }, new Map());

        elRefs && setRefs(elRefs);
    }, [variations, t]);

    // Keyboard shortcuts.
    useEffect(() => {
        const onKey = (e: KeyboardEvent) => {
            let key = e.key;
            // Arrow or enter keys (tab is handled by regular tabindex)
            if (
                !isFocused ||
                !['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Enter'].includes(key) ||
                !selectedCell?.variation
            )
                return;

            e.stopPropagation();
            e.preventDefault();

            let direction = key === 'Enter' ? undefined : getKeyDirection(key, compact);
            let wrapped = false;

            // Left or right
            if (direction === 'prevSku' || direction === 'nextSku') {
                let curIndex = selectedCell.skuIndex;
                const skus = refs.get(getCollectionVariationString(selectedCell.variation)) || [];
                let found = false;

                // select next or previous sku.
                while (curIndex >= 0 && curIndex < skus.length) {
                    curIndex += direction === 'prevSku' ? -1 : 1;
                    const newFocus = skus[curIndex];
                    if (newFocus?.current) {
                        newFocus.current.focus();
                        found = true;
                        break;
                    }
                }

                if (!found && compact) {
                    direction = direction === 'prevSku' ? 'prevColor' : 'nextColor';
                    wrapped = true;
                }
            }

            // select next or previous variation.
            if (direction === 'prevColor' || direction === 'nextColor') {
                const variationCodeArray = Array.from(refs.keys());
                let varIndex = variationCodeArray.findIndex(
                    (variationCode) => variationCode === getCollectionVariationString(selectedCell.variation),
                );

                if (varIndex === -1) return;

                while (varIndex >= 0 && varIndex < variationCodeArray.length) {
                    varIndex += direction === 'prevColor' ? -1 : 1;
                    const varCode = variationCodeArray[varIndex];
                    if (!varCode) return;

                    const skus = refs.get(varCode) || [];

                    const newFocus = getNextFocus(skus, wrapped, direction, selectedCell.skuIndex);

                    if (newFocus?.current) {
                        newFocus.current.focus();
                        break;
                    }
                }
            }

            // Put all qts in cart after current cell is updated.
            if (addToCartEnabled && key === 'Enter') {
                selectedCell.ref?.current && selectedCell.ref.current.blur();
                onAddToCart();
            }
        };

        window.addEventListener('keydown', onKey);
        return () => window.removeEventListener('keydown', onKey);
    }, [isFocused, selectedCell, onAddToCart, refs, compact, addToCartEnabled]);

    useEffect(() => {
        if (compactRef.current) {
            setCompactHeight(compactRef.current.clientHeight);
        }
    }, [compactRef, compact]);

    useEffect(() => {
        const scrollEl = wideScrollRef.current;

        const updateScroll = () => {
            if (!scrollEl) return;
            setWideInfoScroll({ scroll: scrollEl.scrollLeft, maxWidth: scrollEl.clientWidth });
        };

        // Call updateScroll on mount.
        updateScroll();

        const debUpdateScroll = debounce(updateScroll, 150);

        scrollEl?.addEventListener('scroll', debUpdateScroll);
        window?.addEventListener('resize', debUpdateScroll);
        return () => {
            scrollEl?.removeEventListener('scroll', debUpdateScroll);
            window?.removeEventListener('resize', debUpdateScroll);
        };
    }, [wideScrollRef]);

    const hasLow =
        addToCartEnabled && variations
            ? variations
                  ?.flatMap((v) => v.skus)
                  .findIndex((s) => s?.stock?.every((stock) => stock.stockLevel === StockLevel.LowStock)) !== -1
            : false;
    const hasOut =
        addToCartEnabled && variations
            ? variations
                  ?.flatMap((v) => v.skus)
                  .findIndex((s) => s?.stock?.every((stock) => stock.stockLevel === StockLevel.OutOfStock)) !== -1
            : false;

    return (
        <OuterWrapper className={`${className} f3-400`}>
            {!addToCartEnabled && (
                <StockLegend className="mb-2 align-self-end" inStock={true} lowStock={hasLow} noStock={hasOut} />
            )}
            {compact ? (
                <Flex align="start">
                    <CompactColorWrapper
                        column
                        justify="start"
                        align="start"
                        ref={compactRef}
                        minHeight={getCompactmatrixMinHeight(headers?.length || 0, !!selectedCell)}
                    >
                        {variations?.map((v) => (
                            <StyledVariationInput
                                key={`dot_${v.variationNumber}`}
                                compact
                                highlighted={
                                    !!selectedCell?.variation && isSameCollectionVariation(selectedCell.variation, v)
                                }
                                variation={v}
                                selected={selectedVariation === v.variationNumber}
                                onSelect={(e) => {
                                    if (v.variationNumber) {
                                        const firstRef = refs.get(v.variationNumber)?.find((r) => !!r.current);

                                        compactScrollRef.current?.scrollTo({
                                            top: (firstRef?.current?.parentElement?.offsetTop || 44) - 44,
                                            left: 0,
                                            behavior: 'smooth',
                                        });
                                    }

                                    onVariationSelect(e);
                                }}
                                onFocus={() => setSelectedCell(null)}
                                className="p-2 text-center"
                                error={hasMinQtyError(v.variationNumber, v.minQuantity)}
                            />
                        ))}
                    </CompactColorWrapper>
                    <CompactScrollWrapper
                        height={compactHeight}
                        ref={compactScrollRef}
                        minHeight={getCompactmatrixMinHeight(headers?.length || 0, !!selectedCell)}
                    >
                        <Matrix>
                            <MatrixBody>
                                {variations?.map((v) => {
                                    const refCells = getCells(refs, v.variationNumber || '', headers, v.skus);
                                    return (
                                        <Fragment key={`color_${v.variationNumber}`}>
                                            <Row>
                                                <HeaderCellRow
                                                    colSpan={2}
                                                    className="f3-400 px-3 text-capitalize"
                                                    selected={
                                                        !!selectedCell?.variation &&
                                                        isSameCollectionVariation(selectedCell.variation, v)
                                                    }
                                                    dark
                                                >
                                                    {v.color || v.variationNumber}
                                                    <Text small light className="ml-1 d-inline-block">
                                                        {v.colorCode && <>({v.colorCode})</>}
                                                    </Text>
                                                </HeaderCellRow>
                                            </Row>
                                            {v.skus &&
                                                v.skus.length > 0 &&
                                                v.skus.map((sku, j) => {
                                                    const s = refCells.find(
                                                        (c) => isProductSku(c) && c.sku.sku === sku.sku,
                                                    ) as SkuCell;
                                                    if (!s || !s.sku.sku) return null;
                                                    return (
                                                        <Fragment key={s.sku.sku}>
                                                            <Row>
                                                                <>
                                                                    <HeaderCellRow
                                                                        className="px-3 f3-700"
                                                                        selected={selectedCell?.sku.sku === s.sku.sku}
                                                                    >
                                                                        <Text small className="text-capitalize">
                                                                            {s.name}
                                                                        </Text>
                                                                    </HeaderCellRow>
                                                                    <Cell selected={selectedCell?.sku?.sku === s.sku}>
                                                                        <>
                                                                            {selectedCell?.sku?.sku !== s.sku.sku &&
                                                                                !cells.get(s.sku.sku) && (
                                                                                    <StockNumber
                                                                                        stockRows={s.sku.stock ?? []}
                                                                                        numFormat={numFormat}
                                                                                        settings={
                                                                                            stockSettings.stockInfo
                                                                                        }
                                                                                    />
                                                                                )}
                                                                            <CellInput
                                                                                ref={s.ref}
                                                                                name="selectedCell"
                                                                                autoComplete="off"
                                                                                type="number"
                                                                                step="1"
                                                                                value={cells.get(s.sku.sku) || ''}
                                                                                onChange={(e) => {
                                                                                    if (
                                                                                        s.sku.stock?.every(
                                                                                            (stock) =>
                                                                                                stock.stockLevel ===
                                                                                                StockLevel.OutOfStock,
                                                                                        ) &&
                                                                                        disableNoStock
                                                                                    )
                                                                                        return;
                                                                                    else handleQtyChange(e);
                                                                                }}
                                                                                hide={
                                                                                    !s.sku ||
                                                                                    selectedCell?.sku?.sku !== s.sku.sku
                                                                                }
                                                                                dirty={!!cells.get(s.sku.sku)}
                                                                                min={0}
                                                                                selected={
                                                                                    selectedCell?.sku.sku === s.sku.sku
                                                                                }
                                                                                onFocus={() => {
                                                                                    handleCellSelected(
                                                                                        s.sku,
                                                                                        v,
                                                                                        j,
                                                                                        s.ref,
                                                                                    );
                                                                                    setIsFocused(true);
                                                                                }}
                                                                                onBlur={() => {
                                                                                    setIsFocused(false);
                                                                                }}
                                                                                disabled={!addToCartEnabled}
                                                                                error={hasMinQtyError(
                                                                                    v.variationNumber,
                                                                                    v.minQuantity,
                                                                                )}
                                                                            />
                                                                        </>
                                                                    </Cell>
                                                                </>
                                                            </Row>
                                                            {selectedCell && selectedCell.sku.sku === s.sku?.sku ? (
                                                                <Row>
                                                                    <InfoCell className="text-left p-3" colSpan={2}>
                                                                        <StyledSkuInfo
                                                                            replacementPnr={replacementProductNr}
                                                                            variation={v}
                                                                            sku={selectedCell.sku}
                                                                        />
                                                                        {selectedCell.variation?.variationNumber &&
                                                                            hasMinQtyError(
                                                                                selectedCell.variation.variationNumber,
                                                                                v.minQuantity,
                                                                            ) && (
                                                                                <Invalid className="mt-1">{`${t(
                                                                                    'cart.minOrderQty',
                                                                                    'Minimum order quantity',
                                                                                )}: ${v.minQuantity} (-${
                                                                                    variationMinQts[
                                                                                        selectedCell.variation
                                                                                            .variationNumber
                                                                                    ]
                                                                                })`}</Invalid>
                                                                            )}
                                                                    </InfoCell>
                                                                </Row>
                                                            ) : null}
                                                        </Fragment>
                                                    );
                                                })}
                                        </Fragment>
                                    );
                                })}
                            </MatrixBody>
                        </Matrix>
                    </CompactScrollWrapper>
                </Flex>
            ) : (
                <MatrixScrollWrapper ref={wideScrollRef}>
                    <Matrix>
                        <MatrixBody>
                            <Row>
                                <HeaderCellRow
                                    single={!!headers && headers.length === 1}
                                    as="th"
                                    className="f3-700"
                                ></HeaderCellRow>
                                {headers &&
                                    headers.map(([key, val]) => (
                                        <HeaderCellCol as="th" key={key} selected={selectedCell?.name === val}>
                                            <Text small className="text-capitalize">
                                                {val}
                                            </Text>
                                        </HeaderCellCol>
                                    ))}
                            </Row>
                            {variations?.map((v, i) => {
                                const hasQtyError = hasMinQtyError(v.variationNumber, v.minQuantity);
                                return (
                                    <Fragment key={`${v.colorCode || v.variationNumber}_${i}`}>
                                        <Row>
                                            <HeaderCellRow
                                                className="f3-700"
                                                selected={
                                                    !!selectedCell?.variation &&
                                                    isSameCollectionVariation(selectedCell?.variation, v)
                                                }
                                            >
                                                <StyledVariationInput
                                                    highlighted={
                                                        !!selectedCell?.variation &&
                                                        isSameCollectionVariation(selectedCell?.variation, v)
                                                    }
                                                    variation={v}
                                                    selected={selectedVariation === v.variationNumber}
                                                    onSelect={onVariationSelect}
                                                    onFocus={() => setSelectedCell(null)}
                                                    error={hasQtyError}
                                                />
                                            </HeaderCellRow>
                                            {getCells(refs, v.variationNumber || '', headers, v.skus)?.map((s, j) =>
                                                isProductSku(s) && s.sku.sku ? (
                                                    <Cell
                                                        key={`${s.sku.sku}_${j}`}
                                                        selected={selectedCell?.sku?.sku === s.sku}
                                                        selectedSibling={
                                                            selectedCell?.name === s.name ||
                                                            (!!selectedCell?.variation &&
                                                                isSameCollectionVariation(selectedCell?.variation, v))
                                                        }
                                                    >
                                                        <>
                                                            {selectedCell?.sku?.sku !== s.sku.sku &&
                                                                !cells.get(s.sku.sku) && (
                                                                    <StockNumber
                                                                        stockRows={s.sku.stock ?? []}
                                                                        numFormat={numFormat}
                                                                        settings={stockSettings.stockInfo}
                                                                    />
                                                                )}
                                                            <CellInput
                                                                ref={s.ref}
                                                                name="selectedCell"
                                                                autoComplete="off"
                                                                type="number"
                                                                step="1"
                                                                value={cells.get(s.sku.sku) || ''}
                                                                onChange={(e) => {
                                                                    if (
                                                                        s.sku.stock?.every(
                                                                            (stock) =>
                                                                                stock.stockLevel ===
                                                                                StockLevel.OutOfStock,
                                                                        ) &&
                                                                        disableNoStock
                                                                    )
                                                                        return;
                                                                    else handleQtyChange(e);
                                                                }}
                                                                hide={!s.sku || selectedCell?.sku?.sku !== s.sku.sku}
                                                                dirty={!!cells.get(s.sku.sku)}
                                                                min={0}
                                                                selected={selectedCell?.sku.sku === s.sku.sku}
                                                                onFocus={() => {
                                                                    handleCellSelected(s.sku, v, j, s.ref);
                                                                    setIsFocused(true);
                                                                }}
                                                                onBlur={() => {
                                                                    setIsFocused(false);
                                                                }}
                                                                disabled={!addToCartEnabled}
                                                                error={hasQtyError}
                                                            />
                                                        </>
                                                    </Cell>
                                                ) : (
                                                    <Cell
                                                        key={`inactiveSku_${i}_${j}`}
                                                        inactive
                                                        selectedSibling={
                                                            selectedCell?.name === s.name ||
                                                            (!!selectedCell?.variation &&
                                                                isSameCollectionVariation(selectedCell?.variation, v))
                                                        }
                                                    />
                                                ),
                                            )}
                                        </Row>
                                        {selectedCell?.variation &&
                                        isSameCollectionVariation(selectedCell.variation, v) ? (
                                            <Row>
                                                <InfoCell
                                                    className="text-left p-3"
                                                    colSpan={(headers?.length || 0) + 1}
                                                >
                                                    <MovingWrapper
                                                        left={wideInfoScroll.scroll}
                                                        maxWidth={wideInfoScroll.maxWidth}
                                                    >
                                                        <CloseMatrixIcon
                                                            icon={faXmark}
                                                            onClick={() => setSelectedCell(null)}
                                                        />
                                                        <StyledSkuInfo
                                                            replacementPnr={replacementProductNr}
                                                            variation={v}
                                                            sku={selectedCell.sku}
                                                        />
                                                        {selectedCell.variation.variationNumber &&
                                                            hasMinQtyError(
                                                                selectedCell.variation.variationNumber,
                                                                v.minQuantity,
                                                            ) && (
                                                                <Invalid className="mt-1">{`${t(
                                                                    'cart.minOrderQty',
                                                                    'Minimum order quantity',
                                                                )}: ${v.minQuantity} (-${
                                                                    variationMinQts[
                                                                        selectedCell.variation.variationNumber
                                                                    ]
                                                                })`}</Invalid>
                                                            )}
                                                    </MovingWrapper>
                                                </InfoCell>
                                            </Row>
                                        ) : null}
                                    </Fragment>
                                );
                            })}
                        </MatrixBody>
                    </Matrix>
                </MatrixScrollWrapper>
            )}
        </OuterWrapper>
    );
};

export default ProductMatrix;

type StockNumberProps = {
    numFormat: Intl.NumberFormat;
    stockRows: ProductStock[];
    settings: StockSettings[];
};
const StockNumber = ({ numFormat, stockRows, settings }: StockNumberProps) => {
    let stockValue = 0;
    let stockLevel: StockLevel = 0;
    let innerStockValue = 0;
    let noStockIcon = true;

    for (const setting of settings) {
        if (!setting.visible) continue;

        noStockIcon = false;
        const stock = stockRows.find((st) => st.type === getStockType(setting.type));
        if (!setting.excludeFromStockAtHand) {
            if (stock?.value) stockValue += stock.value;
            if (stock?.stockLevel && stock.stockLevel > stockLevel) stockLevel = stock?.stockLevel;
        } else if (stock?.value) {
            // Visible in stockinfo when selecting cell but not in matrix
            innerStockValue += stock.value;
        }
    }

    return (
        <CellText light small className="text-capitalize">
            {stockValue ? (
                numFormat.format(stockValue)
            ) : (
                <StockIcon
                    stockLevel={stockLevel}
                    unknown={noStockIcon}
                    innerStock={stockLevel === StockLevel.OutOfStock && !!innerStockValue}
                />
            )}
        </CellText>
    );
};

const MovingWrapper = styled.div<{ left: number; maxWidth: number }>`
    position: relative;
    display: inline-block;
    left: ${({ left }) => left}px;
    max-width: ${({ maxWidth }) => (maxWidth ? `calc(${maxWidth}px - 2rem)` : 'auto')};
    transition: left ${({ theme }) => theme.transitions.bez} 150ms;
    width: 100%;
`;

const StyledSkuInfo = styled(SkuInfo)``;

const OuterWrapper = styled.div`
    font-size: 12px;
    display: flex;
    flex-direction: column;
`;

const MatrixScrollWrapper = styled.div`
    overflow: auto;
`;

const CompactScrollWrapper = styled(MatrixScrollWrapper)<{ height?: number; minHeight?: number }>`
    position: relative;
    ${({ height }) => height && `max-height: ${height}px;`}
    ${({ minHeight }) => minHeight && `min-height: ${minHeight}px;`}
`;

const CompactColorWrapper = styled(Flex)<{ minHeight?: number }>`
  ${({ minHeight }) => minHeight && `min-height: ${minHeight}px;`}
  background: ${({ theme }) => theme.colors.fillMedium};
`;

const Matrix = styled.table<{ fixed?: boolean }>`
    width: 100%;
    table-layout: fixed;
    border-spacing: 2px;
    border-collapse: separate;
    margin-top: -2px;
`;

const MatrixBody = styled.tbody`
    background: ${({ theme }) => theme.colors.fillMedium};
`;

const Row = styled.tr`
    &:last-of-type {
        border-bottom: none;
    }
`;

const Cell = styled.td<{ selected?: boolean; selectedSibling?: boolean; inactive?: boolean }>`
    position: relative;
    width: 80px;
    height: 42px;

    &:last-of-type {
        border-right: none;
    }

    ${({ selectedSibling, theme }) =>
        selectedSibling &&
        `
      background: ${theme.colors.fill};
    `}
    ${({ selected, theme }) =>
        selected &&
        ` 
      background: ${theme.colors.fillWhite};
    `}
`;
const HeaderCellRow = styled(Cell)<{ single?: boolean; dark?: boolean }>`
    width: ${({ single }) => (single ? '50%' : '138px')};

    ${({ dark, selected, theme }) =>
        (dark || selected) &&
        `
      background: ${darken(0.03, theme.colors.fillMedium)};
    `}
`;

const HeaderCellCol = styled(Cell)`
    text-align: center;

    ${({ selected, theme }) =>
        selected &&
        `
      background: ${darken(0.03, theme.colors.fillMedium)};
    `}
`;

const InfoCell = styled(Cell)`
    background: ${({ theme }) => theme.colors.fillWhite};
`;

const CellInput = styled.input<{ hide: boolean; dirty: boolean; selected: boolean; error?: boolean }>`
  height: 100%;
  width: 100%;
  text-align: center;
  background: white;
  border: none;
  ${({ hide, dirty }) => hide && !dirty && 'opacity: 0;'}
  ${({ selected, theme }) => selected && `outline: 1px solid  ${theme.colors.accent};`}
  ${({ dirty, error, theme }) => error && dirty && `outline: 1px solid  ${theme.colors.error};`}

  &:focus {
    outline: 1px solid ${({ theme }) => theme.colors.accent};
  }
`;

const CellText = styled(Text)`
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
`;

const StyledVariationInput = styled(VariationInput)<{ highlighted?: boolean; selected: boolean; error?: boolean }>`
    width: 100%;
    border: 1px solid transparent;
    padding: 0.2rem 0.45rem;

    ${({ highlighted, theme }) =>
        highlighted &&
        `
    background: ${darken(0.03, theme.colors.fillMedium)};
    > div {
      color: ${theme.colors.text};
  }`}

    ${({ error, theme }) => error && `background: ${lighten(0.14, theme.colors.error)};`}


  &:focus {
        background: white;
        border: 1px solid ${({ theme }) => theme.colors.accent};
    }
`;

const Invalid = styled.div`
    font-size: 14px;
    color: ${({ theme }) => theme.colors.error};
`;

export const CloseMatrixIcon = styled(FontAwesomeIcon)`
    position: absolute;
    font-size: 16px;
    top: 0;
    right: 0;
    cursor: pointer;
`;

function getRef(variationNr: string, refs: ElRefs, index: number) {
    return variationNr && refs.get(variationNr)?.length ? refs.get(variationNr)![index] : null;
}

function getCells(
    refs: ElRefs,
    variationNr: string,
    cols?: [number, string][],
    skus?: ProductStockSku[] | null,
): (SkuCell | { name: string })[] {
    if (!cols) return [];

    const rowCells = cols.map((entry, i) => {
        const [order, name] = entry;
        const found = skus?.find((s) => s.sort === order);

        if (found)
            return {
                name,
                sku: found,
                variationNr,
                skuIndex: order,
                ref: getRef(variationNr, refs, i),
            };
        return { name };
    });

    return rowCells;
}

function isProductSku(obj: SkuCell | { name: string }): obj is SkuCell {
    return obj.hasOwnProperty('sku');
}

function getKeyDirection(key: string, compact: boolean): 'prevColor' | 'nextColor' | 'prevSku' | 'nextSku' | undefined {
    switch (key) {
        case 'ArrowLeft':
            return 'prevSku';
        case 'ArrowRight':
            return 'nextSku';
        case 'ArrowUp':
            return compact ? 'prevSku' : 'prevColor'; // Do not change color on standing matrix.
        case 'ArrowDown':
            return compact ? 'nextSku' : 'nextColor'; // Do not change color on standing matrix.
        default:
            return undefined;
    }
}

function getNextFocus(
    skus: React.RefObject<HTMLInputElement>[],
    wrapped: boolean,
    direction: 'prevColor' | 'nextColor',
    currentIndex: number,
): React.RefObject<HTMLInputElement> | undefined {
    let index: number = currentIndex;
    if (wrapped) {
        if (direction === 'nextColor') {
            index = skus.findIndex((s) => !!s.current);
        } else {
            index = findLastIndex(skus, (s) => !!s.current);
        }
    }

    return skus[index];
}

function getCompactmatrixMinHeight(sizeRows: number, selected: boolean): number {
    return 44 + sizeRows * 44 + (selected ? 200 : 0);
}

function getCollectionVariationString(collectionVariation?: CollectionVariation | null) {
    if (!collectionVariation) return '';

    return `${collectionVariation.variationNumber}${collectionVariation.collectionId ?? ''}${
        collectionVariation.split ?? ''
    }`;
}
