import Flex from 'components/ui/Flex';
import Text from 'components/ui/Text';
import { ProductStockSku } from 'microshop-api';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CollectionVariation } from 'store/reducers/productSlice';
import styled from 'styled-components';
import { QuantityChangedFunction } from './ProductBuy';

type ArticleSelectorProps = {
    className?: string;
    variation: CollectionVariation;
    onQuantityChange: QuantityChangedFunction;
    cells: Map<string, number | undefined>;
    addToCartEnabled: boolean;
};
const ArticleSelector: React.FC<ArticleSelectorProps> = ({
    className,
    variation,
    onQuantityChange,
    cells,
    addToCartEnabled,
}) => {
    const { t } = useTranslation();
    const [selectedSku, setSelectedSku] = useState<string>();
    const skus = variation.skus;

    const boxText =
        skus?.length && skus[0]?.price?.inboxItems && skus[0]?.price?.inboxItems > 1
            ? `${skus[0].price.inboxItems} ${t('stock_pcs', 'pcs')} / ${t('stock_box', 'box')}`
            : null;

    const small = !!skus && skus.length <= 1;
    return (
        <>
            <ArticleSelectContainer small={small} className={`my-4 ${className ? className : ''}`}>
                <Flex justify="between" className={className}>
                    <Text className="mb-2 f3-700" light>
                        {addToCartEnabled && t('size')} {boxText && `(${boxText})`}{' '}
                    </Text>
                </Flex>
                <Flex justify="between">
                    <MatrixContainer small={small} className="w-100">
                        {skus?.map((sku, i) => {
                            const qty = sku.sku ? cells.get(sku.sku) ?? 0 : 0;

                            return (
                                <ArticleSelect
                                    selected={sku.sku === selectedSku}
                                    key={`${sku.sku}${i}`}
                                    sku={sku}
                                    onSelect={() => {
                                        if (!sku.sku || !variation.variationNumber) return;

                                        onQuantityChange(sku.sku, 1, variation, sku.price?.inboxItems, true);
                                        setSelectedSku(sku.sku);
                                    }}
                                    singleSize={false}
                                />
                            );
                        })}
                    </MatrixContainer>
                </Flex>
            </ArticleSelectContainer>
        </>
    );
};

type ArticleSelectProps = {
    sku?: ProductStockSku;
    onSelect: () => void;
    singleSize: boolean;
    disable?: boolean;
    selected: boolean;
};
export const ArticleSelect = ({ sku, onSelect, singleSize, selected, disable }: ArticleSelectProps) => {
    const { t } = useTranslation();
    const isBox = sku?.price?.inboxItems && sku.price?.inboxItems > 1;
    const unitText = isBox ? t('stock.box', 'box') : t('stock.pcs', 'pcs');

    const disabled = disable || (sku && sku.stock?.every((e) => e.value === 0));
    const single = singleSize || !sku?.name;

    return (
        <Flex column className="">
            <SizeWrapper align="center" justify="between">
                <OrderSelect
                    disabled={disabled}
                    selected={selected}
                    placeholder={unitText}
                    className="flex-shrink-0 orderselectas"
                    onClick={onSelect}
                />
                {!single && (
                    <>
                        <SizeText disabled={disabled} className="f1-500 text-uppercase">
                            {sku.name}
                        </SizeText>
                    </>
                )}
            </SizeWrapper>
        </Flex>
    );
};

const OrderSelect = styled.button<{ selected: boolean }>`
    height: 100%;
    width: calc(100% - 68px);
    outline: none;
    font-size: 16px;
    flex-grow: 2;
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

    border: ${({ theme }) => `1px solid ${theme.colors.border};`};
    background: ${({ theme }) => theme.colors.transparent};
    border-radius: 1px;

    &:hover,
    &:focus {
        ${({ selected, disabled }) => !selected && !disabled && `border: 1px solid;`}
    }

    ${({ selected, theme }) => selected && `border:3px solid ${theme.colors.accent}`}
`;

const ArticleSelectContainer = styled.div<{ small: boolean }>`
    max-width: ${({ small }) => (small ? '410px' : 'auto')};
`;
const MatrixContainer = styled.div<{ small: boolean }>`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(135px, 1fr));
    gap: 4px;
    max-width: ${({ small }) => (small ? '245px' : 'auto')};
`;

const SizeWrapper = styled(Flex)`
    border-radius: 0px;
    position: relative;
    height: 50px;
`;

type SizeProps = {
    disabled?: boolean;
    small?: boolean;
};
const SizeText = styled(Text)<SizeProps>`
    color: ${({ theme, disabled }) => (disabled ? theme.colors.textDisabled : theme.colors.text)};
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${({ small }: SizeProps) => (small ? 0.875 : 1)}rem;
    white-space: nowrap;
    pointer-events: none;
`;

export default ArticleSelector;
