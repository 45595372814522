import Text from 'components/ui/Text';
import React from 'react';
import styled from 'styled-components/macro';

type Props = {
    page: number;
    pages: number;
    pageSize: number;
    itemCount: number;
    itemsTotal: number;
    label: string;
    setPage: (page: number) => void;
    compact: boolean;
};

const Pagination: React.FC<Props> = ({ page, pages, pageSize, itemCount, itemsTotal, label, setPage, compact }) => {
    const DOTS = -1;

    const range = (start: number, end: number) => {
        let length = end - start + 1;
        return Array.from({ length }, (_, idx) => idx + start);
    };

    const getPaginationRange = () => {
        const siblingCount = 1;
        // Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2*DOTS
        const totalPageNumbers = siblingCount + 5;

        /*
      Case 1:
      If the number of pages is less than the page numbers we want to show in our
      paginationComponent, we return the range [1..totalPageCount]
    */
        if (totalPageNumbers >= pages) {
            return range(1, pages);
        }

        /*
    	Calculate left and right sibling index and make sure they are within range 1 and totalPageCount
    */
        const leftSiblingIndex = Math.max(page - siblingCount, 1);
        const rightSiblingIndex = Math.min(page + siblingCount, pages);

        /*
      We do not show dots just when there is just one page number to be inserted between the extremes of sibling and the page limits i.e 1 and totalPageCount. Hence we are using leftSiblingIndex > 2 and rightSiblingIndex < totalPageCount - 2
    */
        const shouldShowLeftDots = leftSiblingIndex > 2;
        const shouldShowRightDots = rightSiblingIndex < pages - (compact ? 1 : 2);

        const firstPageIndex = 1;
        const lastPageIndex = pages;

        /*
    	Case 2: No left dots to show, but rights dots to be shown
    */
        if (!shouldShowLeftDots && shouldShowRightDots) {
            let leftItemCount = (compact ? 1 : 3) + 2 * siblingCount;
            let leftRange = range(1, leftItemCount);

            return [...leftRange, DOTS, pages];
        }

        /*
    	Case 3: No right dots to show, but left dots to be shown
    */
        if (shouldShowLeftDots && !shouldShowRightDots) {
            let rightItemCount = (compact ? 1 : 3) + 2 * siblingCount;
            let rightRange = range(pages - rightItemCount + 1, pages);
            return [firstPageIndex, DOTS, ...rightRange];
        }

        /*
    	Case 4: Both left and right dots to be shown
    */
        if (shouldShowLeftDots && shouldShowRightDots) {
            let middleRange = range(leftSiblingIndex, rightSiblingIndex);
            if (compact) return [firstPageIndex, DOTS, page, DOTS, lastPageIndex];
            else return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
        }
    };

    const paginationRange = getPaginationRange() ?? [];

    if (page === 0 || paginationRange.length < 2) {
        return null;
    }

    const lastPage = paginationRange[paginationRange.length - 1];

    const fromItem = (page - 1) * pageSize + 1;
    const toItem = (page - 1) * pageSize + itemCount;

    return (
        <>
            <Text small center>{`${fromItem}-${toItem} / ${itemsTotal} ${label}`}</Text>
            <StyledUl>
                {/* Left navigation arrow disabled: */}
                <StyledLi compact={compact} className={page === 1 ? 'disabled' : ''} onClick={() => setPage(page - 1)}>
                    <div className="arrow left" />
                </StyledLi>
                {paginationRange.map((pageNumber, i) => {
                    // If the pageItem is a DOT, render the DOTS unicode character
                    if (pageNumber === DOTS) {
                        return (
                            <StyledLi compact={compact} key={i} className="dot disabled">
                                &#8230;
                            </StyledLi>
                        );
                    }
                    // Render our Page Pills selected:
                    return (
                        <StyledLi
                            compact={compact}
                            key={i}
                            className={page === pageNumber ? 'selected' : ''}
                            onClick={() => setPage(pageNumber)}
                        >
                            {pageNumber}
                        </StyledLi>
                    );
                })}
                {/*  Right Navigation arrow */}
                <StyledLi
                    compact={compact}
                    className={page === lastPage ? 'disabled' : ''}
                    onClick={() => setPage(page + 1)}
                >
                    <div className="arrow right" />
                </StyledLi>
            </StyledUl>
        </>
    );
};

export default Pagination;

const StyledUl = styled.ul`
    display: flex;
    padding-top: 20px;
    padding-left: 0px;
    list-style-type: none;
    justify-content: center;
`;

const StyledLi = styled.li<{ compact?: boolean }>`
  padding: ${({ compact }) => (compact ? '0 5px' : '0 12px')};
  min-width: 25px;
  height: ${({ compact }) => (compact ? '25px' : '32px')};
  text-align: center;
  margin: auto 4px;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  box-sizing: border-box;
  align-items: center;
  letter-spacing: 0.01071em;
  border-radius: 16px;
  line-height: 1.43;
  font-size: 13px;
  justify-content: center;

    &.dots:hover {
      background-color: transparent;
      cursor: default;
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
      cursor: pointer;
    }

    &.selected {
      background-color: rgba(0, 0, 0, 0.08);
    }

    .arrow {
      &::before {
        position: relative;
        /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
        content: '';
        /* By using an em scale, the arrows will size with the font */
        display: inline-block;
        width: 0.4em;
        height: 0.4em;
        border-right: 0.12em solid rgba(0, 0, 0, 0.87);
        border-top: 0.12em solid rgba(0, 0, 0, 0.87);
      }

      &.left {
        transform: rotate(-135deg) translate(-50%);
      }

      &.right {
        transform: rotate(45deg);
      }
    }

    &.disabled {
      pointer-events: none;

      .arrow::before {
        border-right: 0.12em solid rgba(0, 0, 0, 0.43);
        border-top: 0.12em solid rgba(0, 0, 0, 0.43);
      }

      &:hover {
        background-color: transparent;
        cursor: default;
      }
    }
  }
`;
