import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { getLuminance, lighten, shade } from 'polished';
import React from 'react';
import styled, { ThemeColors } from 'styled-components/macro';
import HeaderIcon from './HeaderIcon';

interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    icon?: IconDefinition;
    iconColor?: keyof ThemeColors;
    bgColor?: keyof ThemeColors;
    small?: boolean;
}

interface IButton {
    bgColor?: keyof ThemeColors;
}
const Button = styled.button<IButton>`
    width: 38px;
    height: 38px;
    line-height: 38px;
    border-radius: 19px;
    background-color: ${({ theme, bgColor }) => {
        return bgColor
            ? theme.colors[bgColor]
            : getLuminance(theme.colors.main) > 0.95
            ? shade(0.03, theme.colors.main)
            : lighten(0.08, theme.colors.main);
    }};
    text-align: center;
    font-size: inherit;
    aria-label: inherit;
    padding: 0;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
`;

const HeaderIconButton: React.FC<IButtonProps> = ({ icon, iconColor, bgColor, small, children, ...rest }) => {
    return (
        <Button bgColor={bgColor} {...rest}>
            {icon && <HeaderIcon small={small} icon={icon} iconColor={iconColor ? iconColor : 'mainText'} />}
            {children}
        </Button>
    );
};

export default HeaderIconButton;
