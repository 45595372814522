import { faFile, faFileExcel, faFilePdf, faFilePowerpoint, faFileWord } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Text from 'components/ui/Text';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { Direction } from 'reactstrap/es/Dropdown';
import styled from 'styled-components';
import Flex from './Flex';
const Files = ({ files, direction, className }: { files: any[]; direction?: Direction; className?: string }) => {
    const { t } = useTranslation();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen((prevState) => !prevState);
    const getIcon = (ext: string) => {
        switch (ext) {
            case '.pdf':
                return faFilePdf;
            case '.xlsx':
            case '.xls':
            case '.csv':
                return faFileExcel;
            case '.doc':
            case '.docx':
                return faFileWord;
            case '.ppt':
            case '.pptx':
                return faFilePowerpoint;
            default:
                return faFile;
        }
    };

    if (!files?.length) {
        return null;
    }

    return (
        <StyledDropdown isOpen={dropdownOpen} toggle={toggle} direction={direction} className={className}>
            <StyledDropdownToggle nav caret className="px-3">
                <small>{t('files')}</small>
            </StyledDropdownToggle>
            <DropdownMenu>
                {files!.map((file, index) => (
                    <a key={index} href={file.url} target="_blank" rel="noreferrer">
                        <StyledDropdownItem>
                            <Flex>
                                <FontAwesomeIcon icon={getIcon(file.extension)} className="me-2" />
                                <StyledText>{file.name}</StyledText>
                            </Flex>
                        </StyledDropdownItem>
                    </a>
                ))}
            </DropdownMenu>
        </StyledDropdown>
    );
};

const StyledDropdown = styled(Dropdown)`
    width: fit-content;
`;

const StyledText = styled(Text)`
    white-space: normal;
    word-wrap: break-word;
`;

const StyledDropdownItem = styled(DropdownItem)``;
const StyledDropdownToggle = styled(DropdownToggle)`
    border: 0;
    border-radius: 17px;
    ${({ theme }) => `background-color: ${theme.colors.buy}`};
`;
export default Files;
