"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SkuPriceInputToJSON = exports.SkuPriceInputFromJSONTyped = exports.SkuPriceInputFromJSON = exports.instanceOfSkuPriceInput = void 0;
var runtime_1 = require("../runtime");
/**
 * Check if a given object implements the SkuPriceInput interface.
 */
function instanceOfSkuPriceInput(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfSkuPriceInput = instanceOfSkuPriceInput;
function SkuPriceInputFromJSON(json) {
    return SkuPriceInputFromJSONTyped(json, false);
}
exports.SkuPriceInputFromJSON = SkuPriceInputFromJSON;
function SkuPriceInputFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'sku': !(0, runtime_1.exists)(json, 'sku') ? undefined : json['sku'],
        'inboxItems': !(0, runtime_1.exists)(json, 'inboxItems') ? undefined : json['inboxItems'],
    };
}
exports.SkuPriceInputFromJSONTyped = SkuPriceInputFromJSONTyped;
function SkuPriceInputToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'sku': value.sku,
        'inboxItems': value.inboxItems,
    };
}
exports.SkuPriceInputToJSON = SkuPriceInputToJSON;
