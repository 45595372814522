import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import uniqueId from 'lodash.uniqueid';
import { shade } from 'polished';
import React, { forwardRef, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { ThemeColors, ThemeContext, ThemeWebColors } from 'styled-components/macro';
import Flex from './Flex';

type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
    id?: string;
    name?: string;
    label?: React.ReactNode;
    subLabel?: string;
    circle?: boolean;
    bgColor?: keyof ThemeColors;
    webColor?: keyof ThemeWebColors;
    iconColor?: keyof ThemeColors;
    validate?: boolean;
    required?: boolean;
};

const Checkbox = forwardRef<HTMLInputElement, InputProps>(
    (
        {
            name,
            label,
            circle,
            bgColor,
            webColor,
            iconColor,
            disabled,
            checked,
            defaultChecked,
            validate,
            required,
            className,
            ...restProps
        },
        ref,
    ) => {
        const themeContext = useContext(ThemeContext);
        const [id] = useState(restProps.id ?? uniqueId(`${name}-`));
        const valid = !validate || (required && checked);
        const { t } = useTranslation();
        return (
            <Wrapper align="center" className={className ? className : ''}>
                <HiddenCheckbox
                    bgColor={bgColor}
                    webColor={webColor}
                    type={restProps?.type ?? 'checkbox'}
                    id={id}
                    name={name}
                    ref={ref}
                    checked={checked}
                    defaultChecked={defaultChecked}
                    disabled={disabled}
                    {...restProps}
                />
                <StyledCheckbox
                    className="flex-shrink-0"
                    disabled={disabled}
                    circle={circle}
                    bgColor={bgColor}
                    webColor={webColor}
                    valid={valid}
                >
                    {(checked || defaultChecked) && (
                        <FontAwesomeIcon
                            fontSize={15}
                            icon={faCheck}
                            color={
                                disabled
                                    ? themeContext.colors.textDisabled
                                    : iconColor
                                    ? themeContext.colors[iconColor]
                                    : themeContext.colors.textLight
                            }
                        />
                    )}
                </StyledCheckbox>
                {label && (
                    <InputLabel disabled={disabled} className="ms-3" htmlFor={id}>
                        {label}
                        {restProps.subLabel && <SubLabel>{restProps.subLabel}</SubLabel>}
                        {!valid && <ValidationText>{t('validation.requiredAccept', 'Please accept')}</ValidationText>}
                    </InputLabel>
                )}
            </Wrapper>
        );
    },
);

const Wrapper = styled(Flex)`
    position: relative;
`;

const HiddenCheckbox = styled.input<{
    bgColor?: keyof ThemeColors;
    webColor?: keyof ThemeWebColors;
    disabled?: boolean;
}>`
    position: absolute;
    opacity: 0;
    width: 30px;
    height: 30px;
    cursor: ${({ disabled }) => (disabled ? 'normal' : 'pointer')};
    &:focus-visible:not([disabled]) + div {
        border: 2px solid ${({ theme }) => theme.colors.accent};
    }

    &:hover ~ div {
        background-color: ${({ bgColor, theme, webColor, disabled }) => {
            if (disabled) return theme.colors.disabled;
            if (webColor && theme.webColors[webColor]) return shade(0.03, theme.webColors[webColor]);
            if (bgColor && theme.colors[bgColor]) return shade(0.03, theme.colors[bgColor]);
            return shade(0.03, theme.colors.fillWhite);
        }};
    }
`;

const SubLabel = styled.p`
    font-size: 12px;
    color: ${({ theme }) => theme.colors.textFaded};
    margin-bottom: 0;
`;

const StyledCheckbox = styled.div<{
    bgColor?: keyof ThemeColors;
    webColor?: keyof ThemeWebColors;
    circle?: boolean;
    disabled?: boolean;
    valid?: boolean;
}>`
  display: flex;
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  transition: all 150ms;
  border: ${({ theme, valid, disabled }) =>
      `1px solid ${disabled ? theme.colors.disabled : valid ? theme.colors.borderLight : theme.colors.error}`};
  ${({ circle }) => circle && 'border-radius: 15px'};

  ${({ disabled, theme }) => disabled && `color: ${theme.colors.textDisabled};`}
  background-color: ${({ bgColor, webColor, theme, disabled }) => {
      if (disabled) return theme.colors.disabled;
      if (webColor && theme.webColors[webColor]) return theme.webColors[webColor];
      if (bgColor && theme.colors[bgColor]) return theme.colors[bgColor];
      return theme.colors.fillWhite;
  }};
`;

const InputLabel = styled.label<{ disabled?: boolean }>`
    width: 100%;
    margin-bottom: 0;
    ${({ disabled, theme }) => disabled && `color: ${theme.colors.textDisabled};`}
`;

const ValidationText = styled.span`
    color: ${({ theme }) => theme.colors.error};
    font-size: 11px;
    line-height: 0.8em;
    display: block;
`;

export default Checkbox;
