import useAppSelector from 'hooks/useAppSelector';
import { Helmet } from 'react-helmet';
import { getFontLink } from 'styles/fonts';

interface HtmlHeadProps {
    canonicalUrl?: string;
}

const HTMLHead = ({ canonicalUrl }: HtmlHeadProps) => {
    const { info } = useAppSelector(({ shop }) => shop);

    const fontBodyLink = getFontLink(info?.theme?.fontBody ?? undefined);
    const fontHeadingLink = getFontLink(info?.theme?.fontHeader ?? undefined);
    const neutralFontLink = getFontLink(info?.theme?.fontBody ?? undefined);

    const title = info?.name ?? '';

    return (
        <Helmet key="page-helmet">
            <title>{title}</title>
            <meta name="title" content={title} />
            <meta name="description" content={title} />
            <link rel="preconnect" href="https://fonts.gstatic.com" />
            {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
            {fontBodyLink}
            {fontHeadingLink !== fontBodyLink && fontHeadingLink}
            {neutralFontLink !== fontBodyLink && neutralFontLink !== fontHeadingLink && neutralFontLink}
        </Helmet>
    );
};

export default HTMLHead;
