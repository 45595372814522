import styled, { ThemeColors } from 'styled-components/macro';

interface IBadge {
    readonly color?: keyof ThemeColors;
    readonly bgColor?: keyof ThemeColors;
}

const Badge = styled.span<IBadge>`
    min-width: 17px;
    height: 17px;
    line-height: 17px;
    border-radius: 9px;
    background-color: ${({ theme, bgColor }) => (bgColor ? theme.colors[bgColor] : theme.colors.attention)};
    color: ${({ theme, color }) => (color ? theme.colors[color] : theme.colors.attentionText)};
    text-align: center;
    font-size: 12px;
    position: absolute;
    right: -6px;
    bottom: 0px;
    pointer-events: none;
    padding: 0 4px;
`;
export default Badge;
