import { faShoppingBag } from '@fortawesome/pro-solid-svg-icons';
import useAppSelector from 'hooks/useAppSelector';
import styled from 'styled-components/macro';
import Badge from './Badge';
import HeaderIconButton from './HeaderIconButton';

const CartButton = ({ className }: { className: string }) => {
    const { quantity } = useAppSelector(({ cart }) => cart.cart);
    const { loading } = useAppSelector(({ cart }) => cart);
    // const dispatch = useAppDispatch();

    return (
        // <ButtonWrapper onClick={() => dispatch(cartToggled('shoppingCart')())}>
        <ButtonWrapper>
            <Icon
                small
                icon={faShoppingBag}
                className={`ms-2 ms-lg-3 ${loading ? 'loading' : ''}`}
                aria-label="shoppingBag"
            />
            {(quantity ?? 0) > 0 && <Badge>{quantity}</Badge>}
        </ButtonWrapper>
    );
};
export default CartButton;

const ButtonWrapper = styled.div`
    position: relative;
`;

const Icon = styled(HeaderIconButton)<{ isLoading?: boolean }>`
    &.loading {
        animation: pulse 1s infinite;
    }

    @keyframes pulse {
        0% {
            transform: scale(0.95);
        }
        50% {
            transform: scale(1);
        }
        100% {
            transform: scale(0.95);
        }
    }
`;
