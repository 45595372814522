"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.FilterOutputToJSON = exports.FilterOutputFromJSONTyped = exports.FilterOutputFromJSON = exports.instanceOfFilterOutput = void 0;
var runtime_1 = require("../runtime");
var FilterItem_1 = require("./FilterItem");
var FilterType_1 = require("./FilterType");
/**
 * Check if a given object implements the FilterOutput interface.
 */
function instanceOfFilterOutput(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfFilterOutput = instanceOfFilterOutput;
function FilterOutputFromJSON(json) {
    return FilterOutputFromJSONTyped(json, false);
}
exports.FilterOutputFromJSON = FilterOutputFromJSON;
function FilterOutputFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'fieldName': !(0, runtime_1.exists)(json, 'fieldName') ? undefined : json['fieldName'],
        'type': !(0, runtime_1.exists)(json, 'type') ? undefined : (0, FilterType_1.FilterTypeFromJSON)(json['type']),
        'customLabel': !(0, runtime_1.exists)(json, 'customLabel') ? undefined : json['customLabel'],
        'filter': !(0, runtime_1.exists)(json, 'filter') ? undefined : (json['filter'] === null ? null : json['filter'].map(FilterItem_1.FilterItemFromJSON)),
    };
}
exports.FilterOutputFromJSONTyped = FilterOutputFromJSONTyped;
function FilterOutputToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'fieldName': value.fieldName,
        'type': (0, FilterType_1.FilterTypeToJSON)(value.type),
        'customLabel': value.customLabel,
        'filter': value.filter === undefined ? undefined : (value.filter === null ? null : value.filter.map(FilterItem_1.FilterItemToJSON)),
    };
}
exports.FilterOutputToJSON = FilterOutputToJSON;
