"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.StockLevelToJSON = exports.StockLevelFromJSONTyped = exports.StockLevelFromJSON = exports.StockLevel = void 0;
/**
 *
 * @export
 */
exports.StockLevel = {
    OutOfStock: 0,
    LowStock: 1,
    InStock: 2
};
function StockLevelFromJSON(json) {
    return StockLevelFromJSONTyped(json, false);
}
exports.StockLevelFromJSON = StockLevelFromJSON;
function StockLevelFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.StockLevelFromJSONTyped = StockLevelFromJSONTyped;
function StockLevelToJSON(value) {
    return value;
}
exports.StockLevelToJSON = StockLevelToJSON;
