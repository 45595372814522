"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeliveryToJSON = exports.DeliveryFromJSONTyped = exports.DeliveryFromJSON = exports.instanceOfDelivery = void 0;
var runtime_1 = require("../runtime");
/**
 * Check if a given object implements the Delivery interface.
 */
function instanceOfDelivery(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfDelivery = instanceOfDelivery;
function DeliveryFromJSON(json) {
    return DeliveryFromJSONTyped(json, false);
}
exports.DeliveryFromJSON = DeliveryFromJSON;
function DeliveryFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'company': !(0, runtime_1.exists)(json, 'company') ? undefined : json['company'],
        'companyId': !(0, runtime_1.exists)(json, 'companyId') ? undefined : json['companyId'],
        'orderReference': !(0, runtime_1.exists)(json, 'orderReference') ? undefined : json['orderReference'],
        'reference': !(0, runtime_1.exists)(json, 'reference') ? undefined : json['reference'],
        'packageLabel': !(0, runtime_1.exists)(json, 'packageLabel') ? undefined : json['packageLabel'],
        'firstName': !(0, runtime_1.exists)(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !(0, runtime_1.exists)(json, 'lastName') ? undefined : json['lastName'],
        'email': !(0, runtime_1.exists)(json, 'email') ? undefined : json['email'],
        'addressId': !(0, runtime_1.exists)(json, 'addressId') ? undefined : json['addressId'],
        'address1': !(0, runtime_1.exists)(json, 'address1') ? undefined : json['address1'],
        'address2': !(0, runtime_1.exists)(json, 'address2') ? undefined : json['address2'],
        'postalCode': !(0, runtime_1.exists)(json, 'postalCode') ? undefined : json['postalCode'],
        'city': !(0, runtime_1.exists)(json, 'city') ? undefined : json['city'],
        'country': !(0, runtime_1.exists)(json, 'country') ? undefined : json['country'],
        'phone': !(0, runtime_1.exists)(json, 'phone') ? undefined : json['phone'],
        'comment': !(0, runtime_1.exists)(json, 'comment') ? undefined : json['comment'],
    };
}
exports.DeliveryFromJSONTyped = DeliveryFromJSONTyped;
function DeliveryToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'company': value.company,
        'companyId': value.companyId,
        'orderReference': value.orderReference,
        'reference': value.reference,
        'packageLabel': value.packageLabel,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'email': value.email,
        'addressId': value.addressId,
        'address1': value.address1,
        'address2': value.address2,
        'postalCode': value.postalCode,
        'city': value.city,
        'country': value.country,
        'phone': value.phone,
        'comment': value.comment,
    };
}
exports.DeliveryToJSON = DeliveryToJSON;
