"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CartItemSkuAddOnToJSON = exports.CartItemSkuAddOnFromJSONTyped = exports.CartItemSkuAddOnFromJSON = exports.instanceOfCartItemSkuAddOn = void 0;
var runtime_1 = require("../runtime");
/**
 * Check if a given object implements the CartItemSkuAddOn interface.
 */
function instanceOfCartItemSkuAddOn(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfCartItemSkuAddOn = instanceOfCartItemSkuAddOn;
function CartItemSkuAddOnFromJSON(json) {
    return CartItemSkuAddOnFromJSONTyped(json, false);
}
exports.CartItemSkuAddOnFromJSON = CartItemSkuAddOnFromJSON;
function CartItemSkuAddOnFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !(0, runtime_1.exists)(json, 'id') ? undefined : json['id'],
        'price': !(0, runtime_1.exists)(json, 'price') ? undefined : json['price'],
        'productName': !(0, runtime_1.exists)(json, 'productName') ? undefined : json['productName'],
        'productNumber': !(0, runtime_1.exists)(json, 'productNumber') ? undefined : json['productNumber'],
        'inputs': !(0, runtime_1.exists)(json, 'inputs') ? undefined : json['inputs'],
        'quantity': !(0, runtime_1.exists)(json, 'quantity') ? undefined : json['quantity'],
        'total': !(0, runtime_1.exists)(json, 'total') ? undefined : json['total'],
        'mandatory': !(0, runtime_1.exists)(json, 'mandatory') ? undefined : json['mandatory'],
    };
}
exports.CartItemSkuAddOnFromJSONTyped = CartItemSkuAddOnFromJSONTyped;
function CartItemSkuAddOnToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'price': value.price,
        'productName': value.productName,
        'productNumber': value.productNumber,
        'inputs': value.inputs,
        'quantity': value.quantity,
        'total': value.total,
        'mandatory': value.mandatory,
    };
}
exports.CartItemSkuAddOnToJSON = CartItemSkuAddOnToJSON;
