"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShopSecurityTypeToJSON = exports.ShopSecurityTypeFromJSONTyped = exports.ShopSecurityTypeFromJSON = exports.ShopSecurityType = void 0;
/**
 *
 * @export
 */
exports.ShopSecurityType = {
    None: 0,
    Secret: 1,
    Register: 2,
    SecretAndRegister: 3
};
function ShopSecurityTypeFromJSON(json) {
    return ShopSecurityTypeFromJSONTyped(json, false);
}
exports.ShopSecurityTypeFromJSON = ShopSecurityTypeFromJSON;
function ShopSecurityTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.ShopSecurityTypeFromJSONTyped = ShopSecurityTypeFromJSONTyped;
function ShopSecurityTypeToJSON(value) {
    return value;
}
exports.ShopSecurityTypeToJSON = ShopSecurityTypeToJSON;
