"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.IdentityToJSON = exports.IdentityFromJSONTyped = exports.IdentityFromJSON = exports.instanceOfIdentity = void 0;
var runtime_1 = require("../runtime");
var Options_1 = require("./Options");
/**
 * Check if a given object implements the Identity interface.
 */
function instanceOfIdentity(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfIdentity = instanceOfIdentity;
function IdentityFromJSON(json) {
    return IdentityFromJSONTyped(json, false);
}
exports.IdentityFromJSON = IdentityFromJSON;
function IdentityFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'orderOptions': !(0, runtime_1.exists)(json, 'orderOptions') ? undefined : (0, Options_1.OptionsFromJSON)(json['orderOptions']),
    };
}
exports.IdentityFromJSONTyped = IdentityFromJSONTyped;
function IdentityToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'orderOptions': (0, Options_1.OptionsToJSON)(value.orderOptions),
    };
}
exports.IdentityToJSON = IdentityToJSON;
