import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { ButtonProps } from './Button';

function isLocation(to: Location | string | undefined): to is Location {
    return !!(to as Location)?.pathname;
}

type Location = {
    pathname?: string;
    search?: string;
    hash?: string;
};

type LocationState = {
    pnr?: string;
    noscroll?: boolean;
    featuredColor?: string;
    brand?: string;
    category?: string;
};

export type LinkData = React.HTMLAttributes<HTMLAnchorElement> &
    ButtonProps & {
        className?: string;
        to?: string | Location;
        children: React.ReactNode;
        target?: string;
        noStyle?: boolean;
        state?: LocationState;
        rel?: string;
    };
const LinkWrapper: React.FC<LinkData> = ({
    className,
    to,
    children,
    target,
    noStyle,
    pill,
    attention,
    bgColor,
    color,
    state,
    rel,
    ...props
}) => {
    const url = isLocation(to) ? (to as Location).pathname : to;

    if (!to) {
        return <Fragment>{children}</Fragment>;
    }
    if (url?.match(/^(http(s)?|ftp):\/\//))
        return (
            <a href={url} className={`Link ${className}`} {...props} target={target || '_self'}>
                {children}
            </a>
        );

    return (
        <Link
            to={to}
            rel={rel || ''}
            state={state}
            className={`${className ? className : ''}`}
            {...props}
            target={target || '_self'}
        >
            {children}
        </Link>
    );
};

type LinkProps = {
    noStyle?: boolean;
};
const StyledLink = styled(LinkWrapper)<LinkProps>`
    color: ${({ theme, noStyle }) => (noStyle ? 'inherit' : theme.colors.text)};
    text-decoration: ${({ noStyle }) => (noStyle ? 'none' : 'underline')};
    :hover,
    :focus,
    :visited {
        color: ${({ theme, noStyle }) => (noStyle ? 'inherit' : theme.colors.text)};
        ${({ noStyle }) => noStyle && 'text-decoration: none;'}
    }
`;
export default StyledLink;
