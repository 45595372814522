import { faAngleLeft, faBars } from '@fortawesome/pro-solid-svg-icons';
import LoginMenu from 'components/ApiAuthorization/LoginMenu';
import useAppSelector from 'hooks/useAppSelector';
import useWindowSize from 'hooks/useWindowSize';
import { CatalogNodeModel } from 'microshop-api';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import * as themeHelper from 'theme/functions';
import CartButton from './CartButton';
import FilesNavigation from './FilesNavigation';
import Flex from './Flex';
import HeaderIconButton from './HeaderIconButton';
import SlideIn from './SlideIn';

const MainNavigation = ({ loginLabel, logoutLabel }: { loginLabel: string; logoutLabel: string }) => {
    const [showCollapsedMenu, setShowCollapsedMenu] = useState<boolean>(false);
    const { info, files, theme } = useAppSelector(({ shop }) => ({ ...shop, files: shop?.shop?.files ?? [] }));

    const { categoriesRaw } = useAppSelector(({ assortment }) => assortment);
    const windowSize = useWindowSize();

    return (
        <StyledNavigationWrapper column>
            <StyledFilesNavigation className="w-100 px-3 mx-auto">
                <FilesNavigation files={files} direction="left" className="ms-auto py-1"></FilesNavigation>
            </StyledFilesNavigation>
            <StyledMainNavigation $color={`${theme.main}`} $light={themeHelper.isLight(theme)}>
                <StyledNavigation className="w-100 px-3 align-items-center">
                    <Flex className="me-4">
                        {info?.logoType ? (
                            <Link to="/">
                                <StyledLogo
                                    className="m-0"
                                    alt={info?.name ?? ''}
                                    src={info?.logoType ?? ''}
                                    height="45"
                                />
                            </Link>
                        ) : (
                            <NavigationItem>
                                <NavigationLink to="/">{info?.name}</NavigationLink>
                            </NavigationItem>
                        )}
                    </Flex>
                    <Flex className="flex-grow-1 align-items-center">
                        {windowSize?.width > 1025 ? (
                            <>
                                {categoriesRaw.map((item, i) => (
                                    <MenuGroup key={`${item.name}-${i}`} menuLink={item} />
                                ))}
                            </>
                        ) : (
                            <CollapsedMenu
                                logo={info?.logoType ?? ''}
                                show={showCollapsedMenu}
                                toggleMenu={() => setShowCollapsedMenu((prev) => !prev)}
                            />
                        )}
                    </Flex>
                    <Flex className="align-items-center">
                        <Link to="/cart">
                            <CartButton className="nav-link" />
                        </Link>
                        {(info?.securityType ?? 0) > 1 && (
                            <StyledLoginMenu
                                loginLabel={loginLabel}
                                logoutLabel={logoutLabel}
                                className="nav-link me-3"
                            />
                        )}

                        {windowSize?.width < 1025 && (
                            <HeaderIconButton
                                onClick={() => setShowCollapsedMenu((prev) => !prev)}
                                icon={faBars}
                                className="ms-2"
                                aria-label="main menu"
                                small
                            />
                        )}
                    </Flex>
                </StyledNavigation>
            </StyledMainNavigation>
        </StyledNavigationWrapper>
    );
};

type MenuGroupProps = {
    menuLink: CatalogNodeModel;
};

const MenuGroup: React.FC<MenuGroupProps> = ({ menuLink }) => {
    const [, setShowSubMenu] = useState(false);

    if (menuLink && !menuLink.children?.length) {
        return (
            <NavigationItem className="me-4">
                <NavigationLink className="nav-link" to={menuLink?.assortmentSlug ?? ''}>
                    {menuLink?.name}
                </NavigationLink>
            </NavigationItem>
        );
    }

    return (
        <SubMenuHandle
            onFocus={() => setShowSubMenu(true)}
            onBlur={() => setShowSubMenu(false)}
            onMouseEnter={() => setShowSubMenu(true)}
            onMouseLeave={() => setShowSubMenu(false)}
        >
            <NavigationItem className="me-4">
                <NavigationLink className="nav-link" to={menuLink?.assortmentSlug ?? ''}>
                    {menuLink.name}
                </NavigationLink>
            </NavigationItem>
            {/* <SubMenu show={showSubMenu} parentLink={menuLink} onClose={() => setShowSubMenu(false)} /> */}
        </SubMenuHandle>
    );
};

const CollapsedMenu = ({ logo, show, toggleMenu }: { logo?: string; show?: boolean; toggleMenu: () => void }) => {
    // const { t } = useTranslation();
    const { categoriesRaw } = useAppSelector(({ assortment }) => assortment);

    return (
        <SlideIn onClose={toggleMenu} visible={show}>
            {{
                headerLogo: false ? (
                    <Flex className="w-100" align="center">
                        <HeaderIconButton
                            bgColor="transparent"
                            icon={faAngleLeft}
                            aria-label="angleLeft"
                            onClick={() => {}}
                        />
                        <Header className="w-100 text-center">test</Header>
                    </Flex>
                ) : (
                    <Flex className="w-100" align="center">
                        <Link onClick={toggleMenu} to={'/'}>
                            {logo && (
                                <CollapsedLogo>
                                    <StyledLogo src={logo} height="45" />
                                </CollapsedLogo>
                            )}
                        </Link>
                    </Flex>
                ),
                content: (
                    <>
                        <CollapsedInner column className="p-3">
                            <CollapsedMenuList>
                                {/* {activeMenu?.featuredLinks && (
                                    <>
                                        <Featured links={activeMenu.featuredLinks} onClose={toggleMenu} />
                                        <hr className="my-4"></hr>
                                    </>
                                )} */}
                                {categoriesRaw.map((category, i) => (
                                    <SubMenuItemList key={`${category.name}-${i}`}>
                                        <Link className="link-dark" to={category.assortmentSlug!}>
                                            <CollapsedLink
                                                justify="between"
                                                align="center"
                                                className="f1-400 pointer"
                                                onClick={toggleMenu}
                                            >
                                                {category.name}
                                            </CollapsedLink>
                                        </Link>
                                        {/* {activeMenu?.url && (
                                            <CollapsedShowAllLink
                                                className="my-3 f1-500 d-inline-block"
                                                to={activeMenu.url}
                                                onClick={toggleMenu}
                                            >
                                                {t('navigation.allIn', 'Show all in')} {activeMenu.name.toLowerCase()}
                                            </CollapsedShowAllLink>
                                        )} */}
                                        {/* {currentMenu.map((item: MenuLink, i: number) => {
                                            if (item.children?.length)
                                                return (
                                                    <CollapsedLink
                                                        justify="between"
                                                        align="center"
                                                        className={`f1-400 pointer`}
                                                        key={`${item.name}-${i}`}
                                                        onClick={() => {
                                                            dispatch(activeMenuChanged(item));
                                                        }}
                                                    >
                                                        {item.name}
                                                        <IconButton icon={faArrowRight}></IconButton>
                                                    </CollapsedLink>
                                                );
                                            return (
                                                <Link key={`${item.name}-${i}`} to={item.url}>
                                                    <CollapsedLink
                                                        justify="between"
                                                        align="center"
                                                        className="f1-400 pointer"
                                                        onClick={toggleMenu}
                                                    >
                                                        {item.name}
                                                    </CollapsedLink>
                                                </Link>
                                            );
                                        })} */}
                                    </SubMenuItemList>
                                ))}
                            </CollapsedMenuList>
                        </CollapsedInner>
                        {/* {activeMenu?.featuredImageLinks && activeMenu.featuredImageLinks.length > 0 && (
                            <CollapsedPromotedArea className="mt-5 py-3">
                                <PromotionHeader className="text-center mb-3 f1-500">
                                    {activeMenu?.featuredHeading || t('navigation.promoted', 'Selected')}
                                </PromotionHeader>
                                <PromotedLinks
                                    links={activeMenu?.featuredImageLinks}
                                    onClose={handleClose}
                                ></PromotedLinks>
                            </CollapsedPromotedArea>
                        )} */}
                    </>
                ),
                footerContent: (
                    <CollapsedFooter>
                        {/* <CollapsedTopNavLinks justify="center" className="pt-4 px-4 pb-1">
                            {topMenu.length >= 1 &&
                                topMenu.map((l, i) => {
                                    return (
                                        <Link
                                            onClick={handleClose}
                                            className="me-3 mb-3 d-inline-block"
                                            to={l.url}
                                            key={i}
                                        >
                                            {l.name}
                                        </Link>
                                    );
                                })}
                        </CollapsedTopNavLinks> */}
                    </CollapsedFooter>
                ),
            }}
        </SlideIn>
    );
};

// type SubMenuProps = {
//     show: boolean;
//     parentLink: MenuLink;
//     onClose: () => void;
// };
// const SubMenu: React.FC<SubMenuProps> = ({ show, parentLink, onClose }) => {
//     const { t } = useTranslation();
//     const [activeLink, setActiveLink] = useState<MenuLink>();

//     const featured = activeLink?.featuredLinks?.length ? activeLink.featuredLinks : parentLink.featuredLinks;
//     const featuredImage = activeLink?.featuredImageLinks?.length
//         ? activeLink.featuredImageLinks
//         : parentLink.featuredImageLinks;
//     const heading = activeLink?.featuredHeading ? activeLink.featuredHeading : parentLink.featuredHeading;

//     let enableSubMenuTopItemOnMouseEnter = true;
//     const debouncedHandleMouseEnter = useDebounce((item?: MenuLink) => {
//         if (enableSubMenuTopItemOnMouseEnter) setActiveLink(item);
//     }, 250);

//     return (
//         <StyledSubMenuWrapper {...{ show }}>
//             <NavigationDropdown fillColor="fillWhite">
//                 {{
//                     content: (
//                         <Container className="pt-4 pb-5 px-0">
//                             <Row>
//                                 <Col sm={12} lg={3}>
//                                     {parentLink.url && (
//                                         <NavigationDropdownLink
//                                             noStyle
//                                             className="mb-3 f1-500 d-inline-block"
//                                             to={parentLink.url}
//                                             onClick={onClose}
//                                         >
//                                             {t('navigation.allIn', 'Show all in')} {parentLink.name.toLowerCase()}
//                                             <FontAwesomeIcon className="ms-1" icon={faAngleRight} />
//                                         </NavigationDropdownLink>
//                                     )}
//                                     <SubMenuItemList
//                                         onMouseEnter={() => (enableSubMenuTopItemOnMouseEnter = true)}
//                                         onMouseLeave={() => (enableSubMenuTopItemOnMouseEnter = false)}
//                                     >
//                                         {parentLink.children?.map((item: MenuLink, i: number) => {
//                                             if (item.children?.length)
//                                                 return (
//                                                     <SubMenuTopItem
//                                                         className={`mb-2 f1-500`}
//                                                         active={activeLink === item}
//                                                         key={`${item.name}-${i}`}
//                                                         onMouseEnter={() => debouncedHandleMouseEnter(item)}
//                                                         onClick={() => setActiveLink(item)}
//                                                     >
//                                                         {item.name}
//                                                     </SubMenuTopItem>
//                                                 );
//                                             return (
//                                                 <NavigationDropdownTopLink
//                                                     className={`mb-2 f1-500`}
//                                                     key={`${item.name}-${i}`}
//                                                     onMouseEnter={() => debouncedHandleMouseEnter(undefined)}
//                                                     to={item.url}
//                                                     onClick={onClose}
//                                                     noStyle
//                                                 >
//                                                     {item.name}
//                                                 </NavigationDropdownTopLink>
//                                             );
//                                         })}
//                                     </SubMenuItemList>
//                                 </Col>
//                                 <Col sm={12} lg={9} className="pr-0">
//                                     {parentLink.children.map((parentItem: MenuLink, pI: number) => (
//                                         <StyledItemWrapper
//                                             key={`${parentItem.name}${pI}`}
//                                             show={activeLink?.name === parentItem.name}
//                                         >
//                                             {parentItem?.children?.length && (
//                                                 <InnerItemWrapper>
//                                                     <NavigationDropdownLink
//                                                         noStyle
//                                                         className="mb-3 f1-500 d-inline-block"
//                                                         to={parentItem.url}
//                                                         onClick={onClose}
//                                                     >
//                                                         {t('navigation.allIn', 'Show all in')}{' '}
//                                                         {parentItem.name.toLowerCase()}
//                                                         <FontAwesomeIcon className="ms-1" icon={faAngleRight} />
//                                                     </NavigationDropdownLink>
//                                                     <InnerItemList
//                                                         expanded={
//                                                             !parentLink?.featuredLinks?.length &&
//                                                             !parentLink?.featuredImageLinks?.length
//                                                         }
//                                                     >
//                                                         {parentItem?.children?.map((item: MenuLink, i) => {
//                                                             if (!item.url) return null;
//                                                             return (
//                                                                 <InnerItemSection
//                                                                     key={`${item.name}${i}`}
//                                                                     className="mb-1 me-3"
//                                                                 >
//                                                                     <NavigationDropdownLinkWithChildren
//                                                                         noStyle
//                                                                         className="f1-500"
//                                                                         to={item.url}
//                                                                         onClick={onClose}
//                                                                     >
//                                                                         {item.name}
//                                                                     </NavigationDropdownLinkWithChildren>
//                                                                     {item.children?.map((ic, j) => {
//                                                                         return (
//                                                                             j < 10 && (
//                                                                                 <NavigationDropdownLink
//                                                                                     noStyle
//                                                                                     key={`${ic.name}${j}`}
//                                                                                     to={ic.url}
//                                                                                     onClick={onClose}
//                                                                                 >
//                                                                                     {ic.name}
//                                                                                 </NavigationDropdownLink>
//                                                                             )
//                                                                         );
//                                                                     })}
//                                                                     {item.children && item.children.length > 10 && (
//                                                                         <NavigationDropdownLink
//                                                                             noStyle
//                                                                             className="f1-700"
//                                                                             to={item.url}
//                                                                             onClick={onClose}
//                                                                         >
//                                                                             ...
//                                                                         </NavigationDropdownLink>
//                                                                     )}
//                                                                 </InnerItemSection>
//                                                             );
//                                                         })}
//                                                     </InnerItemList>
//                                                 </InnerItemWrapper>
//                                             )}
//                                         </StyledItemWrapper>
//                                     ))}
//                                 </Col>
//                             </Row>
//                         </Container>
//                     ),
//                     promo: (featured?.length || featuredImage?.length) && (
//                         <>
//                             {featured?.length && (
//                                 <div className="mb-4">
//                                     <Featured links={featured} onClose={onClose} />
//                                 </div>
//                             )}
//                             {featuredImage?.length && (
//                                 <>
//                                     <PromotionHeader className="ms-1 mb-3 f1-500">
//                                         {heading || t('navigation.promoted', 'Selected')}
//                                     </PromotionHeader>
//                                     <PromotedLinks
//                                         className="pr-4"
//                                         links={parentLink.featuredImageLinks}
//                                         onClose={onClose}
//                                     ></PromotedLinks>
//                                 </>
//                             )}
//                         </>
//                     ),
//                 }}
//             </NavigationDropdown>
//         </StyledSubMenuWrapper>
//     );
// };

// type FeaturedProps = {
//     links: FeaturedLinkProperty[];
//     onClose: () => void;
// };
// const Featured: React.FC<FeaturedProps> = ({ links, onClose }) => {
//     const { t } = useTranslation();

//     return (
//         <FeatureLinks className="m-1 mb-2 f1-400">
//             {links && links.length >= 1 && (
//                 <>
//                     {links.map((l, i) => {
//                         return (
//                             <FeatureLink key={`${l.pageName}${i}`} justify="between" align="center">
//                                 <Link className="d-block" to={l.pageUrl} onClick={onClose}>
//                                     {l.pageName}
//                                 </Link>
//                                 {l.tags?.some((t) => t === 'new') && (
//                                     <FeaturedTag align="center" className="ms-3">
//                                         {t('news.new_one', 'New')}
//                                     </FeaturedTag>
//                                 )}
//                             </FeatureLink>
//                         );
//                     })}
//                 </>
//             )}
//         </FeatureLinks>
//     );
// };

// const PromotionHeader = styled.h4``;

const Header = styled.h2`
    font-size: 22px;
    margin-bottom: 0;
`;

type CollapsedInnerProps = {
    promo?: boolean;
};
const CollapsedInner = styled(Flex)<CollapsedInnerProps>`
    background: ${({ theme }) => theme.colors.fillWhite};
`;

const CollapsedMenuList = styled.div`
    max-width: 100%;
    font-size: 18px;
`;

// const CollapsedTopNavLinks = styled(Flex)`
//     font-size: 12px;
//     line-height: 1rem;
//     flex-wrap: wrap;
//     color: ${({ theme }) => theme.colors.textLight};
// `;

const CollapsedLogo = styled(Flex)`
    width: 100px;
    height: 48px;
`;

// const FeatureLinks = styled.div`
//     font-size: 21px;
//     line-height: 2.5rem;
// `;

// const FeatureLink = styled(Flex)`
//     border-bottom: 2px solid transparent;
//     :hover {
//         border-bottom: 2px solid ${({ theme }) => theme.colors.text};
//     }
//     margin-right: 2rem;
// `;

// const FeaturedTag = styled(Flex)`
//     font-size: 12px;
//     line-height: 1em;
//     height: 2em;
//     padding: 0 4px;
//     background: ${({ theme }) => theme.colors.attention};
//     color: ${({ theme }) => theme.colors.attentionText};
//     border-radius: 2px;
// `;

const CollapsedFooter = styled.div`
    background: ${({ theme }) => theme.colors.fill};
    border-top: 1px solid ${({ theme }) => theme.colors.fillWhite};
`;

const CollapsedLink = styled(Flex)`
    line-height: 45px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.border};
`;

// const CollapsedShowAllLink = styled(Link)``;
// const CollapsedPromotedArea = styled.div`
//     background: ${({ theme }) => theme.colors.fillMedium};
// `;
// const Navigation = styled(Flex)`
//     margin-left: 2rem;
// `;

const NavigationItem = styled.div`
    font-size: 18px;
    color: ${({ theme }) => theme.colors.mainText};
    cursor: default;
    padding-top: 2px;
    border-bottom: 2px solid transparent;
    &:hover {
        border-bottom-color: ${({ theme }) => theme.colors.mainText};
    }
`;

const NavigationLink = styled(Link)`
    font-size: 18px;
    color: ${({ theme }) => theme.colors.mainText};
    :hover,
    :focus,
    :visited {
        color: ${({ theme }) => theme.colors.mainText};
        text-decoration: none;
    }
`;

// const NavigationDropdownLinkWithChildren = styled(NavigationDropdownLink)`
//     font-size: 13px;
// `;

// const StyledItemWrapper = styled.div<{ show: boolean }>`
//     display: ${({ show }) => (show ? 'block' : 'none')};
//     opacity: ${({ show }) => (show ? 1 : 0)};
//     transition: all 0.3s ease;
// `;

// const StyledSubMenuWrapper = styled.div<{ show: boolean }>`
//     pointer-events: ${({ show }) => (show ? 'auto' : 'none')};
//     opacity: ${({ show }) => (show ? 1 : 0)};
//     transition: all 0.3s ease;
// `;

const SubMenuHandle = styled.div`
    /* Hover over menu to show submenu. Extra padding so we don't lose focus when moving cursor from menu item to submenu */
    margin-bottom: -25px;
    padding-bottom: 25px;
    :hover > * {
        border-bottom-color: white;
    }
`;

const SubMenuItemList = styled.div`
    display: flex;
    flex-flow: column wrap;
`;

// type SubMenuTopItemProps = {
//     active: boolean;
// };
// const SubMenuTopItem = styled.span<SubMenuTopItemProps>`
//     border-bottom: 2px solid transparent;
//     font-size: 16px;
//     line-height: 26px;
//     ${({ active, theme }) => (active ? `border-bottom: 2px solid ${theme.colors.textLight}` : '')};
//     :hover {
//         border-bottom: 2px solid ${({ theme }) => theme.colors.text};
//     }
// `;

// const InnerItemWrapper = styled.div`
//     padding-top: 3rem;
//     font-size: 12px;
//     max-width: 800px;
// `;

// type InnerItemsProps = {
//     expanded?: boolean;
// };
// const InnerItemList = styled.div<InnerItemsProps>`
//     columns: 1 auto;
//     ${({ theme }) => theme.media.lg} {
//         columns: 3 auto;
//     }
// `;

// const InnerItemSection = styled.div`
//     break-inside: avoid;
// `;

const StyledNavigationWrapper = styled(Flex)`
    background-color: rgb(244, 244, 244);
    z-index: 999;
    position: sticky;
    top: 0;
    flex-wrap: nowrap;
    justify-content: flex-start;
`;

const StyledMainNavigation = styled(Flex)<{ $color: string; $light: boolean }>`
    ${({ $color }) => $color && `background-color: ${$color};`}
    ${({ $light }) => ($light ? `color: #000;` : `color: #fff;`)}
    height: 71px;
    width: 100%;
`;

const StyledLogo = styled.img`
    letter-spacing: 1.5px;
    margin-right: 1rem;
    text-transform: uppercase;
`;

const StyledNavigation = styled(Flex)`
    max-width: ${({ theme }) => theme.breakpoints.xl}px;
    margin: 0px auto;
`;

const StyledFilesNavigation = styled(Flex)`
    max-width: ${({ theme }) => theme.breakpoints.xl}px;
`;
const StyledLoginMenu = styled(LoginMenu)`
    letter-spacing: 1.5px;
    text-transform: uppercase;
`;

export default MainNavigation;
