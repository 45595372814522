import { shade } from 'polished';
import styled, { ThemeColors } from 'styled-components/macro';

export interface ButtonProps {
    main?: boolean;
    accent?: boolean;
    attention?: boolean;
    height?: number;
    bgColor?: keyof ThemeColors;
    color?: keyof ThemeColors;
    pill?: boolean;
    uppercase?: boolean;
    fullwidth?: boolean;
    fontSize?: string;
    disabled?: boolean;
}

const Button = styled.button<ButtonProps>`
    border: 0;
    font-size: ${({ fontSize }) => (fontSize ? fontSize : '14px')};
    text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : 'inherit')};
    width: ${({ fullwidth: isFullWidth }) => (isFullWidth ? '100%' : 'auto')};
    padding: 0 15px;
    white-space: nowrap;
    transition: opacity 0.3s ease, background-color 0.3s ease;
    height: ${({ height }) => (height ? height + 'px' : '35px')};
    line-height: ${({ height }) => (height ? height + 'px' : '35px')};

    color: ${({ theme, main, accent, attention, color, disabled }) => {
        if (disabled) return theme.colors.textDisabled;
        if (color) return theme.colors[color];
        if (attention) return theme.colors.attentionText;
        if (accent) return theme.colors.accentText;
        if (main) return theme.colors.mainText;
        return theme.colors.text;
    }} !important;

    background-color: ${(props) => getBgColor(props, props.theme.colors)};

    border-radius: ${({ pill, height }) => (pill ? `${height ? height / 2 : '17.5'}px;` : '2px')};

    > a {
        color: #fff;
    }

    &:disabled {
        color: ${({ theme }) => theme.colors.textDisabled} !important;
    }

    ${(props) =>
        !props.disabled
            ? `
    :hover {
      background-color: ${shade(0.03, getBgColor(props, props.theme.colors))};
    }
  `
            : ''}
`;

function getBgColor(props: ButtonProps, themeColors: ThemeColors) {
    const { bgColor, attention, accent, main, disabled } = props;
    if (disabled) return themeColors.disabled;
    if (bgColor) return themeColors[bgColor];
    if (attention) return themeColors.attention;
    if (accent) return themeColors.accent;
    if (main) return themeColors.main;
    return themeColors.fill;
}

export default Button;
