import Flex from 'components/ui/Flex';
import Text from 'components/ui/Text';
import { Valid } from 'store/reducers/cartSlice';
import ValidIcon from './ValidIcon';

const Validate: React.FC<{ valid?: Valid; errorText?: string; validText?: string | null; className?: string }> = ({
    valid,
    errorText,
    validText,
    ...props
}) => (
    <Flex align="center" {...props}>
        {valid === Valid.Invalid && errorText && (
            <Text className="me-2" small color="error">
                {errorText}
            </Text>
        )}
        {valid === Valid.Valid && validText && <Text className="f3-700 me-2">{validText}</Text>}
        <ValidIcon valid={valid} />
    </Flex>
);

export default Validate;
