"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.StandardCheckoutToJSON = exports.StandardCheckoutFromJSONTyped = exports.StandardCheckoutFromJSON = exports.instanceOfStandardCheckout = void 0;
var runtime_1 = require("../runtime");
/**
 * Check if a given object implements the StandardCheckout interface.
 */
function instanceOfStandardCheckout(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfStandardCheckout = instanceOfStandardCheckout;
function StandardCheckoutFromJSON(json) {
    return StandardCheckoutFromJSONTyped(json, false);
}
exports.StandardCheckoutFromJSON = StandardCheckoutFromJSON;
function StandardCheckoutFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'firstName': !(0, runtime_1.exists)(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !(0, runtime_1.exists)(json, 'lastName') ? undefined : json['lastName'],
        'email': !(0, runtime_1.exists)(json, 'email') ? undefined : json['email'],
        'address1': !(0, runtime_1.exists)(json, 'address1') ? undefined : json['address1'],
        'address2': !(0, runtime_1.exists)(json, 'address2') ? undefined : json['address2'],
        'postalCode': !(0, runtime_1.exists)(json, 'postalCode') ? undefined : json['postalCode'],
        'city': !(0, runtime_1.exists)(json, 'city') ? undefined : json['city'],
        'country': !(0, runtime_1.exists)(json, 'country') ? undefined : json['country'],
        'phone': !(0, runtime_1.exists)(json, 'phone') ? undefined : json['phone'],
        'comment': !(0, runtime_1.exists)(json, 'comment') ? undefined : json['comment'],
        'emailLocked': !(0, runtime_1.exists)(json, 'emailLocked') ? undefined : json['emailLocked'],
        'marketingPermission': !(0, runtime_1.exists)(json, 'marketingPermission') ? undefined : json['marketingPermission'],
    };
}
exports.StandardCheckoutFromJSONTyped = StandardCheckoutFromJSONTyped;
function StandardCheckoutToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'firstName': value.firstName,
        'lastName': value.lastName,
        'email': value.email,
        'address1': value.address1,
        'address2': value.address2,
        'postalCode': value.postalCode,
        'city': value.city,
        'country': value.country,
        'phone': value.phone,
        'comment': value.comment,
        'emailLocked': value.emailLocked,
        'marketingPermission': value.marketingPermission,
    };
}
exports.StandardCheckoutToJSON = StandardCheckoutToJSON;
