import { Route, Routes } from 'react-router-dom';
import { ApplicationPaths, LoginActions, LogoutActions } from './ApiAuthorizationConstants';
import { Login } from './Login';
import { Logout } from './Logout';

function ApiAuthorizationRoutes() {
    return (
        <Routes>
            <Route path={ApplicationPaths.ApiAuthorizationPrefix}>
                <Route path={ApplicationPaths.Login} element={loginAction(LoginActions.Login)} />
                <Route path={ApplicationPaths.LoginFailed} element={loginAction(LoginActions.LoginFailed)} />
                <Route path={ApplicationPaths.LoginCallback} element={loginAction(LoginActions.LoginCallback)} />
                <Route path={ApplicationPaths.Profile} element={loginAction(LoginActions.Profile)} />
                <Route path={ApplicationPaths.Register} element={loginAction(LoginActions.Register)} />
                <Route path={ApplicationPaths.LogOut} element={logoutAction(LogoutActions.Logout)} />
                <Route path={ApplicationPaths.LogOutCallback} element={logoutAction(LogoutActions.LogoutCallback)} />
                <Route path={ApplicationPaths.LoggedOut} element={logoutAction(LogoutActions.LoggedOut)} />
            </Route>
        </Routes>
    );
}

function loginAction(name: string) {
    return <Login action={name}></Login>;
}

function logoutAction(name: string) {
    return <Logout action={name}></Logout>;
}

export default ApiAuthorizationRoutes;
