import Badge from 'components/ui/Badge';
import Flex from 'components/ui/Flex';
import Tags from 'components/ui/Tag/Tags';
import Text from 'components/ui/Text';
import VariationDot from 'components/ui/VariationDot';
import useAppSelector from 'hooks/useAppSelector';
import { CustomTag, ImageExtension, ShopCompleteVariation } from 'microshop-api';
import { isSameCollectionVariation } from 'store/reducers/cartSlice';
import { CollectionVariation } from 'store/reducers/productSlice';
import styled, { ThemeWebColors } from 'styled-components';
import getImage, { ImageType } from 'utils/getImage';

type VariationInputProps = {
    variation: CollectionVariation;
    selected: boolean;
    onSelect: (v: CollectionVariation) => void;
    onFocus?: () => void;
    className?: string;
    compact?: boolean;
    displayInCartCount?: boolean;
    disabled?: boolean;
    image?: boolean;
};
const VariationInput: React.FC<VariationInputProps> = ({
    variation,
    selected,
    onSelect,
    className,
    onFocus,
    compact,
    displayInCartCount = true,
    disabled = false,
    image = false,
}) => {
    const localCart = useAppSelector(({ cart }) => cart.localCart);
    const cartVariation = variation ? localCart.find((v) => isSameCollectionVariation(v, variation)) : null;
    const quantity = cartVariation?.variationQty || 0;
    const tags: CustomTag[] = [];

    return (
        <Color
            onFocus={() => onFocus && onFocus()}
            onClick={() => onSelect(variation)}
            itemProp="color"
            className={`${className} ${!disabled && 'pointer'}`}
            compact={compact}
            disabled={disabled}
        >
            {image ? (
                <Flex column={compact}>
                    <Flex column className={`${compact ? '' : 'ml-1'}`}>
                        <ImageContainer>
                            <VariationImage
                                selected={selected}
                                className={`${selected ? 'f1-500' : 'f1-400'} text-capitalize`}
                                src={getImage(variation.image, ImageType.ThumbNail, ImageExtension.Jpg)}
                                alt={variation.color! || variation.variationNumber!}
                            ></VariationImage>
                        </ImageContainer>
                        {variation.split && (
                            <Text small lineheight={14} center className="mt-1">
                                {variation.split}
                            </Text>
                        )}
                        <Text
                            fontSize={!!variation.split ? 11 : 12}
                            lineheight={!!variation.split ? 12 : 14}
                            light={!!variation.split}
                            center
                            textOverflow="100"
                            className={'mt-1'}
                        >
                            {variation.color} ({variation.colorCode})
                        </Text>
                    </Flex>
                </Flex>
            ) : (
                <Flex column={compact} align={compact ? 'center' : 'start'}>
                    <VariationDot
                        className="f3-400 "
                        large
                        hoverable={!disabled}
                        selected={selected}
                        colors={variation.webColors?.flatMap(
                            (c) => (c.toLowerCase() as unknown) as (keyof ThemeWebColors)[],
                        )}
                    >
                        {displayInCartCount && quantity > 0 && (
                            <ColorBadge itemProp="availability" className="f3-400" bgColor="attention">
                                {quantity}
                            </ColorBadge>
                        )}
                    </VariationDot>
                    <Flex column className={`${compact ? '' : 'ms-2'}`}>
                        <Flex column={!compact} justify="center">
                            <ColorText
                                selected={selected}
                                light={!selected}
                                small={compact}
                                className={`${selected ? 'f1-500' : 'f1-400'} text-capitalize`}
                            >
                                {variation.color || variation.variationNumber}
                            </ColorText>
                            {variation.colorCode && !compact && (
                                <ColorText small lighter className={`w-100`}>
                                    ({variation.colorCode})
                                </ColorText>
                            )}
                        </Flex>
                        <Tags compact tags={tags} />
                    </Flex>
                </Flex>
            )}
        </Color>
    );
};

export default VariationInput;

const ColorText = styled(Text)<{ selected?: boolean; lighter?: boolean; small?: boolean }>`
    font-size: ${({ small }) => (small ? '10px' : '12px')};
    border-bottom: 1px solid transparent;
    ${({ selected }) => selected && 'border-bottom: 1px solid black'};
    ${({ lighter }) => lighter && 'color: #a6a6a6'};
`;

const Color = styled.span<{ compact?: boolean; disabled: boolean }>`
    display: flex;
    align-items: center;
    position: relative;
    border: 0;
    outline: 0;
    text-align: left;
    background: ${({ disabled, theme }) => (disabled ? theme.colors.disabled : 'none')};
    ${({ compact }) => compact && 'justify-content:center;'}

    ${({ disabled }) =>
        !disabled
            ? `&:hover ${ColorText}:first-child {
    border-bottom: 1px solid black;
  }
  `
            : 'opacity: 0.6;'}
`;

const ColorBadge = styled(Badge)`
    left: 14px;
    top: 12px;
    right: auto;
`;

const ImageContainer = styled(Flex)`
    width: 95px;
    height: 125px;
    background-color: white;
`;

type VariationImageProps = {
    selected?: boolean;
};

const VariationImage = styled.img<VariationImageProps>`
    position: relative;
    border-bottom: 2px solid ${({ selected, theme }) => (selected ? `${theme.colors.accent}` : 'transparent')};
    height: 100%;
    width: 100%;
    object-fit: contain;

    &:hover,
    &:focus {
        ${({ selected }) => !selected && `border-color: initial;`}
    }
`;
