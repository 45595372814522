"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShopProductAddOnToJSON = exports.ShopProductAddOnFromJSONTyped = exports.ShopProductAddOnFromJSON = exports.instanceOfShopProductAddOn = void 0;
var runtime_1 = require("../runtime");
var ShopProductAddOnInputType_1 = require("./ShopProductAddOnInputType");
var ShopProductAddOnType_1 = require("./ShopProductAddOnType");
/**
 * Check if a given object implements the ShopProductAddOn interface.
 */
function instanceOfShopProductAddOn(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfShopProductAddOn = instanceOfShopProductAddOn;
function ShopProductAddOnFromJSON(json) {
    return ShopProductAddOnFromJSONTyped(json, false);
}
exports.ShopProductAddOnFromJSON = ShopProductAddOnFromJSON;
function ShopProductAddOnFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': !(0, runtime_1.exists)(json, 'type') ? undefined : (0, ShopProductAddOnType_1.ShopProductAddOnTypeFromJSON)(json['type']),
        'inputType': !(0, runtime_1.exists)(json, 'inputType') ? undefined : (0, ShopProductAddOnInputType_1.ShopProductAddOnInputTypeFromJSON)(json['inputType']),
        'price': !(0, runtime_1.exists)(json, 'price') ? undefined : json['price'],
        'id': !(0, runtime_1.exists)(json, 'id') ? undefined : json['id'],
        'productName': !(0, runtime_1.exists)(json, 'productName') ? undefined : json['productName'],
        'productNumber': !(0, runtime_1.exists)(json, 'productNumber') ? undefined : json['productNumber'],
    };
}
exports.ShopProductAddOnFromJSONTyped = ShopProductAddOnFromJSONTyped;
function ShopProductAddOnToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, ShopProductAddOnType_1.ShopProductAddOnTypeToJSON)(value.type),
        'inputType': (0, ShopProductAddOnInputType_1.ShopProductAddOnInputTypeToJSON)(value.inputType),
        'price': value.price,
        'id': value.id,
        'productName': value.productName,
        'productNumber': value.productNumber,
    };
}
exports.ShopProductAddOnToJSON = ShopProductAddOnToJSON;
