import { faInfoCircle, faMinus, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/ui/Flex';
import IconButton from 'components/ui/IconButton';
import Input from 'components/ui/Input';
import useAppSelector from 'hooks/useAppSelector';
import useBreakPoints from 'hooks/useBreakPoints';
import { CartItemSkuAddOn } from 'microshop-api';
import { useState } from 'react';
import styled from 'styled-components/macro';
import { BreakPointSize } from 'types/styled';
import ArticleInfo from './ArticleInfo';
import { QuantityChangedFunction } from './CartItems';
import { isSameCollectionVariation } from 'store/reducers/cartSlice';

type AddonRowProps = {
    name: string;
    img?: string;
    imgAlt?: string;
    imgBg?: boolean;
    invalidVariation?: boolean;
    addon?: CartItemSkuAddOn;
    info: string[];
    quantity?: number;
    sku: any;
    colors?: any[] | null | undefined;
    price?: string | null;
    priceRegular?: string | null;
    total?: string;
    onQtyChange?: QuantityChangedFunction;
    onEditRefinements?: (i: number) => void;
    onRemoveRefinements?: (i: number) => void;
    forceSize?: BreakPointSize;
    variationNumber?: string;
    collectionId?: number | null;
    split?: string | null;
    onSetComment?: (comment: string) => void;
    files?: React.ReactNode[];
    hideDateCell?: boolean;
    stockOperation?: any;
    stockOperationSummary?: any;
    articleDate?: string | undefined | null;
};

export const CartRowAddon = ({
    img,
    imgAlt,
    imgBg,
    name,
    addon,
    quantity,
    sku,
    colors,
    price,
    priceRegular,
    total,
    forceSize,
    variationNumber,
    collectionId,
    split,
    onQtyChange,
    files,
    stockOperation,
}: AddonRowProps) => {
    const bp = useBreakPoints(forceSize);
    const { localCart, loading } = useAppSelector(({ cart }) => cart);
    const cartVariation = localCart.find((v) => isSameCollectionVariation(v, { variationNumber, collectionId, split }));
    const [articleInfo, setArticleInfo] = useState<string | undefined>(undefined);
    const skuNumber = sku.sku!;

    if (!cartVariation) return null;

    const currentSkuQty = cartVariation.skus[skuNumber].qty;

    const handleRemove = () => {
        handleChange(0, true);
    };

    const handleChange = (newQty: number, immediate?: boolean) => {
        if (!onQtyChange || Number.isNaN(newQty) || !addon) return;

        const currentSkuQty = cartVariation.skus[skuNumber].qty;
        const addonId = addon.id!;

        const maxQuantity = currentSkuQty;
        const currentAddons = cartVariation.skus[skuNumber].addOns!;

        const finalAddons = [...currentAddons]?.map((a) => {
            return { ...a, quantity: addonId === a.id ? newQty : a.quantity };
        });

        onQtyChange({
            sku: skuNumber,
            qty: currentSkuQty,
            addons: newQty <= maxQuantity ? finalAddons : currentAddons,
            immediate,
        });
    };

    if (!addon) return null;

    const sm = bp.smBelow;
    const xs = bp.xsBelow;
    const invalid = false;

    return (
        <>
            {typeof stockOperation === 'number' && (
                <StockOperationBar align="center" justify="between" className="p-2 w-100 f3-400"></StockOperationBar>
            )}
            <StyledContainer className="w-100" disabled={addon.mandatory}>
                <AddOnBar className="p-2" />
                <SkuRow className="p-2 f3-500 w-100" invalid={false}>
                    <ArticleInfo
                        key={addon?.inputs?.join() ?? ''}
                        commentAllowed={false}
                        editingComment={false}
                        info={[(addon?.inputs ?? []).join(', ')]}
                        colors={colors}
                        name={name}
                        files={files}
                        img={img}
                        imgAlt={imgAlt}
                        imgBg={imgBg}
                        invalid={invalid}
                        invalidVariation={false}
                        addon={true}
                    />
                    <QtyCell>
                        {addon?.id ? (
                            <CartInput
                                key={addon.quantity}
                                pending={loading}
                                max={currentSkuQty}
                                onQtyChange={(e) => handleChange(+e.target.value)}
                                quantity={addon.quantity!}
                                disabled={invalid}
                            ></CartInput>
                        ) : (
                            `${quantity ?? ''} `
                        )}
                        {sm && <PriceSmall valid={true} total={total} regular={priceRegular} />}
                    </QtyCell>
                    {!sm && !xs && (
                        <>
                            <RightCell>{true && <Price price={price} regular={priceRegular} />}</RightCell>
                            <RightCell>{true && <PriceTotal total={total} regular={priceRegular} />}</RightCell>
                        </>
                    )}
                    {!xs && addon.id && (
                        <RemoveCell className="d-flex justify-content-end align-self-center">
                            <IconButton
                                className="flex-shrink-0"
                                icon={faTimes}
                                onClick={handleRemove}
                                disabled={loading}
                            />
                        </RemoveCell>
                    )}
                </SkuRow>
            </StyledContainer>
            {articleInfo && (
                <InfoCell className="p-2" align="center" justify="between">
                    <Flex align="center">
                        <CellIcon icon={faInfoCircle} className="ms-2 me-3" />
                        {articleInfo}
                    </Flex>
                    <IconButton className="flex-shrink-0" icon={faMinus} onClick={() => setArticleInfo(undefined)} />
                </InfoCell>
            )}
        </>
    );
};

export default CartRowAddon;

const StyledContainer = styled(Flex)<{ disabled?: boolean }>`
    ${({ disabled }) =>
        disabled &&
        `
            pointer-events: none;
            opacity: 0.6;
        `};
`;
type CartInputProps = {
    onQtyChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    quantity: number;
    pending: boolean;
    disabled?: boolean;
    max: number;
};
const CartInput: React.FC<CartInputProps> = ({ onQtyChange, quantity, pending, disabled, max }) => (
    <InputQuantity
        min={0}
        max={max}
        disabled={disabled || pending}
        type="number"
        defaultValue={quantity}
        onChange={onQtyChange}
    />
);

const Price: React.FC<{
    regular: string | null | undefined;
    price: string | null | undefined;
}> = ({ price, regular }) =>
    regular ? (
        <>
            <PriceTextRegular>{regular}</PriceTextRegular>
            <PriceTextDiscount>{price}</PriceTextDiscount>
        </>
    ) : (
        <PriceText>{price}</PriceText>
    );

const PriceSmall: React.FC<{
    regular: string | null | undefined;
    total: string | null | undefined;
    valid: boolean;
}> = ({ total, regular, valid }) =>
    regular ? (
        <>
            <PriceTextDiscount className="mt-2">{valid && total}</PriceTextDiscount>
        </>
    ) : (
        <PriceText className="mt-2">{valid && total}</PriceText>
    );

const PriceTotal: React.FC<{
    regular: string | null | undefined;
    total: string | null | undefined;
}> = ({ total, regular }) =>
    regular ? (
        <>
            <PriceTextDiscount>{total}</PriceTextDiscount>
        </>
    ) : (
        <PriceText>{total}</PriceText>
    );

const StockOperationBar = styled(Flex)`
    font-size: 12px;
    background: ${({ theme }) => theme.colors.fillMedium};
`;
const AddOnBar = styled.div`
    background: ${({ theme }) => theme.colors.fillGray};
`;

const TableRow = styled(Flex)`
    font-size: 12px;
    align-items: center;
`;

const SkuRow = styled(TableRow)<{ invalid?: boolean }>`
    background: #fff;
    min-height: 59px;
    margin-bottom: 1px;
    justify-content: flex-end;
    flex-wrap: wrap;

    ${({ theme }) => theme.media.sm} {
        flex-wrap: nowrap;
    }

    ${({ invalid }) => invalid && 'opacity: 0.6;'}
`;

const Cell = styled.div`
    flex-shrink: 0;
`;

const RightCell = styled(Cell)`
    text-align: right;
    width: 85px;
`;

const QtyCell = styled(RightCell)`
    width: 90px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    align-self: center;
    justify-content: stretch;

    > *:nth-child(2) {
        line-height: 1;
    }
`;

const InputQuantity = styled(Input)`
    border-radius: 0;
    width: 88px;
    margin-bottom: 4px;
    ${({ theme }) => theme.media.xs} {
        margin-bottom: 0;
        width: 68px;
    }
`;

const InfoText = styled.div`
    color: ${({ theme }) => theme.colors.textLight};
`;

const PriceTextRegular = styled(InfoText)`
    color: ${({ theme }) => theme.colors.text};
    text-decoration: line-through;
`;

const PriceTextDiscount = styled(InfoText)`
    color: ${({ theme }) => theme.colors.attention};
`;

const PriceText = styled(InfoText)`
    color: ${({ theme }) => theme.colors.price};
`;

const InfoCell = styled(Flex)`
    white-space: normal;
`;

const CellIcon = styled(FontAwesomeIcon)`
    font-size: 16px;
`;

const RemoveCell = styled(RightCell)`
    width: calc(34px + 0.8rem);
`;
