import ProductList from 'components/Product/ProductList';
import Flex from 'components/ui/Flex';
import Text from 'components/ui/Text';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import { useEffect } from 'react';
import { Col } from 'reactstrap';
import { loadProducts } from 'store/reducers/assortmentSlice';
import styled from 'styled-components/macro';

const Home = () => {
    const dispatch = useAppDispatch();
    const { shop } = useAppSelector(({ shop }) => shop);
    const { products, loading, categoriesRaw } = useAppSelector(({ assortment }) => assortment);

    useEffect(() => {
        dispatch(loadProducts({ page: 1, all: true }));
    }, [dispatch, categoriesRaw]);

    return (
        <>
            <Col lg="5" sm="8" className="mx-auto justify-content-center">
                <Flex className="mb-4 p-4 d-flex align-items-center justify-content-center" column>
                    <Header className="display-2">{shop?.name}</Header>
                    <Text light className="h2 preformatted text-break">
                        {shop?.description}
                    </Text>

                    <Text light className="h3  text-break">
                        {shop?.bodyText}
                    </Text>
                </Flex>
            </Col>
            <ProductList center={true} products={products.slice(0, 4)} loading={loading} />
        </>
    );
};

const Header = styled.h1`
    font-size: 32px;
`;
export default Home;
