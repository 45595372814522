"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.B2BCheckoutToJSON = exports.B2BCheckoutFromJSONTyped = exports.B2BCheckoutFromJSON = exports.instanceOfB2BCheckout = void 0;
var runtime_1 = require("../runtime");
var Delivery_1 = require("./Delivery");
var OrderDetails_1 = require("./OrderDetails");
var UserOptions_1 = require("./UserOptions");
/**
 * Check if a given object implements the B2BCheckout interface.
 */
function instanceOfB2BCheckout(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfB2BCheckout = instanceOfB2BCheckout;
function B2BCheckoutFromJSON(json) {
    return B2BCheckoutFromJSONTyped(json, false);
}
exports.B2BCheckoutFromJSON = B2BCheckoutFromJSON;
function B2BCheckoutFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'delivery': !(0, runtime_1.exists)(json, 'delivery') ? undefined : (0, Delivery_1.DeliveryFromJSON)(json['delivery']),
        'billing': !(0, runtime_1.exists)(json, 'billing') ? undefined : (0, UserOptions_1.UserOptionsFromJSON)(json['billing']),
        'order': !(0, runtime_1.exists)(json, 'order') ? undefined : (0, OrderDetails_1.OrderDetailsFromJSON)(json['order']),
        'origin': !(0, runtime_1.exists)(json, 'origin') ? undefined : json['origin'],
    };
}
exports.B2BCheckoutFromJSONTyped = B2BCheckoutFromJSONTyped;
function B2BCheckoutToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'delivery': (0, Delivery_1.DeliveryToJSON)(value.delivery),
        'billing': (0, UserOptions_1.UserOptionsToJSON)(value.billing),
        'order': (0, OrderDetails_1.OrderDetailsToJSON)(value.order),
        'origin': value.origin,
    };
}
exports.B2BCheckoutToJSON = B2BCheckoutToJSON;
