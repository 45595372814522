import Flex from 'components/ui/Flex';
import useAppSelector from 'hooks/useAppSelector';
import React from 'react';
import { selectBreadCrumbs } from 'store/reducers/assortmentSlice';
import styled from 'styled-components/macro';
import Link from './Link';
interface BreadcrumbsProps {
    activePage?: string;
    className?: string;
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ activePage, className }) => {
    const { categories } = useAppSelector(({ assortment }) => assortment);
    const pageBreadcrumbs = useAppSelector(selectBreadCrumbs(activePage ?? ''));

    const activePageObject = categories[activePage ?? ''];
    return (
        <BreadcrumbsWrapper
            className={`pt-2 px-3 f3-400 ${className ?? ''}`}
            role="navigation"
            aria-label="Breadcrumbs"
        >
            <BreadcrumbList itemScope itemType="http://schema.org/BreadcrumbList">
                {pageBreadcrumbs.map((bc, i) => (
                    <BreadcrumbListItemLink
                        key={i}
                        itemProp="itemListElement"
                        itemScope
                        itemType="http://schema.org/ListItem"
                    >
                        <Link to={{ pathname: bc.slug }} itemProp="item" noStyle>
                            {bc.name}
                            <meta itemProp="name" content={`${bc.name}`} />
                            <meta itemProp="position" content={`${i}`} />
                        </Link>
                    </BreadcrumbListItemLink>
                ))}
                {activePageObject && (pageBreadcrumbs.length ?? 0) > 0 && (
                    <BreadcrumbListItem itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
                        {activePageObject.name}
                        <meta itemProp="name" content={`${activePageObject}`} />
                        <meta itemProp="position" content={`${pageBreadcrumbs?.length}`} />
                    </BreadcrumbListItem>
                )}
            </BreadcrumbList>
        </BreadcrumbsWrapper>
    );
};
export default Breadcrumbs;

const BreadcrumbsWrapper = styled.div``;

const BreadcrumbList = styled.ol`
    list-style-type: none;
    padding-left: 0;
    width: 100%;
    margin: 0;
`;

const BreadcrumbListItem = styled.li`
    position: relative;
    display: inline-block;
    font-size: 13px;
    & {
        margin-right: 1.5em;
    }
    a,
    a:visited {
        color: ${({ theme }) => theme.colors.textLight};
    }
    a:hover {
        color: ${({ theme }) => theme.colors.text};
    }
`;

const BreadcrumbListItemLink = styled(BreadcrumbListItem)`
    &:after {
        content: '/';
        position: absolute;
        top: 0px;
        left: 100%;
        width: 1.5em;
        display: block;
        text-align: center;
    }

    color: currentColor;

    :hover {
        text-decoration: underline;
    }
`;

export const BreadcrumbsFill = styled(Flex)`
    border-bottom: 1px solid ${({ theme }) => theme.colors.borderLight};
`;
