"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CheckoutToJSON = exports.CheckoutFromJSONTyped = exports.CheckoutFromJSON = exports.instanceOfCheckout = void 0;
var runtime_1 = require("../runtime");
var B2BCheckout_1 = require("./B2BCheckout");
var StandardCheckout_1 = require("./StandardCheckout");
/**
 * Check if a given object implements the Checkout interface.
 */
function instanceOfCheckout(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfCheckout = instanceOfCheckout;
function CheckoutFromJSON(json) {
    return CheckoutFromJSONTyped(json, false);
}
exports.CheckoutFromJSON = CheckoutFromJSON;
function CheckoutFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'standardCheckout': !(0, runtime_1.exists)(json, 'standardCheckout') ? undefined : (0, StandardCheckout_1.StandardCheckoutFromJSON)(json['standardCheckout']),
        'b2BCheckout': !(0, runtime_1.exists)(json, 'b2BCheckout') ? undefined : (0, B2BCheckout_1.B2BCheckoutFromJSON)(json['b2BCheckout']),
    };
}
exports.CheckoutFromJSONTyped = CheckoutFromJSONTyped;
function CheckoutToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'standardCheckout': (0, StandardCheckout_1.StandardCheckoutToJSON)(value.standardCheckout),
        'b2BCheckout': (0, B2BCheckout_1.B2BCheckoutToJSON)(value.b2BCheckout),
    };
}
exports.CheckoutToJSON = CheckoutToJSON;
