"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShopCompleteVariationToJSON = exports.ShopCompleteVariationFromJSONTyped = exports.ShopCompleteVariationFromJSON = exports.instanceOfShopCompleteVariation = void 0;
var runtime_1 = require("../runtime");
var PriceInformation_1 = require("./PriceInformation");
var ProductCollection_1 = require("./ProductCollection");
var ProductImage_1 = require("./ProductImage");
var ProductStockSku_1 = require("./ProductStockSku");
var ShopProductAddOn_1 = require("./ShopProductAddOn");
var ShopProductImage_1 = require("./ShopProductImage");
var TagType_1 = require("./TagType");
/**
 * Check if a given object implements the ShopCompleteVariation interface.
 */
function instanceOfShopCompleteVariation(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfShopCompleteVariation = instanceOfShopCompleteVariation;
function ShopCompleteVariationFromJSON(json) {
    return ShopCompleteVariationFromJSONTyped(json, false);
}
exports.ShopCompleteVariationFromJSON = ShopCompleteVariationFromJSON;
function ShopCompleteVariationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'color': !(0, runtime_1.exists)(json, 'color') ? undefined : json['color'],
        'colorCode': !(0, runtime_1.exists)(json, 'colorCode') ? undefined : json['colorCode'],
        'variationNumber': !(0, runtime_1.exists)(json, 'variationNumber') ? undefined : json['variationNumber'],
        'image': !(0, runtime_1.exists)(json, 'image') ? undefined : (0, ProductImage_1.ProductImageFromJSON)(json['image']),
        'webColors': !(0, runtime_1.exists)(json, 'webColors') ? undefined : json['webColors'],
        'tags': !(0, runtime_1.exists)(json, 'tags') ? undefined : (json['tags'] === null ? null : json['tags'].map(TagType_1.TagTypeFromJSON)),
        'featured': !(0, runtime_1.exists)(json, 'featured') ? undefined : json['featured'],
        'skus': !(0, runtime_1.exists)(json, 'skus') ? undefined : (json['skus'] === null ? null : json['skus'].map(ProductStockSku_1.ProductStockSkuFromJSON)),
        'images': !(0, runtime_1.exists)(json, 'images') ? undefined : (json['images'] === null ? null : json['images'].map(ShopProductImage_1.ShopProductImageFromJSON)),
        'price': !(0, runtime_1.exists)(json, 'price') ? undefined : (0, PriceInformation_1.PriceInformationFromJSON)(json['price']),
        'collections': !(0, runtime_1.exists)(json, 'collections') ? undefined : (json['collections'] === null ? null : json['collections'].map(ProductCollection_1.ProductCollectionFromJSON)),
        'addons': !(0, runtime_1.exists)(json, 'addons') ? undefined : (json['addons'] === null ? null : json['addons'].map(ShopProductAddOn_1.ShopProductAddOnFromJSON)),
        'minQuantity': !(0, runtime_1.exists)(json, 'minQuantity') ? undefined : json['minQuantity'],
    };
}
exports.ShopCompleteVariationFromJSONTyped = ShopCompleteVariationFromJSONTyped;
function ShopCompleteVariationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'color': value.color,
        'colorCode': value.colorCode,
        'variationNumber': value.variationNumber,
        'image': (0, ProductImage_1.ProductImageToJSON)(value.image),
        'webColors': value.webColors,
        'tags': value.tags === undefined ? undefined : (value.tags === null ? null : value.tags.map(TagType_1.TagTypeToJSON)),
        'price': (0, PriceInformation_1.PriceInformationToJSON)(value.price),
        'collections': value.collections === undefined ? undefined : (value.collections === null ? null : value.collections.map(ProductCollection_1.ProductCollectionToJSON)),
        'addons': value.addons === undefined ? undefined : (value.addons === null ? null : value.addons.map(ShopProductAddOn_1.ShopProductAddOnToJSON)),
        'minQuantity': value.minQuantity,
    };
}
exports.ShopCompleteVariationToJSON = ShopCompleteVariationToJSON;
