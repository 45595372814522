"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TagTypeToJSON = exports.TagTypeFromJSONTyped = exports.TagTypeFromJSON = exports.TagType = void 0;
/**
 *
 * @export
 */
exports.TagType = {
    New: 0,
    Outlet: 1,
    Sustainable: 2,
    UpComming: 3,
    Label: 4
};
function TagTypeFromJSON(json) {
    return TagTypeFromJSONTyped(json, false);
}
exports.TagTypeFromJSON = TagTypeFromJSON;
function TagTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.TagTypeFromJSONTyped = TagTypeFromJSONTyped;
function TagTypeToJSON(value) {
    return value;
}
exports.TagTypeToJSON = TagTypeToJSON;
