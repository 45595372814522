import { faInfoCircle, faMinus, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Datepicker from 'components/ui/Datepicker';
import Flex from 'components/ui/Flex';
import IconButton from 'components/ui/IconButton';
import Input from 'components/ui/Input';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useBreakPoints from 'hooks/useBreakPoints';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isSameCollectionVariation, setSkuDate } from 'store/reducers/cartSlice';
import styled from 'styled-components/macro';
import { BreakPointSize } from 'types/styled';
import ArticleInfo from './ArticleInfo';
import { QuantityChangedFunction } from './CartItems';
import { CartItemProduct, CartItemSku } from 'microshop-api';

type ArticleRowProps = {
    addonIndex?: number;
    name: string;
    img?: string;
    imgAlt?: string;
    imgBg?: boolean;
    sku?: CartItemSku;
    invalidVariation?: boolean;
    product?: CartItemProduct;
    quantity?: number;
    info: string[];
    colors?: any[] | null | undefined;
    price?: string | null;
    priceRegular?: string | null;
    total?: string;
    onQtyChange?: QuantityChangedFunction;
    onEditRefinements?: (i: number) => void;
    onRemoveRefinements?: (i: number) => void;
    forceSize?: BreakPointSize;
    variationNumber?: string;
    collectionId?: number | null;
    split?: string | null;
    onRemoveColor?: () => void;
    onSetComment?: (comment: string) => void;
    files?: React.ReactNode[];
    addon?: boolean;
    addonIds?: number[];
    hideDateCell?: boolean;
    stockOperation?: any;
    stockOperationSummary?: any;
    articleDate?: string | undefined | null;
};

export const CartRowArticle = ({
    addonIndex,
    img,
    imgAlt,
    imgBg,
    name,
    sku,
    product,
    quantity,
    info,
    colors,
    price,
    priceRegular,
    total,
    forceSize,
    variationNumber,
    collectionId,
    split,
    invalidVariation,
    onQtyChange,
    onEditRefinements,
    onRemoveRefinements,
    onSetComment,
    files,
    addonIds,
    hideDateCell,
}: ArticleRowProps) => {
    const { t } = useTranslation();
    const bp = useBreakPoints(forceSize);
    const { localCart, loading } = useAppSelector(({ cart }) => cart);
    const dispatch = useAppDispatch();
    const cartVariation = localCart.find((cv) =>
        isSameCollectionVariation(cv, { variationNumber, collectionId, split }),
    );
    const [editingComment, setEditingComment] = useState<boolean>(false);
    const [articleInfo, setArticleInfo] = useState<string | undefined>(undefined);
    const [comment, setComment] = useState<string>(sku?.sku ? cartVariation?.skus[sku.sku].comment || '' : '');

    const localDate = sku?.sku ? cartVariation?.skus[sku.sku].date : undefined;
    const [selectedDate, setSelectedDate] = useState<Date | null | undefined>(localDate?.date);

    // listen to date changes.
    useEffect(() => {
        setSelectedDate(localDate?.date);
    }, [localDate]);

    const handleRemove = () => {
        if (onRemoveRefinements != null && typeof addonIndex === 'number') onRemoveRefinements(addonIndex);
        else handleChange(0, true);
    };

    const handleChange = (newQty: number, immediate?: boolean) => {
        if (!onQtyChange || Number.isNaN(newQty) || !sku?.sku || !cartVariation) return;

        const skuNumber = sku.sku;
        const currentQty = cartVariation.skus[skuNumber].qty;
        const minQuantity = (cartVariation.minQty || 1) - (cartVariation.variationQty - currentQty);
        const currentAddons = cartVariation.skus[skuNumber].addOns!;
        // If this is a remove sku event and it's the last sku, we don't need to toast minQty warning.
        const lastSize = currentQty === cartVariation.variationQty && newQty === 0;

        if (!lastSize && newQty < minQuantity) {
            onQtyChange({ sku: skuNumber, qty: minQuantity, addons: currentAddons, immediate, collectionId, split });
        } else {
            onQtyChange({ sku: skuNumber, qty: newQty, addons: currentAddons, immediate, collectionId, split });
        }
    };

    const handleComment = (comment: string) => {
        sku?.sku && onSetComment && onSetComment(comment);
    };

    const handleDate = (date: Date | [Date, Date] | null) => {
        if (date instanceof Date) {
            sku?.sku &&
                variationNumber &&
                dispatch(setSkuDate({ variationNumber: variationNumber, sku: sku.sku, date }));
            setSelectedDate(date);
        }
    };

    if (typeof addonIndex !== 'number' && (!sku || !cartVariation)) return null;
    const SkuDatepicker = () => (
        <DatepickerWrap>
            <StyledDatePicker
                bgColor={localDate?.requested ? 'warning' : localDate?.note ? 'warning' : undefined}
                minDate={new Date()}
                selectedDate={selectedDate}
                onChange={handleDate}
                header={t('shipment.requestDate', 'Request delivery date')}
            />
        </DatepickerWrap>
    );

    const sm = bp.smBelow;
    const xs = bp.xsBelow;
    const invalidProduct = invalidVariation;

    return (
        <>
            <SkuRow className="p-2 f3-500" invalid={invalidProduct}>
                <ArticleInfo
                    comment={comment}
                    commentAllowed
                    addonIndex={addonIndex}
                    editingComment={editingComment}
                    info={info}
                    colors={colors}
                    name={`${split ? `${split} / ` : ''}${name}`}
                    files={files}
                    img={img}
                    imgAlt={imgAlt}
                    imgBg={imgBg}
                    invalidVariation={invalidProduct}
                    setEditingComment={setEditingComment}
                    addonIds={addonIds}
                    onEditRefinements={onEditRefinements}
                />
                <QtyCell>
                    {sku?.sku && product && cartVariation ? (
                        <>
                            <CartInput
                                pending={loading}
                                onQtyChange={(e) => handleChange(+e.target.value)}
                                quantity={cartVariation.skus[sku.sku].qty}
                            ></CartInput>
                            {sku.price?.inboxItems && sku.price.inboxItems > 1 && sku.quantity && (
                                <InfoText>
                                    ({sku.price.inboxItems * sku.quantity} {t('stock_pcs', 'pcs')})
                                </InfoText>
                            )}
                        </>
                    ) : onQtyChange && quantity && typeof addonIndex === 'number' ? (
                        <CartInput
                            pending={loading}
                            onQtyChange={(e) =>
                                onQtyChange({ sku: addonIndex, qty: +e.target.value, addons: sku?.addOns ?? [] })
                            }
                            quantity={quantity}
                        ></CartInput>
                    ) : (
                        `${quantity ?? ''} `
                    )}
                    {!hideDateCell && xs && !invalidProduct && <SkuDatepicker />}

                    {sm && <PriceSmall valid={!invalidProduct} total={total} regular={priceRegular} />}
                </QtyCell>
                {!sm && !xs && (
                    <>
                        <RightCell>{!invalidProduct && <Price price={price} regular={priceRegular} />}</RightCell>
                        <RightCell>{!invalidProduct && <PriceTotal total={total} regular={priceRegular} />}</RightCell>
                    </>
                )}
                {!hideDateCell && !xs ? <DateCell>{!invalidProduct && <SkuDatepicker />}</DateCell> : null}

                {!xs && (sku || typeof addonIndex === 'number') && (
                    <RemoveCell className="d-flex justify-content-end align-self-center">
                        <IconButton
                            className="flex-shrink-0"
                            icon={faTimes}
                            onClick={handleRemove}
                            disabled={loading}
                        />
                    </RemoveCell>
                )}
            </SkuRow>
            {editingComment && (
                <EditCell className="p-2">
                    <Input
                        autoFocus
                        maxLength={100}
                        placeholder={`${t('commentSet', 'Set comment')}`}
                        value={comment}
                        onKeyUp={(e) => {
                            if (e.key === 'Enter') {
                                handleComment(e.currentTarget.value);
                                setEditingComment(false);
                            }
                        }}
                        onChange={(e) => setComment(e.currentTarget.value)}
                        onBlur={(e) => {
                            handleComment(e.currentTarget.value);
                            setTimeout(() => setEditingComment(false), 150);
                        }}
                    />
                </EditCell>
            )}
            {articleInfo && (
                <InfoCell className="p-2" align="center" justify="between">
                    <Flex align="center">
                        <CellIcon icon={faInfoCircle} className="ms-2 me-3" />
                        {articleInfo}
                    </Flex>
                    <IconButton className="flex-shrink-0" icon={faMinus} onClick={() => setArticleInfo(undefined)} />
                </InfoCell>
            )}
        </>
    );
};

export default CartRowArticle;

type CartInputProps = {
    onQtyChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    quantity: number;
    pending: boolean;
    disabled?: boolean;
};
const CartInput: React.FC<CartInputProps> = ({ onQtyChange, quantity, pending, disabled }) => (
    <InputQuantity
        min={0}
        disabled={disabled || pending}
        type="number"
        defaultValue={(quantity ?? '').toString()}
        onChange={onQtyChange}
    />
);

const Price: React.FC<{
    regular: string | null | undefined;
    price: string | null | undefined;
}> = ({ price, regular }) =>
    regular ? (
        <>
            <PriceTextRegular>{regular}</PriceTextRegular>
            <PriceTextDiscount>{price}</PriceTextDiscount>
        </>
    ) : (
        <PriceText>{price}</PriceText>
    );

const PriceSmall: React.FC<{
    regular: string | null | undefined;
    total: string | null | undefined;
    valid: boolean;
}> = ({ total, regular, valid }) =>
    regular ? (
        <>
            <PriceTextDiscount className="mt-2">{valid && total}</PriceTextDiscount>
        </>
    ) : (
        <PriceText className="mt-2">{valid && total}</PriceText>
    );

const PriceTotal: React.FC<{
    regular: string | null | undefined;
    total: string | null | undefined;
}> = ({ total, regular }) =>
    regular ? (
        <>
            <PriceTextDiscount>{total}</PriceTextDiscount>
        </>
    ) : (
        <PriceText>{total}</PriceText>
    );

const StockOperationBar = styled(Flex)`
    font-size: 12px;
    background: ${({ theme }) => theme.colors.fillMedium};
`;

const TableRow = styled(Flex)`
    font-size: 12px;
    align-items: center;
`;

const SkuRow = styled(TableRow)<{ invalid?: boolean }>`
    background: white;
    min-height: 59px;
    margin-bottom: 1px;
    justify-content: flex-end;
    flex-wrap: wrap;

    ${({ theme }) => theme.media.sm} {
        flex-wrap: nowrap;
    }

    ${({ invalid }) => invalid && 'opacity: 0.6;'}
`;

const Cell = styled.div`
    flex-shrink: 0;
`;

const RightCell = styled(Cell)`
    text-align: right;
    width: 85px;
`;

const QtyCell = styled(RightCell)`
    width: 90px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    align-self: center;
    justify-content: stretch;

    > *:nth-child(2) {
        line-height: 1;
    }
`;

const DateCell = styled(RightCell)`
    padding-left: 25px;
    width: 105px;

    // iPhone zooms all input fields to font size 16, therefore the date doesn't fit
    @supports (-webkit-overflow-scrolling: touch) {
        padding-left: 10px;
        width: 117px;
    }
`;

const RemoveCell = styled(RightCell)`
    width: calc(34px + 0.8rem);
`;

const EditCell = styled(Cell)`
    background: white;
`;

const InputQuantity = styled(Input)`
    border-radius: 0;
    width: 88px;
    margin-bottom: 4px;
    ${({ theme }) => theme.media.xs} {
        margin-bottom: 0;
        width: 68px;
    }
`;

const InfoText = styled.div`
    color: ${({ theme }) => theme.colors.textLight};
`;

const PriceTextRegular = styled(InfoText)`
    color: ${({ theme }) => theme.colors.text};
    text-decoration: line-through;
`;

const PriceTextDiscount = styled(InfoText)`
    color: ${({ theme }) => theme.colors.attention};
`;

const PriceText = styled(InfoText)`
    color: ${({ theme }) => theme.colors.price};
`;

const StyledDatePicker = styled(Datepicker)`
    width: 100%;

    input {
        text-align: right;
    }
`;

const DatepickerWrap = styled.div`
    position: relative;
    padding-bottom: 12px;

    ${({ theme }) => theme.media.xs} {
        padding-bottom: 0px;
    }
`;

const InfoCell = styled(Flex)`
    white-space: normal;
`;

const CellIcon = styled(FontAwesomeIcon)`
    font-size: 16px;
`;
