import React from 'react';
import Flex from 'components/ui/Flex';
import Spinner from 'components/ui/Spinner';
import Text from 'components/ui/Text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingBag } from '@fortawesome/pro-solid-svg-icons';
import { toastDismissed } from 'store/reducers/cartSlice';
import useAppDispatch from 'hooks/useAppDispatch';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

export type CartToastData = {
    name?: string | null;
    quantity: number;
    color?: string | null;
    size?: string | null;
    img?: string | null;
    adding?: boolean;
    id: string;
};

const CartToast: React.FC<{ data: CartToastData }> = ({ data }) => {
    const { name, img, adding, color, size, quantity } = data;
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const onDismissToast = () => {
        if (localStorage.getItem('toast_cart_dismissed')) return;
        setTimeout(() => {
            dispatch(toastDismissed({ text: t('toastDismissText') }));
        }, 500);
    };

    return (
        <Toast justify="between" align="center" onClick={() => onDismissToast()}>
            <QuantityWrapper className="p-2 me-2 align-self-stretch" align="center" justify="center">
                <FontAwesomeIcon color="#222" size="lg" className="me-2" icon={faShoppingBag}></FontAwesomeIcon>
                <div>
                    <Text small className="f1-500">
                        {adding ? <Spinner /> : quantity}
                    </Text>
                </div>
            </QuantityWrapper>
            <ProductWrapper className="p-2 flex-grow-1" justify="between" align="center">
                {img && (
                    <ImageWrapper className="me-2 p-1">
                        <Image src={img}></Image>
                    </ImageWrapper>
                )}
                <ProductInfo column>
                    {name && (
                        <Name small textOverflow={'225'} data-name className="f1-500">
                            {name}
                        </Name>
                    )}
                    {color && <InfoText className="text-nowrap">{color}</InfoText>}
                    {size && <InfoText className="text-nowrap">{size}</InfoText>}
                </ProductInfo>
            </ProductWrapper>
        </Toast>
    );
};

export default CartToast;

const Toast = styled(Flex)`
    min-height: 100px;
`;

const ImageWrapper = styled.div`
    margin-left: -0.5rem;
    width: 55px;
`;

const ProductWrapper = styled(Flex)`
    font-size: 12px;
    background: white;
`;
const Name = styled(Text)`
    white-space: normal;
`;

const Image = styled.img`
    max-width: 100%;
    max-height: 100%;
`;

const QuantityWrapper = styled(Flex)`
    background-color: ${({ theme }) => theme.colors.fill};
    width: 100px;
`;

const ProductInfo = styled(Flex)`
    flex-grow: 2;
    font-size: 12px;
    flex-wrap: wrap;
`;

const InfoText = styled.span`
    color: ${({ theme }) => theme.colors.textLight};
`;
