import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/ui/Flex';
import Text from 'components/ui/Text';
import useAppSelector from 'hooks/useAppSelector';
import useBreakPoints from 'hooks/useBreakPoints';
import { CartItemProduct } from 'microshop-api';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { selectProductLinks } from 'store/reducers/assortmentSlice';
import styled from 'styled-components';
import { BreakPointSize } from 'types/styled';

type CartRowHeaderProps = {
    name?: string | null;
    productNumber?: string | null;
    icon?: IconDefinition;
    date?: boolean;
    options?: {
        size?: BreakPointSize;
        hide?: {
            name?: boolean;
            date?: boolean;
        };
    };
};

const CartRowHeader: React.FC<CartRowHeaderProps> = ({ name, productNumber, icon, options }) => {
    const { t } = useTranslation();
    const bp = useBreakPoints(options?.size);
    const showDate = useAppSelector(({ shop }) => shop?.shop?.isB2BShop) ?? false;

    return (
        <ProductHeader className="mb-1 px-2 f1-500">
            <ProductName>
                {!options?.hide?.name && <Name name={name} productNumber={productNumber} icon={icon} />}
            </ProductName>
            <QtyHeader>{t('quantity', 'Quantity')}</QtyHeader>
            <TableHeader hide={bp.smBelow}>{t('price', 'Price')}</TableHeader>
            <TableHeader hide={bp.smBelow}>{t('total', 'Total')}</TableHeader>
            {showDate && <DateHeader hide={bp.xsBelow}>{t('shipment.date', 'Delivery date')}</DateHeader>}
            <RemoveHeader hide={bp.xsBelow} />
        </ProductHeader>
    );
};

type NameProps = {
    productNumber?: string | null;
    name?: string | null;
    icon?: IconDefinition;
};
const Name: React.FC<NameProps> = ({ name, productNumber, icon }) => {
    const links = useAppSelector(selectProductLinks);

    const url = productNumber && links[productNumber];
    return url ? (
        <>
            <Link to={url}>
                {icon && <FontAwesomeIcon icon={icon} className="me-2" />}
                {name ?? productNumber}
            </Link>
        </>
    ) : (
        <Text>
            {icon && <FontAwesomeIcon icon={icon} className="me-2" />}
            {name}
        </Text>
    );
};

export default CartRowHeader;

const Cell = styled.div<{ hide?: boolean }>`
    flex-shrink: 0;
    ${({ hide }) => hide && 'display: none;'}
`;

const RightCell = styled(Cell)`
    text-align: right;
    width: 85px;
`;

const TableHeader = styled(RightCell)`
    font-weight: 500;
    color: ${({ theme }) => theme.colors.textLight};
`;

const ProductHeader = styled(Flex)`
    align-items: flex-end;
`;

const QtyHeader = styled(TableHeader)`
    width: 90px;
`;

const DateHeader = styled(TableHeader)`
    width: 112px;
`;

const RemoveHeader = styled(RightCell)`
    width: calc(34px + 0.8rem);
`;
const ProductName = styled(TableHeader)`
    font-size: 14px;
    color: ${({ theme }) => theme.colors.text};
    text-align: left;
    flex-grow: 2;
    flex-shrink: 2;
    white-space: pre-line;

    a:hover {
        text-decoration: underline;
    }
`;

// function getUnit(t: TFunction<'translation', undefined>, product?: CartProduct) {
//   if (!product) return t('stock_pcs', 'pcs');

//   if (!product.variations?.length || !product.variations[0].skus?.length) return 'price.pieces';
//   const boxItems = product.variations[0].skus[0].price?.inboxItems;
//   return boxItems && boxItems > 1 ? `${t('stock.box', 'box')}` : t('stock_pcs', 'pcs');
// }
