import React, { useState, useRef, useEffect, useLayoutEffect } from 'react';
import styled from 'styled-components/macro';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import IconButton from './IconButton';

export interface Item {
    content: React.ReactNode;
    header?: string;
    click?: (isOpen: boolean) => void;
    open?: boolean;
    scrollToOpenItem?: boolean;
}

export interface AccordionProps {
    items?: Item[];
    scrollToOpenItem?: boolean;
}

const Accordion = ({ items, scrollToOpenItem }: AccordionProps) => {
    return (
        <AccordionContainer className="px-2 px-md-4 mt-1 pb-4">
            {items?.map((item) => (
                <AccordionItem
                    key={item?.header}
                    content={item?.content}
                    header={item?.header}
                    open={item?.open}
                    click={item?.click}
                    scrollToOpenItem={scrollToOpenItem}
                />
            ))}
        </AccordionContainer>
    );
};

const AccordionItem = ({ click, content, header, open, scrollToOpenItem }: Item): JSX.Element => {
    const ref = useRef<HTMLDivElement>(null);
    const [isOpen, setIsOpen] = useState<boolean>(open || false);
    const shouldScrollTo = useRef(false);
    useEffect(() => {
        if (open && scrollToOpenItem) {
            setIsOpen(open);
            shouldScrollTo.current = true;
        }
    }, [open]);

    useLayoutEffect(() => {
        if (ref.current && shouldScrollTo.current) {
            shouldScrollTo.current = false;
            const element = ref.current.getBoundingClientRect().top + window.scrollY;
            window.scroll({
                top: element - 25,
                behavior: 'smooth',
            });
        }
    });

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    return (
        <AccordionItemContainer ref={ref}>
            <AccordionButton
                isOpen={isOpen}
                className="pb-3 d-flex justify-content-between align-items-center f1-700"
                onClick={() => {
                    click?.(isOpen);
                    toggleAccordion();
                }}
            >
                {header}
                <StyledDropDownIconButton small icon={isOpen ? faChevronUp : faChevronDown} />
            </AccordionButton>
            <AccordionContent isOpen={isOpen}>{content}</AccordionContent>
        </AccordionItemContainer>
    );
};

export default Accordion;

const AccordionContainer = styled.div`
    width: 100%;
`;

const AccordionItemContainer = styled.div`
    height: auto;
`;

const AccordionButton = styled.div<{ isOpen: boolean }>`
    width: 100%;
    padding: 1rem 0.6rem 1rem 0.6rem;
    gap: 4px;
    border: none;
    text-align: left;
    cursor: pointer;
    border-bottom: 1px solid #cfcec8;
    color: ${({ theme, isOpen }) => (!isOpen ? theme.colors.textLight : theme.colors.text)};
    background-color: transparent;
`;

const AccordionContent = styled.div<{ isOpen: boolean }>`
    height: ${({ isOpen }) => (isOpen ? 'auto' : '0')};
    opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
    overflow: hidden;
    transition: all 0.2s ease;
    padding-top: ${({ isOpen }) => (isOpen ? '1rem' : '0')};
    padding-bottom: ${({ isOpen }) => (isOpen ? '1rem' : '0')};
    padding-left: 0.6rem;
    padding-right: 0.6rem;
`;

const StyledDropDownIconButton = styled(IconButton)`
    background: ${({ theme }) => theme.colors.fill};
`;
