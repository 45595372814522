import Flex from 'components/ui/Flex';
import useAppSelector from 'hooks/useAppSelector';
import { useTranslation } from 'react-i18next';
// import { B2CCartTotal } from 'nwg-commerce-api';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components/macro';

type TotalProps = {
    className?: string;
};
export const Total = ({ className }: TotalProps) => {
    const { t } = useTranslation();
    const { shop, cart } = useAppSelector(({ shop, cart }) => ({ shop: shop.shop, cart: cart.cart }));

    return (
        <Wrapper className={className}>
            <CostRows total={cart.total}>
                <CostRow label={t('fee_shipping', 'Shipping Cost')} value={cart.shippingFee} />
                <CostRow label={t('discount', 'Discount')} value={cart.discount} />
                <CostRow label={t('fee_admin', 'Admin. fee')} value={cart.fees} />
                <CostRow label={t('vat', 'VAT')} value={cart.vat} />
                <CostRow
                    label={
                        cart?.totalInclVat
                            ? t('total_incl_vat', 'Amount (inc. vat)')
                            : t('total_excl_vat', 'Amount (ex. vat)')
                    }
                    value={cart?.totalInclVat ?? cart?.totalExVat}
                />
            </CostRows>
        </Wrapper>
    );
};

type CostRowsProps = { total?: string | null; children?: JSX.Element[] | JSX.Element; pending?: boolean };
export const CostRows = ({ total, children, pending }: CostRowsProps) => {
    const { t } = useTranslation();
    return (
        <>
            {total && (
                <Amount justify="between" className="f1-500 mb-1">
                    <Label className="f1-500">{t('total', 'Amount')}</Label>
                    <Sum className="f1-700">{pending ? <Skeleton width={90} /> : total}</Sum>
                </Amount>
            )}
            {children}
        </>
    );
};

type CostRowProps = {
    label: string;
    value?: string | null;
    pending?: boolean;
};
export const CostRow: React.FC<CostRowProps> = ({ label, value, pending }) =>
    value ? (
        <StyledCostRow justify="between">
            <Label>{label}</Label>
            <Cost>{pending ? <Skeleton width={50} /> : value}</Cost>
        </StyledCostRow>
    ) : null;

export default Total;

const Wrapper = styled.div`
    background-color: ${({ theme }) => theme.colors.fillWhite};
`;

const Amount = styled(Flex)`
    font-size: 18px;
`;

const Label = styled.span``;

const Sum = styled.span`
    color: ${({ theme }) => theme.colors.sale};
`;

const StyledCostRow = styled(Flex)`
    flex-grow: 1;
    font-size: 12px;
`;

const Cost = styled.span``;
