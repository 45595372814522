"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductCollectionToJSON = exports.ProductCollectionFromJSONTyped = exports.ProductCollectionFromJSON = exports.instanceOfProductCollection = void 0;
var runtime_1 = require("../runtime");
var PriceInformation_1 = require("./PriceInformation");
/**
 * Check if a given object implements the ProductCollection interface.
 */
function instanceOfProductCollection(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfProductCollection = instanceOfProductCollection;
function ProductCollectionFromJSON(json) {
    return ProductCollectionFromJSONTyped(json, false);
}
exports.ProductCollectionFromJSON = ProductCollectionFromJSON;
function ProductCollectionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'split': !(0, runtime_1.exists)(json, 'split') ? undefined : json['split'],
        'collectionId': !(0, runtime_1.exists)(json, 'collectionId') ? undefined : json['collectionId'],
        'price': !(0, runtime_1.exists)(json, 'price') ? undefined : (0, PriceInformation_1.PriceInformationFromJSON)(json['price']),
    };
}
exports.ProductCollectionFromJSONTyped = ProductCollectionFromJSONTyped;
function ProductCollectionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'split': value.split,
        'collectionId': value.collectionId,
        'price': (0, PriceInformation_1.PriceInformationToJSON)(value.price),
    };
}
exports.ProductCollectionToJSON = ProductCollectionToJSON;
