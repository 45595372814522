import {
    faAngleDown,
    faAngleUp,
    faArrowRight,
    faChevronDown,
    faChevronUp,
    faSlidersH,
} from '@fortawesome/pro-solid-svg-icons';
import Checkbox from 'components/ui/Checkbox';
import Flex from 'components/ui/Flex';
import HeaderIcon from 'components/ui/HeaderIcon';
import IconButton from 'components/ui/IconButton';
import Link from 'components/ui/Link';
import SlideIn from 'components/ui/SlideIn';
import Text from 'components/ui/Text';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useBreakPoints from 'hooks/useBreakPoints';
import { FilterItem, FilterOutput, FilterType } from 'microshop-api';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import ReactStickyBox from 'react-sticky-box';
import { Transition, TransitionStatus } from 'react-transition-group';
import { Button, Spinner } from 'reactstrap';
import {
    CategoryUrlMap,
    FilterKind,
    selectAssortmentPage,
    selectCompleteActiveFilters,
    showFilterToggled,
    toggleFilter,
} from 'store/reducers/assortmentSlice';
import styled, { ThemeWebColors } from 'styled-components';
import { KeyValueString } from 'types/KeyValuePair';

interface ProductFilterProps {
    offsetTop?: number;
}
const ProductFilter: React.FC<ProductFilterProps> = ({ offsetTop }) => {
    const {
        filters,
        filtersLoading,
        filtersShow,
        assortmentPageInfo,
        loading,
        // selectedCategory,
        // options,
    } = useAppSelector(({ assortment }) => assortment);
    const activeFilters = useAppSelector(selectCompleteActiveFilters);
    const selectedCategory = useAppSelector(selectAssortmentPage);
    const { t } = useTranslation();
    const bp = useBreakPoints();
    const dispatch = useAppDispatch();
    const hide = () => dispatch(showFilterToggled(false));

    const groupedFilters = activeFilters.reduce(
        (filterGroup, filter) => filterGroup.set(filter.type, filter.values),
        new Map<FilterKind, KeyValueString[]>(),
    );

    const dispatchFilterToggled = (filter: FilterItem) => {
        dispatch(
            toggleFilter({
                value: { key: filter.key!, value: filter.name || filter.name! },
                type: filter.fieldName as FilterKind,
            }),
        );
    };

    if (
        !filtersShow ||
        (filters.flatMap((fo) => fo.filter).length < 1 && (selectedCategory?.children?.length ?? 0) < 1)
    )
        return null;
    // const catOption = (options ?? []).filters?.find((of) => of.filter === 'categories');
    // const colorOption = options.filters?.find((of) => of.filter === 'color');

    return bp.lg ? (
        // offsetTop === Site menu + gutter
        <StickyBox offsetTop={offsetTop} offsetBottom={8}>
            <Transition in={filtersShow} timeout={150}>
                {(state) => (
                    <>
                        <AnimatedWrapper className={``} state={state}>
                            <InnerWrapper className="f1-500">
                                {filtersLoading && (
                                    <Skeleton className="d-block mb-1" height={400} count={2}></Skeleton>
                                )}
                                <Categories selectedCategory={selectedCategory} rows={40} />
                                {/* {(!catOption || catOption.visible) && <Categories rows={40} />} */}
                                {filters?.map(
                                    (fo: FilterOutput) =>
                                        fo.filter!?.length > 0 && (
                                            <FilterList className="mb-4" key={fo.fieldName}>
                                                {fo.filter!?.length > 0 && fo.type === FilterType.Color ? (
                                                    <ColorFilter
                                                        expanded={true}
                                                        filterOutput={fo}
                                                        filter={groupedFilters.get(fo.fieldName as FilterKind)}
                                                        onToggle={dispatchFilterToggled}
                                                    />
                                                ) : fo.type === FilterType.Checkbox ? (
                                                    <OutletFilter
                                                        onClick={dispatchFilterToggled}
                                                        filterOutput={fo}
                                                        filter={groupedFilters.get(fo.fieldName as FilterKind)}
                                                    ></OutletFilter>
                                                ) : (
                                                    <Filter
                                                        expanded={true}
                                                        rows={6}
                                                        filterOutput={fo}
                                                        filter={groupedFilters.get(fo.fieldName as FilterKind)}
                                                        onToggle={dispatchFilterToggled}
                                                    ></Filter>
                                                )}
                                            </FilterList>
                                        ),
                                )}
                            </InnerWrapper>
                        </AnimatedWrapper>
                    </>
                )}
            </Transition>
        </StickyBox>
    ) : (
        <SlideIn onClose={hide} visible>
            {{
                headerLogo: <HeaderIcon icon={faSlidersH} />,
                content: (
                    <CollapsedWrapper column className="p-3 f1-500">
                        {/* <Breadcrumbs /> */}
                        {/* <CurrentCategory className="f1-500">{selectedCategory?.name}</CurrentCategory> */}
                        <Categories selectedCategory={selectedCategory} rows={40} />
                        {filters?.map((fo: FilterOutput) => (
                            <FilterList className="mb-1" key={fo.fieldName}>
                                {fo.filter!?.length > 1 &&
                                    (fo.type === FilterType.Color ? (
                                        <ColorFilter
                                            collapse
                                            expanded={true}
                                            filterOutput={fo}
                                            filter={groupedFilters.get(fo.fieldName as FilterKind)}
                                            onToggle={dispatchFilterToggled}
                                        />
                                    ) : (
                                        <Filter
                                            expanded={true}
                                            rows={0}
                                            filterOutput={fo}
                                            filter={groupedFilters.get(fo.fieldName as FilterKind)}
                                            onToggle={dispatchFilterToggled}
                                        ></Filter>
                                    ))}
                            </FilterList>
                        ))}
                    </CollapsedWrapper>
                ),
                footerContent: (
                    <ButtonContainer className="pb-3">
                        <Button type="button" onClick={hide} pill="true" className="mt-3 mb-4 w-100">
                            {t('show', 'Show products')}
                            {loading ? (
                                <>
                                    {' '}
                                    <Spinner />
                                </>
                            ) : (
                                ` (${assortmentPageInfo.totalItems})`
                            )}
                        </Button>
                    </ButtonContainer>
                ),
            }}
        </SlideIn>
    );
};

type CategoriesProps = {
    selectedCategory: CategoryUrlMap;
    rows: number;
    invert?: boolean;
};

const Categories: React.FC<CategoriesProps> = ({ selectedCategory, rows, invert }) => {
    const { t } = useTranslation();
    const bp = useBreakPoints();
    const [showAll, setShowAll] = useState(true);

    if (!selectedCategory) return null;

    return (
        <>
            <Flex className="flex-row" justify="between" align="center">
                {selectedCategory?.children && selectedCategory.children.length > rows && (
                    <>
                        {bp.lgBelow && <FilterName small>{t('assortment.category_other', 'Categories')}</FilterName>}
                        <ShowAllRow invert={invert} toggled={showAll} onToggle={() => setShowAll(!showAll)} />
                    </>
                )}
            </Flex>
            <FilterList className="f1-500 mb-lg-4 mb-2">
                {selectedCategory?.children?.map((child: { name: string; path: string }, i: number) => {
                    return (
                        child &&
                        (showAll || i < rows) && (
                            <SelectRow key={i} text={child.name} url={child.path} invert={invert} />
                        )
                    );
                })}
            </FilterList>
        </>
    );
};

type ColorFilterProps = {
    expanded: boolean;
    filterOutput: FilterOutput;
    filter?: KeyValueString[];
    onToggle: (filter: FilterItem) => void;
    collapse?: boolean;
};
const ColorFilter: React.FC<ColorFilterProps> = ({ expanded, filterOutput, filter, onToggle, collapse }) => {
    const { t } = useTranslation();
    const [showAll, setShowAll] = useState(expanded ? true : false);

    const nrChecked = filterOutput.filter?.reduce((acc, f) => {
        return isChecked(f, filter) ? acc + 1 : acc;
    }, 0);

    return (
        <>
            <Flex justify="between" align="center">
                <FilterName small>
                    {t('filter_color')} {nrChecked && nrChecked > 0 ? ` (${nrChecked})` : null}
                </FilterName>
                {filterOutput.filter && collapse && (
                    <ShowAllRow toggled={showAll} onToggle={() => setShowAll(!showAll)} />
                )}
            </Flex>
            {(!collapse || showAll) && (
                <WebColors>
                    {filterOutput.filter!.map(
                        (f: FilterItem, i) =>
                            f.key && (
                                <Checkbox
                                    key={f.key + i}
                                    circle
                                    webColor={f.key?.toLowerCase() as keyof ThemeWebColors}
                                    iconColor={f.key === 'White' ? 'textLight' : 'textWhite'}
                                    name={f.name!}
                                    checked={isChecked(f, filter)}
                                    onChange={() => onToggle(f)}
                                />
                            ),
                    )}
                </WebColors>
            )}
        </>
    );
};

type FieldName =
    | 'color'
    | 'brand'
    | 'gender'
    | 'certification'
    | 'designer'
    | 'theme'
    | 'retail'
    | 'sleeve'
    | 'material'
    | 'feature'
    | 'activity'
    | 'range'
    | 'category'
    | 'care'
    | 'neckline'
    | 'fit'
    | 'outlet';
const isFieldName = (maybeFieldName: string | null | undefined): maybeFieldName is FieldName => {
    return (
        maybeFieldName === 'color' ||
        maybeFieldName === 'brand' ||
        maybeFieldName === 'gender' ||
        maybeFieldName === 'certification' ||
        maybeFieldName === 'designer' ||
        maybeFieldName === 'theme' ||
        maybeFieldName === 'retail' ||
        maybeFieldName === 'sleeve' ||
        maybeFieldName === 'material' ||
        maybeFieldName === 'feature' ||
        maybeFieldName === 'activity' ||
        maybeFieldName === 'range' ||
        maybeFieldName === 'category' ||
        maybeFieldName === 'care' ||
        maybeFieldName === 'neckline' ||
        maybeFieldName === 'fit' ||
        maybeFieldName === 'outlet'
    );
};

type FilterProps = {
    expanded: boolean;
    filterOutput: FilterOutput;
    filter?: KeyValueString[];
    onToggle: (filterItem: FilterItem) => void;
    rows: number;
    invert?: boolean;
};
const Filter: React.FC<FilterProps> = ({ expanded, filterOutput, filter, onToggle, rows, invert }) => {
    const { t } = useTranslation();
    const nrChecked = filterOutput.filter?.reduce((acc, f) => {
        return isChecked(f, filter) ? acc + 1 : acc;
    }, 0);
    const bp = useBreakPoints();
    const fieldName = isFieldName(filterOutput.fieldName) ? filterOutput.fieldName : undefined;

    const [showAll, setShowAll] = useState(bp.lgBelow && expanded ? true : false);
    const [form, setFormToShow] = useState<{ show: boolean; name: string }>({
        show: bp.lg && expanded ? true : false,
        name: fieldName ?? '',
    });
    const compact = !form.show && bp.lgBelow;
    const fieldNameTranslation = filterOutput.customLabel
        ? filterOutput.customLabel
        : fieldName
        ? // i18next-extract-mark-context-next-line ["", "color", "brand", "gender", "certification", "designer", "theme", "retail", "sleeve", "material", "feature", "activity", "range", "category", "care", "neckline", "fit"]
          t('filter', { context: fieldName })
        : undefined;
    return (
        <>
            <Flex justify="between" align="center">
                <FilterName small>
                    {fieldName && fieldNameTranslation}
                    {nrChecked && nrChecked > 0 ? ` (${nrChecked})` : null}
                </FilterName>

                {bp.lg && (
                    <StyledDropDownIconButton
                        small
                        icon={form.show && form.name === fieldName ? faChevronUp : faChevronDown}
                        onClick={(e) => {
                            e.preventDefault();
                            setFormToShow({
                                show: false,
                                name: fieldName ?? '',
                            });
                            setFormToShow({
                                show: form.name === fieldName ? !form.show : true,
                                name: fieldName ?? '',
                            });
                        }}
                    />
                )}
                {bp.lgBelow && <ShowAllRow invert={invert} toggled={showAll} onToggle={() => setShowAll(!showAll)} />}
            </Flex>
            {filterOutput.filter?.map(
                (f, i) =>
                    f.key &&
                    (form.show || compact) &&
                    (showAll || i < rows) && (
                        <CheckRow
                            key={`${f.key} + i`}
                            text={f.name || f.key}
                            isChecked={isChecked(f, filter)}
                            onToggle={() => onToggle(f)}
                            invert={invert}
                        />
                    ),
            )}
            {filterOutput.filter && filterOutput.filter.length > rows && form.show && (
                <ShowAllRow invert={invert} toggled={showAll} onToggle={() => setShowAll(!showAll)} />
            )}
        </>
    );
};

type OutletFilterProps = {
    filterOutput: FilterOutput;
    filter?: KeyValueString[];
    onClick: (filter: FilterItem) => void;
};

const OutletFilter: React.FC<OutletFilterProps> = ({ onClick, filterOutput, filter }) => {
    const { t } = useTranslation();
    const fieldName = filterOutput.fieldName === 'outlet' ? filterOutput.fieldName : '';
    const fieldNameTranslation = filterOutput.customLabel
        ? filterOutput.customLabel
        : fieldName
        ? // i18next-extract-mark-context-next-line ["", "color", "brand", "gender", "certification", "designer", "theme", "retail", "sleeve", "material", "feature", "activity", "range", "category", "outlet", "care", "neckline", "fit"]
          t('filter.filter', { context: fieldName })
        : undefined;

    const filterOutputFilter = filterOutput.filter?.[0];

    return filterOutputFilter ? (
        <CheckRow
            key={filterOutput.fieldName}
            isChecked={isChecked(filterOutputFilter, filter)}
            onToggle={() => onClick(filterOutputFilter)}
            text={fieldNameTranslation || fieldName}
            noBorder
        ></CheckRow>
    ) : null;
};

type ShowAllProps = {
    onToggle: () => void;
    toggled: boolean;
    invert?: boolean;
};
const ShowAllRow: React.FC<ShowAllProps> = ({ onToggle, toggled, invert }) => {
    const { t } = useTranslation();

    return (
        <FilterRow onClick={onToggle} className="pointer f1-400 flex-grow-1">
            <Flex justify="end" align="center">
                <ShowAllText light small>
                    {toggled ? t('show_less') : t('show_all', 'Show all')}
                </ShowAllText>
                <IconButton
                    className=""
                    bgColor={invert ? 'fillWhite' : 'fill'}
                    icon={toggled ? faAngleUp : faAngleDown}
                ></IconButton>
            </Flex>
        </FilterRow>
    );
};

type SelectRowProps = {
    text: string;
    url: string;
    invert?: boolean;
};
const SelectRow: React.FC<SelectRowProps> = ({ text, url, invert }) => (
    <FilterElement>
        <Link noStyle state={{ noscroll: true }} to={{ pathname: url }}>
            <Flex justify="between" align="center">
                <Text large light>
                    {text}
                </Text>
                <IconButton icon={faArrowRight} bgColor={invert ? 'fill' : 'fillWhite'}></IconButton>
            </Flex>
        </Link>
    </FilterElement>
);

type CheckRowProps = {
    text: string;
    isChecked: boolean;
    onToggle: () => void;
    invert?: boolean;
    noBorder?: boolean;
};
const CheckRow: React.FC<CheckRowProps> = ({ noBorder, text, isChecked, onToggle, invert }) => (
    <FilterElement noBorder={noBorder ?? false} onClick={onToggle} className="pointer">
        <Flex justify="between" align="center">
            <Text large light>
                {text}
            </Text>
            <Checkbox name={text} defaultChecked={isChecked} bgColor={invert ? 'fill' : 'fillWhite'} />
        </Flex>
    </FilterElement>
);

function isChecked(filter: any, f?: any[]) {
    return f?.some((f) => f.key === filter.key) || false;
}

const StyledDropDownIconButton = styled(IconButton)`
    background: ${({ theme }) => theme.colors.fill};
`;
const StickyBox = styled(ReactStickyBox)`
    box-shadow: none;
    padding: 0.25rem 1rem 0.25rem 2rem;
    margin: 0 0.5rem 0 -1rem;
`;

const ButtonContainer = styled(Flex)`
    padding: 10px;
    background-color: ${({ theme }) => theme.colors.fillWhite};
`;

const ShowAllText = styled(Text)`
    display: none;
    ${({ theme }) => theme.media.lg} {
        display: block;
    }
`;

// const CurrentCategory = styled.h2`
//     font-size: 32px;
// `;

const FilterName = styled(Text)`
    font-size: 14px;
    line-height: 2.5rem;
    ${({ theme }) => theme.media.lg} {
        font-size: 12px;
        line-height: initial;
    }
`;

const CollapsedWrapper = styled(Flex)`
    background-color: ${({ theme }) => theme.colors.fill};
`;

const FilterWrapper = styled.div`
    flex-shrink: 0;
    // width: calc(280px + 2em);
`;

const InnerWrapper = styled.div`
    width: 250px;
`;

const FilterList = styled.div``;

const FilterRow = styled.div`
    padding: 5px 0;
`;

const FilterElement = styled(FilterRow)<{ noBorder?: boolean }>`
    ${({ noBorder, theme }) => !noBorder && `border-bottom: 1px solid ${theme.colors.border};`}

    a:hover {
        text-decoration: underline;
    }
`;

const WebColors = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 10px 0;

    > * {
        margin: 0 5px 5px 0;
    }
`;

type TransitionProps = {
    state?: TransitionStatus;
};
const AnimatedWrapper = styled(FilterWrapper)<TransitionProps>`
    overflow: hidden;
    margin-left: -1em;
    ${({ state }) => {
        switch (state) {
            case 'entering':
            case 'entered':
                return `
        `;
            case 'exiting':
                return `
          max-width: 0;
        `;
            case 'exited':
                return `
          max-width: 0;
        `;
        }
    }};
    transition: max-width 150ms ${({ theme }) => theme.transitions.bez};
`;

// const FilterContent = styled.div`
//     max-width: 280px;
//     .col-12 {
//         padding: 0 !important;
//     }
// `;

export default ProductFilter;
