import Button from 'components/ui/Button';
import Spinner from 'components/ui/Spinner';
import React from 'react';
import styled, { ThemeColors } from 'styled-components/macro';

export type CheckoutButtonProps = {
    onSubmit?: (event: any) => void;
    bgColor?: keyof ThemeColors;
    color?: keyof ThemeColors;
    text: string;
    loading: boolean;
    disabled?: boolean;
    borderRadius?: string;
};

const CheckoutButton: React.FC<CheckoutButtonProps> = ({
    onSubmit,
    text,
    bgColor,
    color,
    borderRadius,
    loading,
    disabled,
}) => {
    return (
        <StyledCheckoutButton
            onClick={onSubmit}
            className="mt-4 f1-500"
            fullwidth
            pill
            attention
            bgColor={bgColor ? bgColor : 'buy'}
            color={color ? color : 'textWhite'}
            disabled={disabled || loading}
            borderRadius={borderRadius}
        >
            {loading && <Spinner className="me-1" />} {text}
        </StyledCheckoutButton>
    );
};

export default CheckoutButton;

const StyledCheckoutButton = styled(Button)<{ borderRadius?: string }>`
    font-size: 14px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : '')};
`;
