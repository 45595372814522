import React from 'react';
import Button from 'components/ui/Button';
import Flex from 'components/ui/Flex';
import Text from 'components/ui/Text';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

const DismissToast: React.FC<{ type: string; text: string }> = ({ type, text }) => {
    const { t } = useTranslation();

    const onDismiss = () => {
        localStorage.setItem(`toast_${type}_dismissed`, Date.now().toString());
    };

    return (
        <Toast column justify="between" align="start" className="p-3">
            <Text>{text}</Text>
            <Flex className="w-100 mt-3">
                <StyledButton className="me-3" onClick={() => onDismiss()}>
                    {t('toastDismissYesOption')}
                </StyledButton>
                <StyledButton>{t('toastDismissNoOption')}</StyledButton>
            </Flex>
        </Toast>
    );
};

export default DismissToast;

const Toast = styled(Flex)`
    max-width: 310px;
    min-height: 100px;
    width: 100%;
`;

const StyledButton = styled(Button)`
    flex: 1;
`;
