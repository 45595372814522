import { DefaultTheme } from 'styled-components/macro';

export function lightnessClass(theme: DefaultTheme) {
    if (theme.lightness === 5) return '';
    if (theme.lightness > 5) return `lighten-${theme.lightness - 5}`;

    const dark = [4, 3, 2, 1];
    if (theme.lightness < 5) return `darken-${dark[theme.lightness - 1]}`;
}

export function darknessClass(theme: DefaultTheme) {
    if (theme.lightness === 5) return `darken-2`;
    if (theme.lightness > 5) return `lighten-4 text-dark`;
    if (theme.lightness <= 5) return `darken-4`;
}

export function isLight(theme: DefaultTheme) {
    return theme.lightness > 5;
}

export function isDark(theme: DefaultTheme) {
    return theme.lightness <= 5;
}
export function textClass(theme: DefaultTheme) {
    return theme.lightness > 5 ? 'text-dark' : 'text-light';
}
