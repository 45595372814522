import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import styled, { ThemeColors, ThemeContext } from 'styled-components/macro';

import { shade } from 'polished';

export interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    icon: IconDefinition;
    iconContent?: React.ReactNode;
    color?: keyof ThemeColors;
    bgColor?: keyof ThemeColors;
    large?: boolean;
    small?: boolean;
    square?: boolean;
    shadow?: boolean;
    iconRight?: boolean;
    disableFont?: boolean;
    fontSize?: string;
    flexJustify?: string;
}

type ButtonProps = {
    bgColor?: keyof ThemeColors;
    large?: boolean;
    square?: boolean;
    small?: boolean;
    hasText?: boolean;
    shadow?: boolean;
    color?: keyof ThemeColors;
    flexJustify?: string;
};

const Button = styled.button<ButtonProps>`
  display: flex;
  flex-shrink: 0;
  justify-content: ${({ flexJustify }) => (flexJustify != null ? flexJustify : 'center')};
  align-items: center;
  ${({ large, small, hasText }) => hasText !== true && `width: ${large ? 40 : small ? 24 : 34}px;`}
  height: ${({ large, small }) => (large ? 40 : small ? 24 : 34)}px;
  border-radius: ${({ square, large }) => (square ? 2 : large ? 20 : 17)}px;
  background-color: ${({ bgColor, theme, disabled }) =>
      disabled ? theme.colors.disabled : bgColor ? theme.colors[bgColor] : theme.colors.fill};
  ${({ theme, shadow }) => (shadow ? theme.shadows.soft : '')};
  text-align: center;
  border: 0;
  font-size: ${({ hasText, theme }) => (hasText ? theme.typography.fontSize : '16px')};
  padding: ${({ hasText }) => (hasText ? '0 1em' : 0)};
  white-space: nowrap;
  color: ${({ theme, color, disabled }) =>
      disabled ? theme.colors.textDisabled : color ? theme.colors[color] : theme.colors.text};

  ${({ disabled, bgColor, theme }) =>
      !disabled
          ? `
    :hover {
      background-color: ${shade(0.03, theme.colors[!bgColor || bgColor === 'transparent' ? 'fill' : bgColor])}
    }
  `
          : ''}

  transition: background-color 0.2s linear;
`;

const IconButton: React.FC<IButtonProps> = ({
    icon,
    color,
    bgColor,
    large,
    square,
    shadow,
    children,
    disabled,
    iconRight,
    disableFont,
    fontSize,
    iconContent,
    flexJustify,
    ...rest
}) => {
    const themeContext = useContext(ThemeContext);

    const textEl = children ? (
        <Text
            fontSize={fontSize}
            className={`${!disableFont ? 'f1-500' : ''} me-2`}
            large={large}
            noPadding={iconRight}
        >
            {children}
        </Text>
    ) : (
        <></>
    );

    const iconEl = iconContent ? (
        iconContent
    ) : (
        <FontAwesomeIcon
            size={'1x'}
            icon={icon}
            color={
                disabled
                    ? themeContext.colors.textDisabled
                    : color
                    ? themeContext.colors[color]
                    : themeContext.colors.textLight
            }
        />
    );

    const components = [textEl, iconEl];

    const compStructure = iconRight ? (
        <>
            {components[0]}
            {components[1]}
        </>
    ) : (
        <>
            {components[1]}
            {components[0]}
        </>
    );

    return (
        <Button
            color={color}
            large={large}
            bgColor={bgColor}
            square={square}
            hasText={!!children}
            shadow={shadow}
            disabled={disabled}
            flexJustify={flexJustify}
            {...rest}
        >
            {compStructure}
        </Button>
    );
};

export default IconButton;

type TextProps = {
    large?: boolean;
    fontSize?: string;
    noPadding?: boolean;
};
const Text = styled.span<TextProps>`
    padding-left: ${({ large }) => (large ? 12 : 10)}px;
    font-size: ${({ theme, fontSize }) => (fontSize ? fontSize : theme.typography.fontSize)};
    ${({ noPadding }) => (noPadding ? 'padding-left:0;px;' : '')}
`;
