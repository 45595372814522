import Flex from 'components/ui/Flex';
import ThemeButton from 'components/ui/ThemeButton';
import { useTranslation } from 'react-i18next';
import ShopLoadLayout from './ShopLoadLayout';

const ShopMustLogin = ({ login, register }: { login: () => void; register: () => void }) => {
    const { t } = useTranslation();
    return (
        <ShopLoadLayout>
            {t('shop_login_required')}

            <Flex className="text-center">
                <ThemeButton className={`m-3 mt-5`} onClick={login} text={t('signin')} />
                <ThemeButton className={`m-3 mt-5`} onClick={register} text={t('register')} />
            </Flex>
        </ShopLoadLayout>
    );
};
export default ShopMustLogin;
