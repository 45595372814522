import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PriceInformation, SkuPriceValue } from 'microshop-api';
import services from 'services';
import { AppDispatch, RootState } from 'store';

export type PriceType = 'retail' | 'retailAndCustomer' | 'retailAsCustomerPrice' | 'customer' | 'none';

export type ThunkConf = {
    dispatch: AppDispatch;
    state: RootState;
};

const PRICE_CURRENCY_ACTION = 'price/currency';
export const customerPrice = createAsyncThunk<void, PriceInformation, ThunkConf>(
    'price/customerPrice',
    async (skuPrice, { getState, dispatch }) => {
        const customerPrices = getState().price.customerPrices;
        const price = skuPrice.sku && customerPrices[skuPrice.sku];
        if (!price) {
            dispatch(fetchCustomerPrice(skuPrice));
        }
    },
);

export const fetchCustomerPrice = createAsyncThunk<any[], PriceInformation, ThunkConf>(
    'price/fetchCustomerPrice',
    async (skuPrice) => {
        const response = await services.assortment.getPrice([{ sku: skuPrice.sku, inboxItems: skuPrice.inboxItems }]);
        return response;
    },
);

// export const currencySelected = createAsyncThunk<string, string, ThunkConf>(
//     PRICE_CURRENCY_ACTION + '/selected',
//     async (currency, { getState, dispatch }) => {
//         persistCurrency(currency);
//         commerce.setCurrency(currency);
//         const product = getState().product.product;
//         const variation = getState().product.variation;
//         if (product && product.productNumber) {
//             dispatch(
//                 getProduct({
//                     pnrOrSlug: product?.productNumber,
//                     featuredColor: variation?.colorCode ?? undefined,
//                     forceReload: true,
//                 }),
//             );
//         }
//         return currency;
//     },
// );

// export const currencyDefaultSet = createAsyncThunk<
//     string | undefined,
//     { lang: Language; currencies: string[] },
//     ThunkConf
// >(PRICE_CURRENCY_ACTION + '/setdefault', async (input) => {
//     const defaultCurrency = getSelectedCurrency(input.lang, input.currencies);
//     commerce.setCurrency(defaultCurrency);
//     return defaultCurrency;
// });

export type CustomerPrices = {
    [key: string]: SkuPriceValue | null;
};

export type PriceFormat = {
    prefix?: string;
    prefixBox?: string;
    prefixRetail?: string;
    prefixSales?: string;
};

interface PriceState {
    customerPrices: CustomerPrices;
    customerPricesPending: { [key: string]: boolean };
    currencies: string[];
    currency?: string;
    priceFormat?: PriceFormat;

    priceType: PriceType;
}
export const initialState: PriceState = {
    customerPrices: {},
    customerPricesPending: {},
    currencies: [],
    priceType: 'customer',
};

const priceSlice = createSlice({
    name: 'price',
    initialState,
    reducers: {
        currenciesSet(state, action: PayloadAction<string[]>) {
            state.currencies = action.payload;
        },
        priceFormatSet(state, action: PayloadAction<PriceFormat>) {
            state.priceFormat = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCustomerPrice.pending, (state, action) => {
            const sku = action.meta.arg.sku;

            if (sku) {
                state.customerPricesPending[sku] = true;
            }
        });
        builder.addCase(fetchCustomerPrice.fulfilled, (state, action) => {
            const sku = action.meta.arg.sku;

            if (sku) {
                const priceArray = action.payload;
                const price = priceArray.length ? priceArray[0] : null;

                state.customerPrices[sku] = price;
                state.customerPricesPending[sku] = false;
            }
        });
        builder.addCase(fetchCustomerPrice.rejected, (state, action) => {
            const sku = action.meta.arg.sku;

            if (sku) {
                state.customerPricesPending[sku] = false;
            }
        });
        // builder.addMatcher(isFulfilledAction(USER_INFO_ACTION), (state) => {
        //   state.customerPrices = {};
        // });
        // builder.addMatcher(isFulfilledAction(USER_PRICE_ACTION), (state, action) => {
        //   state.customerPrices = {};
        // });

        // builder.addMatcher(isFulfilledAction(PRICE_CURRENCY_ACTION), (state, action) => {
        //   state.currency = action.payload;
        // });
    },
});

// API
export { PRICE_CURRENCY_ACTION };
export const { currenciesSet, priceFormatSet } = priceSlice.actions;
export default priceSlice.reducer;

// const getDefaultCurrency = (language: Language | undefined) => {
//     switch (language?.name) {
//         case 'sv':
//             return 'SEK';
//         case 'no':
//             return 'NOK';
//         case 'da':
//             return 'DKK';
//         default:
//             return 'EUR';
//     }
// };

// const getSelectedCurrency = (language: Language | undefined, currencies: string[]) => {
//     const prefferedCurrency = localStorage.getItem('currency') || getDefaultCurrency(language);
//     const hasPrefferedCurrency = currencies.find((c) => c === prefferedCurrency);
//     return hasPrefferedCurrency ? prefferedCurrency : currencies.length > 0 ? currencies[0] : undefined;
// };

// const persistCurrency = (selectedCurrency: string) => localStorage.setItem('currency', selectedCurrency);

// * Price Selectors
const selectShoudLazyLoad = (state: RootState) => state.shop.shop?.isB2BShop;
const selectCustomerPrices = (state: RootState) => state.price.customerPrices;
const selectCustomerPricesPending = (state: RootState) => state.price.customerPricesPending;
const selectPriceInfo = (_: RootState, price: PriceInformation) => price;

export const selectCustomerPrice = createSelector(
    selectCustomerPrices,
    selectPriceInfo,
    selectShoudLazyLoad,
    (prices, priceInfo, shouldLazyLoad) => {
        if (!priceInfo) return null;

        const price = !shouldLazyLoad ? priceInfo.customer : priceInfo.sku ? prices[priceInfo.sku] : null;

        if (!price) return null;

        return price;
    },
);

export const selectCustomerPricePending = createSelector(
    selectCustomerPricesPending,
    selectPriceInfo,
    selectShoudLazyLoad,
    (pending, priceInfo, shouldLazyLoad) => {
        if (!priceInfo?.sku || !shouldLazyLoad) return false;
        const hasTried = pending.hasOwnProperty(priceInfo.sku);
        if (hasTried) return pending[priceInfo.sku];
        return true;
    },
);

export const selectPriceValue = createSelector(selectPriceInfo, (priceInfo) => {
    const isBox = !!(priceInfo.inboxItems && priceInfo.inboxItems > 1);
    return isBox && priceInfo.box ? priceInfo.box : priceInfo;
});

// export const selectIsBox = createSelector(
//     selectPriceInfo,
//     (priceInfo) => priceInfo.inboxItems && priceInfo.inboxItems > 1,
// );
// export const selectRegularPrice = createSelector(
//     selectPriceInfo,
//     (priceInfo) => priceInfo.inboxItems && priceInfo.inboxItems > 1,
// );

// export const selectIsSale = createSelector(selectCustomerPrice, selectPriceInfo, (customer, priceInfo) => {
//     const isBox = !!priceInfo.inboxItems && priceInfo.inboxItems > 1;
//     const { regular } = isBox && priceInfo.box ? priceInfo.box : priceInfo;

//     return regular?.num && customer?.num && regular.num > customer?.num;
// });

// const selectPriceViewSetting = (state: RootState) => state.user.priceViewSetting;
// const selectIsAnonymous = (state: RootState) => state.authorization.anonymous;

// export const selectPriceType = createSelector(
//     selectPriceViewSetting,
//     selectCommerceType,
//     selectIsAnonymous,
//     (priceSetting, commerceType, isAnon): PriceType => {
//         const isB2B = commerceType === CommerceType.B2B;
//         const isB2BResellerorB2c = commerceType === CommerceType.B2BResellers || commerceType === CommerceType.B2C;
//         const isNone = commerceType === CommerceType.None;
//         if (isB2BResellerorB2c) return 'retailAsCustomerPrice';
//         if (priceSetting === PriceViewSetting.None) return 'none';
//         if (priceSetting === PriceViewSetting.Retail || isNone || (isB2B && isAnon)) return 'retail';

//         if (isB2B && priceSetting === PriceViewSetting.Customer) return 'customer';
//         if (priceSetting === PriceViewSetting.RetailAndCustomer) return 'retailAndCustomer';

//         return 'none';
//     },
// );

// const selectUserOrderSettings = (state: RootState) => state.user.userIdentity?.orderOptions;
// export const selectHideCustomerPrices = createSelector(
//     selectUserOrderSettings,
//     (orderSettings) => orderSettings?.priceEnabled === false,
// );
// export const selectShowCustomerPrice = createSelector(
//     selectPriceType,
//     selectHideCustomerPrices,
//     (priceType, hidePrices) =>
//         !hidePrices &&
//         (priceType === 'customer' || priceType === 'retailAndCustomer' || priceType === 'retailAsCustomerPrice'),
// );
// export const selectShowRetailPrice = createSelector(
//     selectPriceType,
//     (priceType) => priceType === 'retail' || priceType === 'retailAndCustomer',
// );
// export const selectShouldLoadCustomerPrice = createSelector(
//     selectPriceType,
//     selectHideCustomerPrices,
//     selectCustomerPrice,
//     selectPriceInfo,

//     (priceType, hidePrice, customerPrice, price) => {
//         if (!price.sku) return false;
//         return !hidePrice && (priceType === 'customer' || priceType === 'retailAndCustomer') && !customerPrice;
//     },
// );
