import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import Flex from 'components/ui/Flex';
import IconButton from 'components/ui/IconButton';
import Text from 'components/ui/Text';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Row } from 'reactstrap';

const Empty = () => {
    const { t } = useTranslation();
    return (
        <Row>
            <Flex column className="align-items-center justify-content-center">
                <h2 className="mb-4">{t('cart_empty')}</h2>
                <Link className="link-dark" to="/">
                    <Flex className="justify-content-center align-items-center">
                        <Text large light className="me-3">
                            {t('confirm_continue')}
                        </Text>
                        <IconButton icon={faArrowRight} bgColor="fillWhite" />
                    </Flex>
                </Link>
            </Flex>
        </Row>
    );
};
export default Empty;
