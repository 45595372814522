import styled, { ThemeColors } from 'styled-components/macro';
import Flex from './Flex';

type SpinnerProps = {
    className?: string;
    color?: keyof ThemeColors;
    useFullHeight?: boolean;
    center?: boolean;
};
const Spinner = ({ className, color, useFullHeight = true, center }: SpinnerProps) => (
    <Flex className={useFullHeight ? 'h-100' : undefined} align="center" justify={center ? 'center' : 'start'}>
        <StyledSpinner color={color} className={className || undefined} />
    </Flex>
);

const StyledSpinner = styled.span<SpinnerProps>`
    /* https://cssloaders.github.io/ */
    width: 1.2em;
    height: 1.2em;
    border-radius: 50%;
    position: relative;
    animation: rotate 1s linear infinite;

    &::before,
    &::after {
        content: '';
        box-sizing: border-box;
        position: absolute;
        inset: 0px;
        border-radius: 50%;
        border: 1px solid #b1b1b1;
        animation: prixClipFix 3s alternate infinite;
    }
    &::after {
        inset: 4px;
        border-color: ${({ theme, color }) =>
            theme.colors ? (color ? theme.colors[color] : theme.colors.attention) : '#ff3d00'};
        animation: prixClipFix 3.5s alternate infinite, rotate 0.5s linear infinite reverse;
    }

    @keyframes rotate {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    @keyframes prixClipFix {
        0% {
            clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
        }
        25% {
            clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
        }
        50% {
            clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
        }
        75% {
            clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
        }
        100% {
            clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
        }
    }
`;

export default Spinner;
