import { CustomerApi, Identity } from 'microshop-api';
import { config } from './api';

const customerApi = new CustomerApi(config);

const customer = {
    customerGet: (): Promise<Identity> => customerApi.customerGet(),
};

export { customer };
