import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import { CSSProperties, ThemeColors, ThemeContext } from 'styled-components/macro';

type IconProps = {
    icon: IconDefinition;
    iconColor?: keyof ThemeColors;
    small?: boolean;
    size?: string;
    style?: CSSProperties;
    className?: string;
};

const getSize = (small?: boolean, size?: any) => {
    if (size) return size;

    return small ? '1x' : '2x';
};

const IconHeader: React.FC<IconProps> = ({ icon, iconColor, small, size, style, ...props }) => {
    const themeContext = useContext(ThemeContext);

    return (
        <FontAwesomeIcon
            style={style}
            size={getSize(small, size)}
            icon={icon}
            color={iconColor ? themeContext.colors[iconColor] : themeContext.colors.mainText}
            {...props}
        />
    );
};

export default IconHeader;
