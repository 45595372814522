"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShopProductImageToJSON = exports.ShopProductImageFromJSONTyped = exports.ShopProductImageFromJSON = exports.instanceOfShopProductImage = void 0;
var runtime_1 = require("../runtime");
var ImageExtension_1 = require("./ImageExtension");
/**
 * Check if a given object implements the ShopProductImage interface.
 */
function instanceOfShopProductImage(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfShopProductImage = instanceOfShopProductImage;
function ShopProductImageFromJSON(json) {
    return ShopProductImageFromJSONTyped(json, false);
}
exports.ShopProductImageFromJSON = ShopProductImageFromJSON;
function ShopProductImageFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'angle': !(0, runtime_1.exists)(json, 'angle') ? undefined : json['angle'],
        'type': !(0, runtime_1.exists)(json, 'type') ? undefined : json['type'],
        'imageId': !(0, runtime_1.exists)(json, 'imageId') ? undefined : json['imageId'],
        'fileName': !(0, runtime_1.exists)(json, 'fileName') ? undefined : json['fileName'],
        'ext': !(0, runtime_1.exists)(json, 'ext') ? undefined : (0, ImageExtension_1.ImageExtensionFromJSON)(json['ext']),
        'customUrl': !(0, runtime_1.exists)(json, 'customUrl') ? undefined : json['customUrl'],
        'collectionId': !(0, runtime_1.exists)(json, 'collectionId') ? undefined : json['collectionId'],
        'split': !(0, runtime_1.exists)(json, 'split') ? undefined : json['split'],
    };
}
exports.ShopProductImageFromJSONTyped = ShopProductImageFromJSONTyped;
function ShopProductImageToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'angle': value.angle,
        'type': value.type,
        'imageId': value.imageId,
        'fileName': value.fileName,
        'ext': (0, ImageExtension_1.ImageExtensionToJSON)(value.ext),
        'customUrl': value.customUrl,
        'collectionId': value.collectionId,
        'split': value.split,
    };
}
exports.ShopProductImageToJSON = ShopProductImageToJSON;
