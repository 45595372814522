"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShopVatModeToJSON = exports.ShopVatModeFromJSONTyped = exports.ShopVatModeFromJSON = exports.ShopVatMode = void 0;
/**
 *
 * @export
 */
exports.ShopVatMode = {
    None: 0,
    Included: 1,
    Excluded: 2
};
function ShopVatModeFromJSON(json) {
    return ShopVatModeFromJSONTyped(json, false);
}
exports.ShopVatModeFromJSON = ShopVatModeFromJSON;
function ShopVatModeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.ShopVatModeFromJSONTyped = ShopVatModeFromJSONTyped;
function ShopVatModeToJSON(value) {
    return value;
}
exports.ShopVatModeToJSON = ShopVatModeToJSON;
