"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.StockTypeToJSON = exports.StockTypeFromJSONTyped = exports.StockTypeFromJSON = exports.StockType = void 0;
/**
 *
 * @export
 */
exports.StockType = {
    Local: 0,
    Region: 1,
    Global: 2,
    Custom: 3
};
function StockTypeFromJSON(json) {
    return StockTypeFromJSONTyped(json, false);
}
exports.StockTypeFromJSON = StockTypeFromJSON;
function StockTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.StockTypeFromJSONTyped = StockTypeFromJSONTyped;
function StockTypeToJSON(value) {
    return value;
}
exports.StockTypeToJSON = StockTypeToJSON;
