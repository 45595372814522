"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CommDateToJSON = exports.CommDateFromJSONTyped = exports.CommDateFromJSON = exports.instanceOfCommDate = void 0;
var runtime_1 = require("../runtime");
/**
 * Check if a given object implements the CommDate interface.
 */
function instanceOfCommDate(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfCommDate = instanceOfCommDate;
function CommDateFromJSON(json) {
    return CommDateFromJSONTyped(json, false);
}
exports.CommDateFromJSON = CommDateFromJSON;
function CommDateFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'utc': !(0, runtime_1.exists)(json, 'utc') ? undefined : (new Date(json['utc'])),
        'str': !(0, runtime_1.exists)(json, 'str') ? undefined : json['str'],
    };
}
exports.CommDateFromJSONTyped = CommDateFromJSONTyped;
function CommDateToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'utc': value.utc === undefined ? undefined : (value.utc.toISOString()),
        'str': value.str,
    };
}
exports.CommDateToJSON = CommDateToJSON;
