import { faBan, faExclamationTriangle, faLayerPlus, faPenAlt, faQuestion } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/ui/Flex';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
    img?: string;
    imgAlt?: string;
    imgBg?: boolean;
    hideImagePlaceholder?: boolean;
    invalidVariation?: boolean;
    invalid?: boolean;
    name?: string;
    variationNumber?: string;
    info: string[];
    colors?: any[] | null | undefined;
    articleType?: string;
    files?: React.ReactNode[] | undefined;
    onEditRefinements?: ((i: number, productNumber?: string, variationNumber?: string) => void) | undefined;
    onRemoveRefinements?: ((index: number) => void) | undefined;
    addonIndex?: number;
    editingComment: boolean;
    comment?: string;
    commentAllowed: boolean;
    setEditingComment?: (editing: boolean) => void;
    orderLines?: any[];
    addonIds?: number[];
    className?: string;
    stockOperation?: any;
    addon?: boolean;
}

const ArticleInfo = (props: Props) => {
    const { t } = useTranslation();

    return (
        <ProductInfoCell className={props.className}>
            <Flex align="center" $wrap>
                {!props.hideImagePlaceholder && (
                    <ImageWrapper className="me-2 p-1" as={props.img ? 'button' : undefined} darkBg={props.imgBg}>
                        {props.img ? (
                            <Image src={props.img} alt={props.imgAlt} />
                        ) : (
                            <ImageIcon justify="center">
                                <FontAwesomeIcon
                                    size="2x"
                                    icon={props.addon ? faLayerPlus : props.invalid ? faBan : faQuestion}
                                />
                            </ImageIcon>
                        )}
                    </ImageWrapper>
                )}
                <ProductInfo column>
                    <span className="f1-500">
                        {props.invalidVariation && (
                            <CellIcon color="red" icon={faExclamationTriangle} className="me-2" />
                        )}
                        {props.name != null && props.name}
                    </span>
                    {props.files?.map((row, i) => (
                        <React.Fragment key={`file${i}`}>{row}</React.Fragment>
                    ))}
                    {props.invalid && (
                        <InfoTextError>{t('cart.errorInvalidSku', 'Invalid: Article does not exist.')}</InfoTextError>
                    )}
                    {typeof props.addonIndex !== 'number' && props.commentAllowed && props.setEditingComment && (
                        <CommentWrapper
                            align="center"
                            className="pointer f3-400"
                            onClick={() => props.setEditingComment?.(true)}
                        >
                            <FontAwesomeIcon size="1x" className="me-1" icon={faPenAlt} />
                            {!props.editingComment && props.comment}
                        </CommentWrapper>
                    )}
                </ProductInfo>
            </Flex>
        </ProductInfoCell>
    );
};

export const getRefinementFiles = (addOn: { files?: any[] | null | undefined }) => {
    return addOn.files?.map((file, fileIndex) => (
        <div key={fileIndex}>
            <FileLink className="f3-400" target="_blank" rel="noreferrer" href={file?.url!}>
                {file?.fileName}
            </FileLink>
        </div>
    ));
};

export default ArticleInfo;

const Cell = styled.div`
    flex-shrink: 0;
`;

const ProductInfoCell = styled(Cell)`
    flex-grow: 2;
    flex-shrink: 2;
`;

const ImageWrapper = styled.div<{ as?: string; darkBg?: boolean }>`
    width: 56px;
    min-width: 56px;

    ${({ as }) =>
        as === 'button' &&
        `
      border: none;
      background-color: transparent;
    `};
    ${({ darkBg, theme }) => darkBg && `background:  ${theme.colors.transparentBg};`}
`;

const Image = styled.img`
    width: 48px;
    max-height: 60px;
    object-fit: contain;
`;

const ImageIcon = styled(Flex)`
    width: 48px;
`;

const ProductInfo = styled(Flex)`
    font-size: 12px;
`;

const CellIcon = styled(FontAwesomeIcon)`
    font-size: 16px;
`;

const InfoText = styled.div`
    color: ${({ theme }) => theme.colors.textLight};
`;

const InfoTextError = styled(InfoText)`
    color: ${({ theme }) => theme.colors.error};
`;

const CommentWrapper = styled(Flex)`
    cursor: pointer;
    min-height: 18px;
    white-space: normal;
    word-break: break-word;
    color: ${({ theme }) => theme.colors.textLight};
`;

const FileLink = styled.a`
    color: ${({ theme }) => theme.colors.textLight};
    text-decoration: none;
`;

const BottomActions = styled(Flex)`
    > *:not(:last-child) {
        margin-right: 10px;
    }
`;
