import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCertificate, faExclamationCircle, faFilePdf } from '@fortawesome/pro-solid-svg-icons';
import styled from 'styled-components/macro';

import useAppSelector from 'hooks/useAppSelector';
import { selectProductAccordionData, selectProductLoading } from 'store/reducers/productSlice';
import { Mark, Document } from 'microshop-api';
import getImage, { ImageType } from 'utils/getImage';
import { Item } from 'components/ui/Accordion';
import Flex from 'components/ui/Flex';
import Link from 'components/ui/Link';
import Text from 'components/ui/Text';
import Accordion from 'components/ui/Accordion';

interface IProductAccordionData {
    attributes?: {
        [key: string]: string[];
    } | null;
    documents?: Document[] | null;
    dimensions?: {
        [key: string]: number;
    } | null;
    careInstructions?: Mark[];
    certifications?: Mark[];
    certificationDescription?: string;
}

const ProductDetailPageAccordion = () => {
    const loading = useAppSelector(selectProductLoading);
    const data: IProductAccordionData = useAppSelector(selectProductAccordionData);
    const { t } = useTranslation();

    const productAttributes = getProductInformation(data?.attributes);

    const hasProductAttributes = productAttributes && !!productAttributes.length;

    const items: Item[] = [
        ...(hasProductAttributes
            ? [
                  {
                      header: t('product_information', 'Product information'),
                      content: <Attributes data={productAttributes} />,
                      open: true,
                  },
              ]
            : []),
        ...(data?.careInstructions?.length
            ? [
                  {
                      header: t('care_instructions', 'Care instructions'),
                      content: <CareInstructions data={data.careInstructions} />,
                  },
              ]
            : []),
        ...(data?.certifications?.length || data?.certificationDescription
            ? [
                  {
                      header: t('certificates', 'Certificates'),
                      content: (
                          <Certificates
                              data={data?.certifications}
                              certificationDescription={data?.certificationDescription}
                          />
                      ),
                  },
              ]
            : []),
        ...(data?.documents?.length
            ? [
                  {
                      header: t('document', 'Document'),
                      content: <Documents data={data.documents} />,
                  },
              ]
            : []),
    ];

    return (
        <>
            {loading ? (
                <Skeleton height={400}></Skeleton>
            ) : (
                items?.length > 0 && (
                    <>
                        <Accordion items={items} />
                    </>
                )
            )}
        </>
    );
};

export default ProductDetailPageAccordion;

interface AttributeProps {
    data?: ProductInformation;
}

const Attributes = ({ data }: AttributeProps) => {
    const { t } = useTranslation();
    return (
        <>
            {data?.map(({ key, value }) => {
                return (
                    value && (
                        <div key={key}>
                            <Text className="d-inline f3-700 mr-1" large>
                                {t(key)}:{' '}
                            </Text>
                            <Text className="d-inline" large>
                                {value}
                            </Text>
                        </div>
                    )
                );
            })}
        </>
    );
};

interface CareInstructionsProps {
    data?: Mark[];
}

const CareInstructions = ({ data }: CareInstructionsProps) => {
    return (
        <>
            <IconList>
                {data?.map((ci, i) => (
                    <IconListItem className="m-2" key={i}>
                        <Flex align="center" column>
                            {ci.image?.fileName ? (
                                <IconPicture src={getImage(ci.image!, ImageType.Preview)} alt={ci.text!} />
                            ) : (
                                <IconPicturePlaceholder align="center" justify="center">
                                    <FontAwesomeIcon icon={faExclamationCircle} size="2x"></FontAwesomeIcon>
                                </IconPicturePlaceholder>
                            )}
                            <Text center>{ci?.text}</Text>
                        </Flex>
                    </IconListItem>
                ))}
            </IconList>
        </>
    );
};

interface CertificatesProps {
    data?: Mark[];
    certificationDescription?: string | null;
}

const Certificates = ({ data, certificationDescription }: CertificatesProps) => {
    return (
        <>
            {certificationDescription && <Text center>{certificationDescription}</Text>}

            <IconList>
                {data?.map((c, i) => (
                    <IconListItem key={i}>
                        <Flex align="center" column>
                            {c.image?.fileName ? (
                                <CertificateIcon src={getImage(c.image!, ImageType.Preview)} alt={c.text!} />
                            ) : (
                                <IconPicturePlaceholder align="center" justify="center">
                                    <FontAwesomeIcon icon={faCertificate} size="2x"></FontAwesomeIcon>
                                </IconPicturePlaceholder>
                            )}
                            <Text center>{c?.text!}</Text>
                        </Flex>
                    </IconListItem>
                ))}
            </IconList>
        </>
    );
};

interface DocumentsProps {
    data?: Document[] | null;
}

const Documents = ({ data }: DocumentsProps) => {
    return (
        <>
            {data?.map((d, i) => (
                <IconListItem className="m-2" key={i}>
                    <FontAwesomeIcon icon={faFilePdf} size="2x" className="pl-2 pr-2"></FontAwesomeIcon>
                    {d.url ? (
                        <>
                            <StyledLink className="f3-700 mb-3" to={d?.url} target="_blank">
                                {d?.name}
                            </StyledLink>
                        </>
                    ) : null}
                </IconListItem>
            ))}
        </>
    );
};

const IconList = styled.ul`
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
`;
const IconListItem = styled.li`
    flex-basis: 136px;
`;
const IconPicture = styled.img`
    width: 60px;
    height: 60px;
    object-fit: contain;
`;
const IconPicturePlaceholder = styled(Flex)`
    width: 60px;
    height: 60px;
    color: ${({ theme }) => theme.colors.text};
`;
const CertificateIcon = styled(IconPicture)`
    padding: 0.5rem;
`;
const StyledLink = styled(Link)`
    font-size: 16px;
    text-decoration: none;
`;
const List = styled.ul`
    list-style-type: disc;
    padding: 0;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
`;

const ListItem = styled.li`
    color: ${({ theme }) => theme.colors.textLight};
`;
const SizeGuideThumbnail = styled.img`
    max-height: 200px;
    max-width: 200px;
    object-fit: contain;
`;

const SizeGuideItem = styled(Flex)`
    margin-right: 1rem;
    text-align: center;
`;

const PRODUCT_INFORMATION_KEYS = [
    'fabrics',
    'gender',
    'measure',
    'weight',
    'width',
    'country',
    'height',
    'diameter',
    'packaging',
    'designer',
    'presentationDate',
    'limitedEdition',
    'printCode',
    'colorComment',
    'techniqueComment',
    'pockets',
    'sleeve',
    'hoodDetails',
    'closure',
    'neckline',
    'dimensions',
    'capacity',
    'volume',
] as const;

type ProductInformationKey = typeof PRODUCT_INFORMATION_KEYS[number];

type ProductInformation = Array<{ key: ProductInformationKey; value: string }>;

function getProductInformation(attributes?: { [key: string]: string[] } | null): ProductInformation | undefined {
    if (!attributes) return;

    return Object.entries(attributes).reduce((acc, [key, value]) => {
        if (isProductInformationKey(key)) {
            return [...acc, { key, value: value.join(', ') }];
        }
        return acc;
    }, [] as ProductInformation);
}

export function isProductInformationKey(key: string): key is ProductInformationKey {
    return PRODUCT_INFORMATION_KEYS.includes(key as ProductInformationKey);
}
