"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PriceInformationToJSON = exports.PriceInformationFromJSONTyped = exports.PriceInformationFromJSON = exports.instanceOfPriceInformation = void 0;
var runtime_1 = require("../runtime");
var PriceValue_1 = require("./PriceValue");
var PriceValues_1 = require("./PriceValues");
/**
 * Check if a given object implements the PriceInformation interface.
 */
function instanceOfPriceInformation(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfPriceInformation = instanceOfPriceInformation;
function PriceInformationFromJSON(json) {
    return PriceInformationFromJSONTyped(json, false);
}
exports.PriceInformationFromJSON = PriceInformationFromJSON;
function PriceInformationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'customer': !(0, runtime_1.exists)(json, 'customer') ? undefined : (0, PriceValue_1.PriceValueFromJSON)(json['customer']),
        'retail': !(0, runtime_1.exists)(json, 'retail') ? undefined : (0, PriceValue_1.PriceValueFromJSON)(json['retail']),
        'regular': !(0, runtime_1.exists)(json, 'regular') ? undefined : (0, PriceValue_1.PriceValueFromJSON)(json['regular']),
        'special': !(0, runtime_1.exists)(json, 'special') ? undefined : (0, PriceValue_1.PriceValueFromJSON)(json['special']),
        'sku': !(0, runtime_1.exists)(json, 'sku') ? undefined : json['sku'],
        'currency': !(0, runtime_1.exists)(json, 'currency') ? undefined : json['currency'],
        'inboxItems': !(0, runtime_1.exists)(json, 'inboxItems') ? undefined : json['inboxItems'],
        'box': !(0, runtime_1.exists)(json, 'box') ? undefined : (0, PriceValues_1.PriceValuesFromJSON)(json['box']),
    };
}
exports.PriceInformationFromJSONTyped = PriceInformationFromJSONTyped;
function PriceInformationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'customer': (0, PriceValue_1.PriceValueToJSON)(value.customer),
        'retail': (0, PriceValue_1.PriceValueToJSON)(value.retail),
        'regular': (0, PriceValue_1.PriceValueToJSON)(value.regular),
        'special': (0, PriceValue_1.PriceValueToJSON)(value.special),
        'sku': value.sku,
        'currency': value.currency,
        'inboxItems': value.inboxItems,
        'box': (0, PriceValues_1.PriceValuesToJSON)(value.box),
    };
}
exports.PriceInformationToJSON = PriceInformationToJSON;
