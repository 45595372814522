"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CatalogNodeModelToJSON = exports.CatalogNodeModelFromJSONTyped = exports.CatalogNodeModelFromJSON = exports.instanceOfCatalogNodeModel = void 0;
var runtime_1 = require("../runtime");
var ProductImage_1 = require("./ProductImage");
/**
 * Check if a given object implements the CatalogNodeModel interface.
 */
function instanceOfCatalogNodeModel(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfCatalogNodeModel = instanceOfCatalogNodeModel;
function CatalogNodeModelFromJSON(json) {
    return CatalogNodeModelFromJSONTyped(json, false);
}
exports.CatalogNodeModelFromJSON = CatalogNodeModelFromJSON;
function CatalogNodeModelFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': !(0, runtime_1.exists)(json, 'name') ? undefined : json['name'],
        'assortmentSlug': !(0, runtime_1.exists)(json, 'assortmentSlug') ? undefined : json['assortmentSlug'],
        'code': !(0, runtime_1.exists)(json, 'code') ? undefined : json['code'],
        'text': !(0, runtime_1.exists)(json, 'text') ? undefined : json['text'],
        'picture': !(0, runtime_1.exists)(json, 'picture') ? undefined : (0, ProductImage_1.ProductImageFromJSON)(json['picture']),
        'children': !(0, runtime_1.exists)(json, 'children') ? undefined : (json['children'] === null ? null : json['children'].map(CatalogNodeModelFromJSON)),
        'parent': !(0, runtime_1.exists)(json, 'parent') ? undefined : json['parent'],
        'products': !(0, runtime_1.exists)(json, 'products') ? undefined : json['products'],
    };
}
exports.CatalogNodeModelFromJSONTyped = CatalogNodeModelFromJSONTyped;
function CatalogNodeModelToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'assortmentSlug': value.assortmentSlug,
        'code': value.code,
        'text': value.text,
        'picture': (0, ProductImage_1.ProductImageToJSON)(value.picture),
        'children': value.children === undefined ? undefined : (value.children === null ? null : value.children.map(CatalogNodeModelToJSON)),
        'parent': value.parent,
        'products': value.products,
    };
}
exports.CatalogNodeModelToJSON = CatalogNodeModelToJSON;
