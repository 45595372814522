import { faAngleUp } from '@fortawesome/pro-solid-svg-icons';
import { transparentize } from 'polished';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import IconButton from './IconButton';

interface Props {
    children: string | null | undefined | any;
}

const Truncate: React.FC<Props> = ({ children: text }) => {
    const { t } = useTranslation();
    const [isReadMore, setIsReadMore] = useState(true);
    const [showReadMore, setShowReadMore] = useState<false | true | undefined>(undefined);
    const toggleReadMore = () => setIsReadMore((v) => !v);
    const textRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const textHeight = textRef?.current?.getBoundingClientRect().height || 0;
        setShowReadMore(textHeight > 170);
    }, [textRef]);

    if (!text) return null;

    if (showReadMore === false) {
        return text;
    } else {
        const TextElement = showReadMore ? TruncateContainer : 'div';
        return (
            <TextElement ref={textRef}>
                {showReadMore !== undefined && isReadMore ? text.slice(0, 400) : text}
                <TruncateBtnWrapper>
                    <ReadMoreWrapper showFade={isReadMore}>
                        <ToggleButton
                            onClick={toggleReadMore}
                            square
                            iconRight
                            flipArrow={isReadMore}
                            icon={faAngleUp}
                            bgColor="fillMedium"
                        >
                            {
                                // i18next-extract-mark-context-next-line ["", "all", "more", "less"]
                                t('show', { context: isReadMore ? 'more' : 'less' })
                            }
                        </ToggleButton>
                    </ReadMoreWrapper>
                </TruncateBtnWrapper>
            </TextElement>
        );
    }
};

export default Truncate;

const TruncateContainer = styled.div`
    position: relative;
    margin-bottom: 50px;
`;

const TruncateBtnWrapper = styled.div`
    border: none;
    background: none;
    position: absolute;
    bottom: -3.6em;
    width: 100%;
`;

const ToggleButton = styled(IconButton)<{ flipArrow: boolean }>`
    position: absolute;
    bottom: 1em;
    left: -1em;

    > svg {
        transition: transform 0.2s ease;
        transform: ${({ flipArrow }) => (flipArrow ? 'scale(-1)' : 'none')};
    }
`;

const ReadMoreWrapper = styled.span<{ showFade?: boolean }>`
    ${({ theme, showFade }) =>
        showFade &&
        `
    :before {
      position: absolute;
      content: '';
      bottom: 3.5em;
      right: 0;
      left: 0;
      background: linear-gradient(to top, ${theme.colors.fillMedium} 18%, ${transparentize(
            0.3,
            theme.colors.fillMedium,
        )} 90%);
      width: 100%;
      height: 5em;
    `}
`;
