"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CartSummaryToJSON = exports.CartSummaryFromJSONTyped = exports.CartSummaryFromJSON = exports.instanceOfCartSummary = void 0;
var runtime_1 = require("../runtime");
var CartItemProduct_1 = require("./CartItemProduct");
/**
 * Check if a given object implements the CartSummary interface.
 */
function instanceOfCartSummary(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfCartSummary = instanceOfCartSummary;
function CartSummaryFromJSON(json) {
    return CartSummaryFromJSONTyped(json, false);
}
exports.CartSummaryFromJSON = CartSummaryFromJSON;
function CartSummaryFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'currency': !(0, runtime_1.exists)(json, 'currency') ? undefined : json['currency'],
        'total': !(0, runtime_1.exists)(json, 'total') ? undefined : json['total'],
        'shippingFee': !(0, runtime_1.exists)(json, 'shippingFee') ? undefined : json['shippingFee'],
        'fees': !(0, runtime_1.exists)(json, 'fees') ? undefined : json['fees'],
        'discount': !(0, runtime_1.exists)(json, 'discount') ? undefined : json['discount'],
        'vat': !(0, runtime_1.exists)(json, 'vat') ? undefined : json['vat'],
        'totalInclVat': !(0, runtime_1.exists)(json, 'totalInclVat') ? undefined : json['totalInclVat'],
        'totalExVat': !(0, runtime_1.exists)(json, 'totalExVat') ? undefined : json['totalExVat'],
        'items': !(0, runtime_1.exists)(json, 'items') ? undefined : (json['items'] === null ? null : json['items'].map(CartItemProduct_1.CartItemProductFromJSON)),
        'quantity': !(0, runtime_1.exists)(json, 'quantity') ? undefined : json['quantity'],
    };
}
exports.CartSummaryFromJSONTyped = CartSummaryFromJSONTyped;
function CartSummaryToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {};
}
exports.CartSummaryToJSON = CartSummaryToJSON;
