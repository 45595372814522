import useAppSelector from 'hooks/useAppSelector';
import { CartItemProduct, CartItemVariation, ImageExtension, PriceInformation, PriceValue } from 'microshop-api';
import { Fragment } from 'react';
import styled from 'styled-components';
import { QuantityChangedFunction } from './CartItems';
import CartRowAddon from './CartRowAddon';
import CartRowArticle from './CartRowArticle';
import CartRowHeader from './CartRowHeader';
import getImage, { ImageType } from 'utils/getImage';

type CartProductProps = {
    onChangeQuantity: QuantityChangedFunction;
    onRemove: (sku: string) => void;
    onSetComment: (sku: string, comment: string) => void;
    product: CartItemProduct;
    forceSize?: any;
    hideName?: boolean;
};

const CartRowProduct: React.FC<CartProductProps> = ({
    onChangeQuantity,
    onRemove,
    onSetComment,
    forceSize,
    product,
    hideName,
}) => {
    return (
        <Product className="mb-4">
            <CartRowHeader
                name={product.name}
                options={{ size: forceSize, hide: { name: hideName, date: forceSize === 'sm' } }}
                productNumber={product.number}
            />
            {product.variations?.map((v, j) => (
                <Variation
                    onChangeQuantity={onChangeQuantity}
                    onRemove={onRemove}
                    onSetComment={onSetComment}
                    forceSize={forceSize}
                    key={`${v.number}${j}`}
                    product={product}
                    variation={v}
                />
            ))}
        </Product>
    );
};

type VariationProps = {
    onChangeQuantity: QuantityChangedFunction;
    onRemove: (sku: string) => void;
    onSetComment: (sku: string, comment: string) => void;
    product: CartItemProduct;
    variation: CartItemVariation;
    forceSize?: any;
};

const Variation: React.FC<VariationProps> = ({
    onChangeQuantity,
    onRemove,
    onSetComment,
    variation,
    product,
    forceSize,
}) => {
    const isB2B = useAppSelector(({ shop }) => shop?.shop?.isB2BShop) ?? false;

    return (
        <>
            {variation.skus?.map((sku, i) => {
                const collectionId = variation.collectionId;
                const split = variation.split;

                return (
                    <Fragment key={`${sku.sku}-${i}`}>
                        <CartRowArticle
                            forceSize={forceSize}
                            invalidVariation={false}
                            img={getImage(variation?.image, ImageType.Preview, ImageExtension.Jpg)}
                            imgAlt={product?.number ?? ''}
                            name={`${variation.color || ''}${variation.color && sku.size ? ' / ' : ''}${
                                sku.size || ''
                            }`}
                            info={[sku.sku!]}
                            sku={sku}
                            product={product}
                            price={getCustomerPrice(sku.price)?.str ?? ''}
                            priceRegular={getRegularPrice(sku.price)?.str ?? ''}
                            total={sku.skuTotal || ''}
                            onQtyChange={onChangeQuantity}
                            variationNumber={variation.number!}
                            collectionId={collectionId}
                            split={split}
                            onRemoveColor={() => onRemove(sku.sku!)}
                            onSetComment={(comment) => onSetComment(sku.sku!, comment)}
                            hideDateCell={!isB2B}
                        />

                        {sku.addOns?.map((addon, i) => (
                            <CartRowAddon
                                forceSize={forceSize}
                                invalidVariation={false}
                                key={`${addon.id}-${i}`}
                                img={''}
                                imgAlt={''}
                                name={`${addon.productName}`}
                                sku={sku}
                                info={[addon.productNumber!]}
                                addon={addon}
                                price={addon.price}
                                total={addon.total || ''}
                                onQtyChange={onChangeQuantity}
                                variationNumber={variation.number!}
                                collectionId={variation.collectionId}
                                split={variation.split}
                            />
                        ))}
                    </Fragment>
                );
            })}
        </>
    );
};

export default CartRowProduct;

function getCustomerPrice(price?: PriceInformation): PriceValue | undefined {
    if (!price) return;

    const isBox = price.inboxItems && price.inboxItems > 1 && price.box;
    if (isBox) return price.box?.customer;

    return price.customer;
}

function getRegularPrice(price?: PriceInformation): PriceValue | undefined {
    if (!price) return;

    const isBox = price.inboxItems && price.inboxItems > 1 && price.box;
    if (isBox) return price.box?.regular;

    return price.regular;
}

export type RemoveQuantityData = {
    article: string;
    name?: string;
    productNumber: string;
    variationNumber?: string;
    price?: number;
    category?: string;
    currency?: string;
    isVariation?: boolean;
};

// function getProductRemoveData(
//   product: CartItemProduct,
//   variation: CartItemVariation,
//   sku?: CartItemSku,
// ): RemoveQuantityData | null {
//   const article = sku ? sku : variation;
//   const priceValue = getCustomerPrice(article);

//   return {
//     article: 'sku' in article ? article.sku! : 'variationNumber' in article ? article.variationNumber! : '',
//     name: product.number || undefined,
//     productNumber: product.number!,
//     price: priceValue?.num,
//     currency: article.price?.currency || undefined,
//     variationNumber: variation.variationNumber || undefined,
//     isVariation: sku ? false : true,
//   };
// }

const Product = styled.div``;
