import { registerLocale } from 'react-datepicker';

const locales: any = {
    be: import('date-fns/locale/be'),
    da: import('date-fns/locale/da'),
    de: import('date-fns/locale/de'),
    'de-CH': import('date-fns/locale/de'),
    fi: import('date-fns/locale/fi'),
    fr: import('date-fns/locale/fr'),
    'fr-CH': import('date-fns/locale/fr'),
    it: import('date-fns/locale/it'),
    nl: import('date-fns/locale/nl'),
    no: import('date-fns/locale/nn'),
    nb: import('date-fns/locale/nn'),
    nn: import('date-fns/locale/nn'),
    sv: import('date-fns/locale/sv'),
};

export const registerDatePickerLocale = (language: string) => {
    if (locales[language]) {
        locales[language].then(({ default: locale }: { default: Locale }) => {
            if (locale.code) {
                registerLocale(language, locale);
            }
        });
    }
};
