export function isValidPhoneNumber(value?: string | undefined | null) {
  if (!value) return false;
  const phoneRegexp = /^(\+\d{1,2}\s?)?1?-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
  return phoneRegexp.test(value);
}

export function isValidPhoneLength(value?: string | undefined | null) {
  if (!value) return false;
  return value.length <= 16 && value.length >= 10;
}
