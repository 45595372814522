import { faCheck, faCircleInfo, faHyphen, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StockLevel } from 'microshop-api';
import { useContext } from 'react';
import { ThemeContext } from 'styled-components/macro';

type StockIconProps = {
    stockLevel?: StockLevel;
    innerStock?: boolean;
    unknown?: boolean;
    className?: string;
    size?: 'lg' | 'sm';
};
const StockIcon = ({ stockLevel, innerStock, unknown, className, size }: StockIconProps) => {
    const themeContext = useContext(ThemeContext);

    if (unknown) {
        return (
            <FontAwesomeIcon
                size={size ?? 'lg'}
                className={className ? className : ''}
                color={themeContext.colors.textFaded}
                icon={faHyphen}
            />
        );
    }

    if (innerStock) {
        return (
            <FontAwesomeIcon
                size={size ?? 'lg'}
                className={className ? className : ''}
                color={themeContext.colors.textLight}
                icon={faCircleInfo}
            />
        );
    }

    if (stockLevel === StockLevel.OutOfStock) stockLevel = StockLevel.LowStock;

    switch (stockLevel) {
        case StockLevel.InStock:
            return (
                <FontAwesomeIcon
                    size={size ?? 'lg'}
                    className={className ? className : ''}
                    color={themeContext.colors.success}
                    icon={faCheck}
                />
            );
        case StockLevel.LowStock:
            return (
                <FontAwesomeIcon
                    size={size ?? 'lg'}
                    className={className ? className : ''}
                    color={themeContext.colors.warning}
                    icon={faCheck}
                />
            );
        // case StockLevel.OutOfStock:
        //     return null;
        default:
            return (
                <FontAwesomeIcon
                    size={size ?? 'lg'}
                    className={className ? className : ''}
                    color={themeContext.colors.error}
                    icon={faTimes}
                />
            );
    }
};

export default StockIcon;
