import styled, { ThemeWebColors } from 'styled-components/macro';

type VariationDotProps = {
    colors?: (keyof ThemeWebColors)[];
    large?: boolean;
    selected?: boolean;
    hoverable?: boolean;
};
const VariationDot = styled.div<VariationDotProps>`
  position: relative;
  width: ${({ large }) => (large ? 24 : 14)}px;
  height: ${({ large }) => (large ? 24 : 14)}px;
  border-radius: ${({ large }) => (large ? 12 : 7)}px;
  flex-shrink: 0;

  ${({ theme, colors }) => !colors?.length && `background-color: ${theme.colors.fill};`}
  ${({ theme, colors }) => colors?.length === 1 && `background-color: ${theme.webColors[colors[0]]};`}
  ${({ theme, colors }) =>
      colors?.length === 2 &&
      `background: linear-gradient(45deg, ${theme.webColors[colors[0]]} 49% , ${theme.webColors[colors[1]]} 51%);`}
  ${({ colors, theme }) =>
      colors &&
      colors.length > 2 &&
      `
      background-image:
        radial-gradient(
          white,
          rgba(255, 255, 255, 0) 0%
        ),
        conic-gradient(${colors.map(
            (color: keyof ThemeWebColors, i: number) =>
                `${theme.webColors[color]} ${(360 / colors.length) * i}deg ${(360 / colors.length) * (i + 1) - 3}deg`,
        )})
      ;
    `}
  border: 1px solid ${({ theme }) => `${theme.colors.fillMedium}`};
  border-width: ${({ colors, large }) =>
      !colors?.length || colors?.some((c: keyof ThemeWebColors) => c === 'white') ? 2 : large ? 0 : 1}px;

  ${({ hoverable }) =>
      hoverable &&
      `
    transition: filter 0.2s linear;
    &:hover {
      > div:first-of-type {
        filter: brightness(90%);
        transition: filter 0.2s linear;
      }
    }
  `}
`;

export default VariationDot;
