import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCheckCircle, faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import i18next from 'i18next';
import { uniqueId } from 'lodash';
import { setLightness } from 'polished';
import React, { useState } from 'react';
import styled, { ThemeColors } from 'styled-components/macro';
import { InputType } from 'types/InputType';
import { isValidPhoneLength, isValidPhoneNumber } from 'utils/inputValidation';
import Spinner from './Spinner';
import Text from './Text';

interface InputProps extends React.HTMLAttributes<HTMLInputElement> {
    autoFocus?: boolean;
    required?: boolean;
    validate?: boolean;
    valid?: boolean | undefined;
    type?: InputType | undefined;
    size?: number;
    name?: string;
    value?: string | number;
    min?: number;
    max?: number;
    large?: boolean;
    bgColor?: keyof ThemeColors;
    icon?: IconProp;
    className?: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    defaultValue?: string | number;
    id?: string;
    label?: string;
    showValidateIcon?: boolean;
    showValidationError?: boolean;
    placeholder?: string;
    loading?: boolean;
    readOnly?: boolean;
    errorText?: string;
    error?: boolean;
    minLength?: number;
    maxLength?: number;
    useBottomBorder?: boolean;
    boldBottomBorder?: boolean;
    centered?: boolean;
    lightLabel?: boolean;
    smallLabel?: boolean;
    usePlaceHolderAsLabel?: boolean;
    warning?: boolean;
    rightSidedIcon?: boolean;
    disabled?: boolean;
    paddingRight?: string;
    suffix?: string | number | null;
    lightness?: number;
    list?: string;
}

const Input = React.forwardRef<HTMLInputElement, React.PropsWithChildren<InputProps>>(
    (
        {
            icon,
            showValidationError = true,
            validate,
            valid,
            useBottomBorder,
            boldBottomBorder,
            centered,
            bgColor,
            large,
            className,
            value,
            defaultValue,
            onChange,
            type = 'text',
            errorText,
            label,
            lightLabel,
            smallLabel = true,
            usePlaceHolderAsLabel,
            showValidateIcon,
            placeholder,
            warning,
            loading,
            rightSidedIcon,
            paddingRight,
            suffix,
            size,
            lightness,
            list,
            ...props
        },
        ref,
    ) => {
        const [id] = useState(props.id ?? uniqueId(`${props.name}-`));
        const [localValue, setValue] = useState<string | undefined>((value || defaultValue)?.toString());
        const invalid = validate
            ? isInvalid(type, props.required, localValue, props.minLength, props.maxLength, warning)
            : false;
        const placeholderLabel = !label && usePlaceHolderAsLabel && placeholder;

        return (
            <InputContainer className={className ?? undefined}>
                {!placeholderLabel && label && (
                    <Label
                        htmlFor={id}
                        className={!lightLabel ? 'f3-700' : undefined}
                        light={lightLabel}
                        smallLabel={smallLabel}
                    >
                        {label}
                        {props.required && (
                            <Text small className="d-inline" color="textFaded">
                                {' *'}
                            </Text>
                        )}
                    </Label>
                )}
                <InputWrapper
                    hasIcon={!!icon && !rightSidedIcon}
                    bottomBorder={useBottomBorder}
                    boldBottomBorder={boldBottomBorder}
                    valid={showValidationError ? !invalid && !errorText : true}
                    paddingRight={paddingRight}
                >
                    {!rightSidedIcon && icon && <Icon icon={icon} />}
                    {showValidateIcon && validate && (
                        <ValidateIcon icon={valid === false ? faTimesCircle : faCheckCircle} $valid={valid} />
                    )}
                    {placeholderLabel && (
                        <Label asPlaceholder hasValue={!!localValue} light smallLabel={smallLabel}>
                            {placeholderLabel}
                        </Label>
                    )}
                    <StyledInput
                        id={id}
                        ref={ref}
                        value={value}
                        large={large}
                        centered={centered}
                        type={type}
                        fontSize={size}
                        list={list}
                        defaultValue={defaultValue ? defaultValue.toString() : undefined}
                        placeholder={!usePlaceHolderAsLabel ? placeholder : undefined}
                        bgColor={useBottomBorder && !bgColor ? 'transparent' : bgColor}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setValue(e.target.value);
                            onChange?.(e);
                        }}
                        warning={warning}
                        lightness={lightness}
                        {...props}
                    />
                    {rightSidedIcon && (loading || icon) && (
                        <IconContainer>
                            {loading ? (
                                <>
                                    <IconDivider />
                                    <Spinner className="ml-2" />
                                </>
                            ) : icon ? (
                                <>
                                    <IconDivider />
                                    <RightSidedIcon icon={icon} />
                                </>
                            ) : null}
                        </IconContainer>
                    )}
                    {suffix && value && (
                        <SuffixInputPlaceholder data-suffix={suffix} fontSize={size} centered={centered}>
                            {value}
                        </SuffixInputPlaceholder>
                    )}
                </InputWrapper>
                {showValidationError && invalid && <ValidationText>{invalid}</ValidationText>}
                {errorText && <ValidationText>{errorText}</ValidationText>}
            </InputContainer>
        );
    },
);

export default Input;

const StyledInput = styled.input<{
    bottomBorder?: boolean;
    boldBottomBorder?: boolean;
    centered?: boolean;
    warning?: boolean;
    fontSize?: number;
    bgColor?: keyof ThemeColors;
    hasIcon?: boolean;
    large?: boolean;
    lightness?: number;
}>`
    border-radius: 0;
    transition: border-color 0.15s ease-in-out;
    ${({ fontSize }) => fontSize && `font-size ${fontSize}px;`};
    background-color: ${({ theme, bgColor, lightness }) =>
        bgColor ? (lightness ? setLightness(lightness, theme.colors[bgColor]) : theme.colors[bgColor]) : '#FFF'};
    outline: none;
    width: 100%;
    line-height: 26px;

    ${({ centered }) => centered && 'text-align: center;'}
    ${({ theme, warning }) => warning && `color: ${theme.colors.error};`}
`;

const IconContainer = styled.div`
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: space-between;
    width: 30px;
`;

const IconDivider = styled.div`
    height: 1.3em;
    width: 0;
    border-left: 1px solid ${({ theme }) => theme.colors.border};
`;

const Icon = styled(FontAwesomeIcon)`
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    pointer-events: none;
    font-size: 16px;
    color: ${({ theme }) => theme.colors.textLight};
`;

const RightSidedIcon = styled(FontAwesomeIcon)`
    color: ${({ theme }) => theme.colors.textLight};
    font-size: 16px;
`;

const ValidateIcon = styled(FontAwesomeIcon)<{ $valid: boolean | undefined }>`
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    pointer-events: none;
    color: ${({ theme, $valid }) =>
        $valid === true ? theme.colors.success : $valid === false ? theme.colors.error : theme.colors.textDisabled};
`;

export const Label = styled.label<{
    light?: boolean;
    smallLabel?: boolean;
    asPlaceholder?: boolean;
    hasValue?: boolean;
}>`
    width: 100%;
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: ${({ smallLabel }) => (smallLabel ? 12 : 15)}px;
    letter-spacing: 0.5px;
    color: ${({ light, theme }) => (light ? theme.colors.textLight : theme.colors.text)};
    transition: all 0.3s ease-in-out;
    margin-bottom: 0;

    ${({ asPlaceholder, hasValue, theme }) =>
        asPlaceholder &&
        `
      font-size: 14px;
      pointer-events: none;
      position: absolute;
      z-index: 1;
      color: ${theme.colors.textFaded};
      left: 10px;
      top: 6px;
      ${
          hasValue &&
          `
          top: -20px;
          font-size: 12px;
          color: ${theme.colors.text};
          left: 6px;
        `
      }
    `}
`;

const InputContainer = styled.div`
    position: relative;
`;

const InputWrapper = styled.div<{
    bottomBorder?: boolean;
    boldBottomBorder?: boolean;
    hasIcon?: boolean;
    large?: boolean;
    valid?: boolean;
    paddingRight?: string;
}>`
    position: relative;
    width: 100%;

    ${({ theme, bottomBorder, boldBottomBorder, hasIcon, large, valid, paddingRight }) =>
        bottomBorder
            ? `
        padding-bottom: ${boldBottomBorder ? 12 : 2}px;
        ${boldBottomBorder ? theme.borders.underline2 : theme.borders.underline1};
        ${StyledInput} {
          border: 0;
          ${large ? 'padding: 8px 15px;' : ''}
          ${hasIcon ? 'padding-left: 40px;' : ''}
        }
      `
            : `
        ${StyledInput} {
          border: 1px solid ${valid ? theme.colors.borderLight : theme.colors.error};
          padding: 6px 8px;
          padding-right: ${paddingRight ? paddingRight : ''};
          ${hasIcon ? 'padding-left: 40px;' : ''}

          &:focus {
            border: 1px solid ${theme.colors.border};
            box-shadow: none;
          }
        }
      `}
`;

const SuffixInputPlaceholder = styled.span<{ fontSize?: number; centered?: boolean }>`
    position: absolute;
    top: 50%;
    left: 0;
    color: transparent;
    padding: 0 8px;
    width: 100%;
    pointer-events: none;
    transform: translateY(-50%);
    ${({ fontSize }) => fontSize && `font-size ${fontSize}px;`};
    ${({ centered }) => centered && `text-align: center;`};

    &:after {
        position: absolute;
        content: attr(data-suffix);
        color: black;
        opacity: 0.7;
        margin-left: 3px;
    }
`;

const ValidationText = styled.span`
    display: block;
    color: ${({ theme }) => theme.colors.error};
    font-size: 11px;
    right: 0;
    line-height: normal;
    margin-top: 5px;
`;

function isInvalid(
    type: InputType,
    required?: boolean,
    value?: string,
    minLength?: number,
    maxLength?: number,
    warning?: boolean,
): string | undefined {
    if (warning) return ' ';
    if (value && minLength && value.length < minLength) {
        return (
            i18next.t(
                'validation.minCharacterLength',
                'Input is shorter than the allowed minlength of {{minLength}} characters',
                { minLength: minLength },
            ) ?? undefined
        );
    }
    if (value && maxLength && value.length > maxLength) {
        return (
            i18next.t(
                'validation.maxCharacterLength',
                'Input is longer than the allowed maxlength of {{maxLength}} characters',
                { maxLength: maxLength },
            ) ?? undefined
        );
    }
    if (!required) return;
    if (required && !value?.trim()) return i18next.t('validation.required', 'Required');
    if (type === 'email' && (!value || value.indexOf('@') === -1))
        return i18next.t('validation.email', 'Invalid email');
    if (type === 'phone' && value) {
        if (!isValidPhoneNumber(value)) return i18next.t('validation.phoneInvalid', 'Invalid telephone number');
        if (!isValidPhoneLength(value))
            return i18next.t('validation.phoneLength', 'Telephone number must be 10 or 16 digits long');
    }

    return;
}
