import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import authService from 'components/ApiAuthorization/AuthorizeService';
import { Init, Shop } from 'microshop-api';
import services from 'services';
import { setConfiguration } from 'services/api';
import { AppDispatch, RootState } from 'store';
import { DefaultTheme } from 'styled-components/macro';
import { getFontFamily } from 'styles/fonts';
import defaultTheme from 'theme/defaultTheme';
import { registerDatePickerLocale } from 'utils/datepickerLocalesConfig';

export enum ShopSecurityType {
    None = 0,
    Secret = 1,
    Registred = 2,
    RegistredSecret = 3,
}

type ShopError = 'error_shop_init' | 'shop_does_not_exist' | 'exception_shop_init';

type Info = Init;

const getMediaPath = () => {
    const mediaPath = document.getElementsByTagName('BODY')[0].getAttribute('data-media');
    return mediaPath && mediaPath.length > 0 ? mediaPath : process.env.REACT_APP_MEDIASERVICEURL;
};

type ShopState = {
    info?: Info;
    shop?: Shop;
    hostName: string;
    initiating: boolean;
    loading: boolean;
    verified: boolean;
    error?: ShopError;
    secretError: boolean;
    cookieAgree: boolean;
    firstVisit: boolean;
    theme: DefaultTheme;
    token: string;
    mediaUrl: string | undefined;
};

const initialState: ShopState = {
    hostName: '',
    info: undefined,
    initiating: false,
    loading: false,
    verified: false,
    error: undefined,
    secretError: false,
    cookieAgree: false,
    firstVisit: false,
    theme: defaultTheme,
    shop: undefined,
    token: '',
    mediaUrl: getMediaPath(),
};

const shopSlice = createSlice({
    name: 'shop',
    initialState,
    reducers: {
        setHostName(state, action: PayloadAction<{ hostName: string }>) {
            state.hostName = action.payload.hostName;
        },
        setShopError(state, action: PayloadAction<{ error: ShopError }>) {
            state.error = action.payload.error;
        },
        setInfo(state, action: PayloadAction<{ info: any }>) {
            state.info = action.payload.info;

            registerDatePickerLocale(action.payload?.info?.language);
        },
        setCookieAndVisit(state, action: PayloadAction<{ cookieAgree: boolean; firstVisit: boolean }>) {
            state.cookieAgree = action.payload.cookieAgree;
            state.firstVisit = action.payload.firstVisit;
        },
        setTheme(state, action: PayloadAction<{ theme: DefaultTheme }>) {
            state.theme = {
                ...state.theme,
                ...action.payload.theme,
                typography: {
                    ...state.theme.typography,
                    ...action.payload.theme.typography,
                },
            };
        },
        setVerified(state, action: PayloadAction<{ token: string }>) {
            state.verified = true;
            state.token = action.payload.token;
        },
        setShop(state, action: PayloadAction<any>) {
            state.shop = { ...state.shop, ...action.payload };
        },
        setLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },
        setCookieAgree(state, action: PayloadAction<boolean>) {
            state.cookieAgree = action.payload;
        },
        setSecretError(state, action: PayloadAction<boolean>) {
            state.secretError = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(initiateShop.pending, (state) => {
            state.initiating = true;
        });
        builder.addCase(initiateShop.rejected, (state) => {
            state.initiating = false;
        });
        builder.addCase(initiateShop.fulfilled, (state) => {
            state.initiating = false;
        });
        builder.addCase(loadShop.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(loadShop.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(loadShop.fulfilled, (state, action) => {
            state.loading = false;
            state.shop = { ...action.payload };
        });
        builder.addCase(getAndSetAnonymousToken.fulfilled, (state) => {
            state.secretError = false;
        });
        builder.addCase(getAndSetAnonymousToken.rejected, (state) => {
            state.secretError = true;
        });
    },
});

const getAnonymousToken = async (secret: string, hostName: string) => {
    try {
        const tokenResponse = await services.oid.connect({ hostName, secret });
        if (!tokenResponse.ok) {
            return null;
        }

        const token = await tokenResponse.json();

        localStorage.setItem(getAnonymousTokenKey(hostName), token.access_token);
        return { token: token.access_token, verified: true, initiating: false };
    } catch (e) {
        return null;
    }
};

const getAnonymousTokenKey = (hostName: string) => {
    return 'AnonymousToken:' + hostName;
};

export function setTokenHeader(token: string) {
    return { headers: { Authorization: `Bearer ${token}` } };
}

const verifyToken = async (token: string | null) => {
    if (!token) return false;

    setConfiguration({ token: token });

    try {
        const verification = await services.shop.verify();
        return /true/i.test(`${verification}`);
    } catch (e) {
        return false;
    }
};

const checkToken = async (hostName: string) => {
    const isAuthenticated = await authService.isAuthenticated();

    const saved_token = isAuthenticated
        ? await authService.getAccessToken()
        : localStorage.getItem(getAnonymousTokenKey(hostName));

    const verifiedToken = await verifyToken(saved_token);
    if (verifiedToken) {
        return { token: saved_token, verified: true };
    }
    return { token: '', verified: false };
};

export const acceptCookie = createAsyncThunk<
    void,
    void,
    {
        dispatch: AppDispatch;
        state: RootState;
    }
>('shop/accept_cookie', async (_, { dispatch, getState }) => {
    const hostName = getState().shop.hostName;
    localStorage.setItem('accept_cookies_' + hostName, 'true');
    dispatch(setCookieAgree(true));
});

export const initiateShop = createAsyncThunk<
    void,
    string,
    {
        dispatch: AppDispatch;
        state: RootState;
    }
>('shop/initiate', async (hostName, { dispatch, getState, rejectWithValue }) => {
    const state = getState().shop;

    dispatch(setHostName({ hostName: hostName }));

    if (state.info) return;

    try {
        const info = await services.shop.initiate();

        if (!info) return;
        if (!info._exists) {
            dispatch(setShopError({ error: 'shop_does_not_exist' }));
            return rejectWithValue('');
        }

        const cookieAgree = localStorage.getItem('accept_cookies_' + hostName) === 'true';
        const firstVisit = !(localStorage.getItem('firstVisit_' + hostName) === 'false');

        dispatch(setCookieAndVisit({ cookieAgree, firstVisit }));

        if (info.theme) {
            const theme = { ...state.theme };
            if (info.theme.main) theme.main = info.theme.main;
            if (info.theme.footer) theme.footer = info.theme.footer;
            if (info.theme.accent) {
                theme.accent = info.theme.accent;
                theme.colors = { ...theme.colors, buy: info.theme.accent, accent: info.theme.accent };
            }
            if (info.theme.lightness) theme.lightness = info.theme.lightness;
            if (info.theme.fontHeader || info.theme.fontBody) {
                const f1 = getFontFamily(info.theme.fontBody ?? undefined);
                const f2 = getFontFamily(info.theme.fontHeader ?? undefined);
                const fontFamilys = {
                    f1400family: f1[400],
                    f1500family: f1[500],
                    f1700family: f1[700],
                    f2400family: f2[400],
                    f2700family: f2[700],
                };
                theme.typography = {
                    ...defaultTheme.typography,
                    ...{
                        fontBody: info.theme.fontBody ?? defaultTheme.typography.fontBody,
                        fontHeading: info.theme.fontHeader ?? defaultTheme.typography.fontHeading,
                    },
                    ...fontFamilys,
                };
            }

            dispatch(setTheme({ theme }));
        }

        dispatch(setInfo({ info: info }));

        const tokenRes = await checkToken(hostName);
        if (tokenRes?.verified) {
            dispatch(setVerified({ token: tokenRes.token! }));
            setConfiguration({ token: tokenRes.token! });
            return;
        }

        if (info?.securityType === ShopSecurityType.None) {
            dispatch(getAndSetAnonymousToken({ token: '', hostName: hostName }));
        }
    } catch (e) {
        dispatch(setShopError({ error: 'exception_shop_init' }));
        return rejectWithValue('');
    }
});

export const getAndSetAnonymousToken = createAsyncThunk<
    void,
    { token: string; hostName?: string },
    {
        dispatch: AppDispatch;
        state: RootState;
    }
>('shop/set_anonymous_token', async ({ token, hostName }, { dispatch, getState, rejectWithValue }) => {
    const state = getState().shop;

    try {
        const anonToken = await getAnonymousToken(token, hostName ?? state.hostName);

        if (anonToken) {
            dispatch(setVerified({ token: anonToken.token! }));
            setConfiguration({ token: anonToken.token! });
            return;
        }

        return rejectWithValue('');
    } catch (e) {
        return rejectWithValue('');
    }
});

export const loadShop = createAsyncThunk<
    Shop | undefined,
    void,
    {
        dispatch: AppDispatch;
        state: RootState;
    }
>('shop/load', async (_, { dispatch, getState, rejectWithValue }) => {
    const state = getState().shop;

    try {
        const shop = await services.shop.load();
        if (state.firstVisit) {
            localStorage.setItem('firstVisit_' + state.hostName, 'false');
        }
        return shop;
    } catch (e) {
        return rejectWithValue({ error: 'An exception occured when loading the shop' });
    }
});

export const {
    setHostName,
    setShopError,
    setCookieAndVisit,
    setInfo,
    setTheme,
    setVerified,
    setShop,
    setLoading,
    setCookieAgree,
    setSecretError,
} = shopSlice.actions;
export default shopSlice.reducer;
