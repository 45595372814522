"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PriceValueToJSON = exports.PriceValueFromJSONTyped = exports.PriceValueFromJSON = exports.instanceOfPriceValue = void 0;
var runtime_1 = require("../runtime");
/**
 * Check if a given object implements the PriceValue interface.
 */
function instanceOfPriceValue(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfPriceValue = instanceOfPriceValue;
function PriceValueFromJSON(json) {
    return PriceValueFromJSONTyped(json, false);
}
exports.PriceValueFromJSON = PriceValueFromJSON;
function PriceValueFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'num': !(0, runtime_1.exists)(json, 'num') ? undefined : json['num'],
        'str': !(0, runtime_1.exists)(json, 'str') ? undefined : json['str'],
    };
}
exports.PriceValueFromJSONTyped = PriceValueFromJSONTyped;
function PriceValueToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'num': value.num,
        'str': value.str,
    };
}
exports.PriceValueToJSON = PriceValueToJSON;
