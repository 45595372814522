"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PriceValuesToJSON = exports.PriceValuesFromJSONTyped = exports.PriceValuesFromJSON = exports.instanceOfPriceValues = void 0;
var runtime_1 = require("../runtime");
var PriceValue_1 = require("./PriceValue");
/**
 * Check if a given object implements the PriceValues interface.
 */
function instanceOfPriceValues(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfPriceValues = instanceOfPriceValues;
function PriceValuesFromJSON(json) {
    return PriceValuesFromJSONTyped(json, false);
}
exports.PriceValuesFromJSON = PriceValuesFromJSON;
function PriceValuesFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'customer': !(0, runtime_1.exists)(json, 'customer') ? undefined : (0, PriceValue_1.PriceValueFromJSON)(json['customer']),
        'retail': !(0, runtime_1.exists)(json, 'retail') ? undefined : (0, PriceValue_1.PriceValueFromJSON)(json['retail']),
        'regular': !(0, runtime_1.exists)(json, 'regular') ? undefined : (0, PriceValue_1.PriceValueFromJSON)(json['regular']),
        'special': !(0, runtime_1.exists)(json, 'special') ? undefined : (0, PriceValue_1.PriceValueFromJSON)(json['special']),
    };
}
exports.PriceValuesFromJSONTyped = PriceValuesFromJSONTyped;
function PriceValuesToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'customer': (0, PriceValue_1.PriceValueToJSON)(value.customer),
        'retail': (0, PriceValue_1.PriceValueToJSON)(value.retail),
        'regular': (0, PriceValue_1.PriceValueToJSON)(value.regular),
        'special': (0, PriceValue_1.PriceValueToJSON)(value.special),
    };
}
exports.PriceValuesToJSON = PriceValuesToJSON;
