import { ApplicationPaths } from 'components/ApiAuthorization/ApiAuthorizationConstants';

const oid = {
    connect: async ({ hostName, secret }: { hostName: string; secret: string }): Promise<any> => {
        return fetch('/connect/token', {
            method: 'POST',
            headers: {
                Accept: '*/*',
                'Content-Type': 'application/x-www-form-urlencoded',
                shop: hostName,
                client_secret: secret,
            },
            body: 'grant_type=client_credentials&client_id=Anonymous',
        });
    },
    authorize: (): Promise<any> => {
        return fetch(ApplicationPaths.ApiAuthorizationClientConfigurationUrl);
    },
};

export { oid };
