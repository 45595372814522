import useImage from 'hooks/useImage';
import React from 'react';

export const Image: React.FC<React.ImgHTMLAttributes<HTMLImageElement>> = ({ alt, ...props }) => {
    const { loaded } = useImage(props.src || '');

    if (!loaded) return null;

    return (
        <>
            <meta itemProp="image" content={props.src} />
            {/*@ts-ignore*/}
            <img rel="preload" alt={alt ? alt : ''} {...props} />
        </>
    );
};

export default Image;
