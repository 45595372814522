import Flex from 'components/ui/Flex';
import Spinner from 'components/ui/Spinner';
import Text from 'components/ui/Text';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import { PriceInformation } from 'microshop-api';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
    PriceType,
    customerPrice,
    selectCustomerPrice,
    selectCustomerPricePending,
    selectPriceValue,
} from 'store/reducers/priceSlice';
import styled, { ThemeColors } from 'styled-components/macro';

type ProductPriceProps = {
    price: PriceInformation;
    fontSize?: number;
    color?: keyof ThemeColors;
    compact?: boolean;
    showPriceType?: PriceType;
};
const ProductPrice: React.FC<ProductPriceProps> = ({ price, fontSize, compact, color }) => {
    const { priceType } = useAppSelector(({ price }) => price);
    const shouldLoadCustomer = useAppSelector(({ shop }) => shop.shop?.isB2BShop);

    const ref = useRef<HTMLDivElement>(null);
    const dispatch = useAppDispatch();

    useEffect(() => {
        let observer: IntersectionObserver | null = null;
        if (ref.current && shouldLoadCustomer) {
            const observer = new IntersectionObserver((entries) =>
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        dispatch(customerPrice(price));
                        observer.disconnect();
                    }
                }),
            );
            observer.observe(ref.current);
        }

        return () => {
            if (observer) observer.disconnect();
        };
    }, [ref, dispatch, price, shouldLoadCustomer]);

    if (priceType === ('none' as PriceType)) return null;

    return (
        <div ref={ref} itemProp="offers" itemType="https://schema.org/Offer" itemScope>
            {compact ? (
                <ProductPriceCompact color={color} fontSize={fontSize} price={price} showPriceType={priceType} />
            ) : (
                <ProductPriceExpanded color={color} fontSize={fontSize} price={price} />
            )}
        </div>
    );
};

const ProductPriceExpanded: React.FC<ProductPriceProps> = ({ fontSize = 32, color, price }) => {
    return (
        <Flex className="mt-1" align="end">
            <MainPrice className="me-3">
                <Text className="f1-500" small light>
                    <CustomerPricePrefix price={price} />
                </Text>
                <Text fontSize={fontSize} color={color ? color : 'price'}>
                    <CustomerPrice price={price} />
                </Text>
            </MainPrice>
        </Flex>
    );
};

const ProductPriceCompact: React.FC<ProductPriceProps> = ({ fontSize = 12, color, price }) => {
    return (
        <Text className="d-inline-block" fontSize={fontSize} color={color ? color : 'price'}>
            <CustomerPriceString price={price} />
        </Text>
    );
};

type PriceInfoProps = {
    price: PriceInformation;
};
const CustomerPriceString: React.FC<PriceInfoProps> = ({ price }) => {
    const customerPrice = useAppSelector((state) => selectCustomerPrice(state, price));
    const pending = useAppSelector((state) => selectCustomerPricePending(state, price));

    if (pending) return <Spinner color="buy" />;
    if (!customerPrice) return <NoPrice />;

    return (
        <>
            <CustomerPricePrefix price={price} />
            <CustomerPrice price={price} />
        </>
    );
};

const CustomerPrice: React.FC<PriceInfoProps> = ({ price }) => {
    const isSale = undefined;
    const customerPrice = useAppSelector((state) => selectCustomerPrice(state, price));
    const pending = useAppSelector((state) => selectCustomerPricePending(state, price));
    const priceValue = useAppSelector((state) => selectPriceValue(state, price));
    // const isSale = useAppSelector((state) => selectIsSale(state, price));

    if (pending) return <Spinner color="buy" />;
    if (!customerPrice) return <NoPrice />;

    return (
        <>
            <StyledPrice color={isSale ? 'sale' : undefined}>
                {customerPrice?.str ?? <NoPrice />}
                <meta itemProp="price" content={`${customerPrice?.str}`} />
                <meta itemProp="priceCurrency" content={`${price.currency}`} />
            </StyledPrice>
            {isSale && priceValue.regular?.str && (
                <StyledStrikePrice className="f1-500" color="textLight">
                    {priceValue.regular.str}
                </StyledStrikePrice>
            )}
        </>
    );
};

// const RetailPriceString: React.FC<PriceInfoProps> = ({ price }) => {
//     if (typeof price.retail?.num !== 'number') return null;
//     if (price.retail.num === 0) return <span>-</span>;

//     return (
//         <>
//             <RetailPricePrefix price={price} />
//             <RetailPrice price={price} />
//         </>
//     );
// };

// const RetailPrice: React.FC<PriceInfoProps> = ({ price }) => {
//     return (
//         <StyledPrice>
//             {price.retail?.str}
//             <meta itemProp="price" content={`${price.retail?.str}`} />
//             <meta itemProp="priceCurrency" content={`${price.currency}`} />
//         </StyledPrice>
//     );
// };

// const RetailPricePrefix: React.FC<PriceInfoProps> = ({ price }) => {
//     const { t } = useTranslation();
//     const isBox = false;

//     const format = useAppSelector(({ price }) => price.priceFormat);
//     const isBox = useAppSelector((state) => selectIsBox(state, price));

//     return (
//         <PriceSpan className="me-1">
//             {/* {format?.prefixRetail ? format.prefixRetail : t('price.rrp_short', 'RRP')} */}
//             {isBox ? ' / ' + t('stock_pcs', 'pcs') : ''}
//         </PriceSpan>
//     );
// };

const CustomerPricePrefix: React.FC<PriceInfoProps> = ({ price }) => {
    const { t } = useTranslation();
    const isBox = false;
    const isSale = false;
    const format = {
        prefixBox: '',
        prefixSales: '',
        prefix: '',
    };

    // const isBox = useAppSelector((state) => selectIsBox(state, price));
    // const isSale = useAppSelector((state) => selectIsSale(state, price));
    // const format = useAppSelector(({ price }) => price.priceFormat);

    const boxPrefix = format?.prefixBox ?? '';
    const salePrefix = format?.prefixSales ?? '';
    const prefix = format?.prefix ?? '';

    let p: string = prefix;

    if (isSale) p = salePrefix;
    if (isBox) p = boxPrefix;

    return (
        <PriceSpan className="me-1">
            {p}
            {isBox ? price.inboxItems + t('stock_pcs', 'pcs') : ''}
        </PriceSpan>
    );
};

const NoPrice = () => {
    return null;
};

export default ProductPrice;

const Price = styled.div``;

// const NoPriceSpan = styled.span`
//     color: ${({ theme }) => theme.colors.textLight};
// `;

const MainPrice = styled(Price)`
    line-height: 1.2;
`;

const PriceSpan = styled.span`
    display: inline-block;
`;

const StyledPrice = styled(PriceSpan)<{ color?: keyof ThemeColors }>`
    ${({ color, theme }) => color && `color: ${theme.colors[color]};`}
`;

const StyledStrikePrice = styled(StyledPrice)`
    text-decoration: line-through;
    margin-left: 0.4em;
`;
