"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeliveryAddressInputToJSON = exports.DeliveryAddressInputFromJSONTyped = exports.DeliveryAddressInputFromJSON = exports.instanceOfDeliveryAddressInput = void 0;
var runtime_1 = require("../runtime");
/**
 * Check if a given object implements the DeliveryAddressInput interface.
 */
function instanceOfDeliveryAddressInput(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfDeliveryAddressInput = instanceOfDeliveryAddressInput;
function DeliveryAddressInputFromJSON(json) {
    return DeliveryAddressInputFromJSONTyped(json, false);
}
exports.DeliveryAddressInputFromJSON = DeliveryAddressInputFromJSON;
function DeliveryAddressInputFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': !(0, runtime_1.exists)(json, 'name') ? undefined : json['name'],
        'street': !(0, runtime_1.exists)(json, 'street') ? undefined : json['street'],
        'street2': !(0, runtime_1.exists)(json, 'street2') ? undefined : json['street2'],
        'street3': !(0, runtime_1.exists)(json, 'street3') ? undefined : json['street3'],
        'zipCode': !(0, runtime_1.exists)(json, 'zipCode') ? undefined : json['zipCode'],
        'stateCode': !(0, runtime_1.exists)(json, 'stateCode') ? undefined : json['stateCode'],
        'phone': !(0, runtime_1.exists)(json, 'phone') ? undefined : json['phone'],
        'city': !(0, runtime_1.exists)(json, 'city') ? undefined : json['city'],
        'country': !(0, runtime_1.exists)(json, 'country') ? undefined : json['country'],
        'email': !(0, runtime_1.exists)(json, 'email') ? undefined : json['email'],
        'id': !(0, runtime_1.exists)(json, 'id') ? undefined : json['id'],
    };
}
exports.DeliveryAddressInputFromJSONTyped = DeliveryAddressInputFromJSONTyped;
function DeliveryAddressInputToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'street': value.street,
        'street2': value.street2,
        'street3': value.street3,
        'zipCode': value.zipCode,
        'stateCode': value.stateCode,
        'phone': value.phone,
        'city': value.city,
        'country': value.country,
        'email': value.email,
        'id': value.id,
    };
}
exports.DeliveryAddressInputToJSON = DeliveryAddressInputToJSON;
