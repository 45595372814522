import Spinner from './Spinner';

const Loader = ({ loading, label, small }: { loading: boolean; label: string; small?: boolean }) => {
    // const spinnerClasses = small ? 'spinner-grow spinner-grow-sm' : 'spinner-grow';

    if (!loading) return null;
    return (
        loading && (
            <>
                <Spinner color="buy" className="mx-2" />
                {/* <span className={`${spinnerClasses} mx-2`} role="status"></span> */}
                <span>{label}</span>
            </>
        )
    );
};
export default Loader;
