import { Component } from 'react';
import ShopLoad from '../Shop/ShopLoad';
import { ApplicationPaths, LogoutActions, QueryParameterNames } from './ApiAuthorizationConstants';
import authService, { AuthenticationResultStatus } from './AuthorizeService';

// The main responsibility of this component is to handle the user's logout process.
// This is the starting point for the logout process, which is usually initiated when a
// user clicks on the logout button on the LoginMenu component.

type State = { message?: string; isReady: boolean; authenticated: boolean };

type Props = {
    action: string;
};

export class Logout extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            message: undefined,
            isReady: false,
            authenticated: false,
        };
    }

    componentDidMount() {
        const action = this.props.action;

        switch (action) {
            case LogoutActions.Logout:
                if (!!window.history.state?.usr?.local) {
                    this.logout(this.getReturnUrl());
                } else {
                    // This prevents regular links to <app>/authentication/logout from triggering a logout
                    this.setState({ isReady: true, message: 'The logout was not initiated from within the page.' });
                }
                break;
            case LogoutActions.LogoutCallback:
                this.processLogoutCallback();
                break;
            case LogoutActions.LoggedOut:
                this.makeSureloggedOut();
                this.setState({ isReady: true, message: 'loggedout' });
                break;
            default:
                throw new Error(`Invalid action '${action}'`);
        }

        this.populateAuthenticationState();
    }

    render() {
        const { isReady, message } = this.state;
        if (!isReady) {
            return null;
        }
        if (!!message) {
            setTimeout(() => {
                this.navigateToReturnUrl('/');
            }, 2000);
            return <ShopLoad loggedout={message === 'loggedout'} message={message} />;
        } else {
            const action = this.props.action;
            switch (action) {
                case LogoutActions.Logout:
                    return <ShopLoad logout={true} />;
                case LogoutActions.LogoutCallback:
                    return <ShopLoad logout={true} />;
                case LogoutActions.LoggedOut:
                    setTimeout(() => {
                        this.navigateToReturnUrl('/');
                    }, 2000);
                    return <ShopLoad loggedout={true} />;
                default:
                    throw new Error(`Invalid action '${action}'`);
            }
        }
    }
    // If logging out outside rect app the is isAuthenticated might still return true. Makes sure it clear internal auth service as well
    async makeSureloggedOut() {
        const isauthenticated = await authService.isAuthenticated();
        if (isauthenticated) {
            await authService.signOut({});
        }
    }
    async logout(returnUrl: any) {
        const state = { returnUrl };
        const isauthenticated = await authService.isAuthenticated();
        if (isauthenticated) {
            const result = await authService.signOut(state);
            switch (result.status) {
                case AuthenticationResultStatus.Redirect:
                    break;
                case AuthenticationResultStatus.Success:
                    await this.navigateToReturnUrl(returnUrl);
                    break;
                case AuthenticationResultStatus.Fail:
                    this.setState({ message: result.message });
                    break;
                default:
                    throw new Error('Invalid authentication result status.');
            }
        } else {
            this.setState({ message: 'loggedout' });
        }
    }

    async processLogoutCallback() {
        const url = window.location.href;
        const result = await authService.completeSignOut(url);
        switch (result.status) {
            case AuthenticationResultStatus.Redirect:
                // There should not be any redirects as the only time completeAuthentication finishes
                // is when we are doing a redirect sign in flow.
                throw new Error('Should not redirect.');
            case AuthenticationResultStatus.Success:
                await this.navigateToReturnUrl(this.getReturnUrl(result.state));
                break;
            case AuthenticationResultStatus.Fail:
                this.setState({ message: result.message });
                break;
            default:
                throw new Error('Invalid authentication result status.');
        }
    }

    async populateAuthenticationState() {
        const authenticated = await authService.isAuthenticated();
        this.setState({ isReady: true, authenticated });
    }

    getReturnUrl(state?: { returnUrl: any } | undefined) {
        const params = new URLSearchParams(window.location.search);
        const fromQuery = params.get(QueryParameterNames.ReturnUrl);
        if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
            // This is an extra check to prevent open redirects.
            throw new Error('Invalid return url. The return url needs to have the same origin as the current page.');
        }
        return (state && state.returnUrl) || fromQuery || `${window.location.origin}${ApplicationPaths.LoggedOut}`;
    }

    navigateToReturnUrl(returnUrl: string | URL) {
        return window.location.replace(returnUrl);
    }
}
