import { createGlobalStyle } from 'styled-components/macro';

import { craftSansSrc } from './fonts/craft-sans';
import { exoSrc } from './fonts/exo';
import { helveticaSrc } from './fonts/helvetica';
// import { interSrc } from './fonts/inter';
import { libreFranklinSrc } from './fonts/libre-franklin';
import { recifeDisplaySrc } from './fonts/recife-display';
import { valueSansSrc } from './fonts/value-sans';
import { robotoSrc } from './fonts/roboto/index';

const FontStyle = createGlobalStyle`
  body {
    font-size: ${({ theme }) => theme.typography.fontSize};
    font-family: ${({ theme }) => theme.typography.f1400family}, helvetica, sans-serif;
  }
  h1, h2, h3 {
    font-family: ${({ theme }) => theme.typography.f2700family}, helvetica, sans-serif;
    font-weight: 700;
  }
  h3 {
    font-family: ${({ theme }) => theme.typography.f1700family}, helvetica, sans-serif;
    font-weight: 700;
  }
  h4 {
    font-family: ${({ theme }) => theme.typography.f1500family}, helvetica, sans-serif;
    font-weight: 500;
  }
  .f1-400 {
    font-family: ${({ theme }) => theme.typography.f1400family}, helvetica, sans-serif;
    font-weight: 400;
  }
  .f1-500 {
    font-family: ${({ theme }) => theme.typography.f1500family}, helvetica, sans-serif;
    font-weight: 500;
  }
  .f1-700 {
    font-family: ${({ theme }) => theme.typography.f1700family}, helvetica, sans-serif;
    font-weight: 700;
  }
  .f2-400 {
    font-family: ${({ theme }) => theme.typography.f2400family}, helvetica, sans-serif;
    font-weight: 400;
  }
  .f2-700 {
    font-family: ${({ theme }) => theme.typography.f2700family}, helvetica, sans-serif;
    font-weight: 700;
  }
  .f3-300 {
    font-family: ${({ theme }) => theme.typography.f3300family}, helvetica, sans-serif;
    font-weight: 300;
  }
  .f3-400 {
    font-family: ${({ theme }) => theme.typography.f3400family}, helvetica, sans-serif;
    font-weight: 400;
  }
  .f3-500 {
    font-family: ${({ theme }) => theme.typography.f3500family}, helvetica, sans-serif;
    font-weight: 500;
  }
  .f3-700 {
    font-family: ${({ theme }) => theme.typography.f3700family}, helvetica, sans-serif;
    font-weight: 700;
  }
`;

export function getFontLink(key?: string) {
    switch (key) {
        case 'Antenna Ex Cond':
        case 'Antenna':
            return (
                <link
                    href="//cloud.typenetwork.com/projects/5402/fontface.css/"
                    rel="stylesheet"
                    type="text/css"
                ></link>
            );
        case 'urw-din-semi-condensed':
            return <link href="https://use.typekit.net/ejk4rne.css" rel="stylesheet"></link>;
        case 'Inter':
            return '';
        case 'Roboto':
            return '';
        case 'Libre Franklin':
            return '';
        case 'Craft Sans':
            return '';
        case 'Exo':
            return '';
        case 'Recife Display':
            return '';
        case 'Value Sans':
            return '';
        case 'Helvetica':
            return '';
        case 'urw-form':
        default:
            return <link href="https://use.typekit.net/obe0xuy.css" rel="stylesheet"></link>;
    }
}

export function getLocalFontSrc(key: string) {
    const def = robotoSrc;
    switch (key) {
        case 'Inter':
            return def;
        case 'Craft Sans':
            return craftSansSrc;
        case 'Exo':
            return exoSrc;
        case 'Roboto':
            return robotoSrc;
        case 'Libre Franklin':
            return libreFranklinSrc;
        case 'Recife Display':
            return recifeDisplaySrc;
        case 'Value Sans':
            return valueSansSrc;
        case 'Helvetica':
            return helveticaSrc;
        case 'Antenna':
        case 'Antenna Ex Cond':
        case 'URW Din Semi Condensed':
        case 'URW Form':
        default:
            return def;
    }
}

export function getFontFamily(key?: string) {
    switch (key) {
        case 'Antenna':
            return {
                400: 'Antenna Reg',
                500: 'Antenna Med',
                700: 'Antenna Bd',
            };
        case 'Antenna Ex Cond':
            return {
                400: 'Antenna Ex Cond Med',
                500: 'Antenna Ex Cond Med',
                700: 'Antenna Ex Cond Med',
            };
        case 'Inter':
            return {
                400: 'Inter',
                500: 'Inter',
                700: 'Inter',
            };
        case 'Craft Sans':
            return {
                400: 'Craft Sans',
                500: 'Craft Sans',
                700: 'Craft Sans',
            };
        case 'Exo':
            return {
                400: 'Exo',
                500: 'Exo',
                700: 'Exo',
            };
        case 'URW Din Semi Condensed':
            return {
                400: 'urw-din-semi-condensed',
                500: 'urw-din-semi-condensed',
                700: 'urw-din-semi-condensed',
            };
        case 'Roboto':
            return {
                400: 'Roboto',
                500: 'Roboto',
                700: 'Roboto',
            };
        case 'Libre Franklin':
            return {
                400: 'Libre Franklin',
                500: 'Libre Franklin',
                700: 'Libre Franklin',
            };
        case 'Recife Display':
            return {
                400: 'Recife Display',
                500: 'Recife Display',
                700: 'Recife Display',
            };
        case 'Value Sans':
            return {
                400: 'Value Sans',
                500: 'Value Sans',
                700: 'Value Sans',
            };
        case 'Helvetica':
            return {
                400: 'Helvetica',
                500: 'Helvetica',
                700: 'Helvetica',
            };
        case 'URW Form':
        default:
            return {
                400: 'urw-form',
                500: 'urw-form',
                700: 'urw-form',
            };
    }
}

export default FontStyle;
