import { ToastContainer } from 'react-toastify';
import styled from 'styled-components/macro';

const StyledToastContainer = styled(ToastContainer).attrs({})`
  width: auto;
  margin: 1em;
  margin-top: 7em;

  .Toastify__toast-container--bottom-center {
    transform: none;
  }

  .Toastify__toast-container--top-right {
    top: 6rem;
  }

  .Toastify__toast {
    padding: 0;
    border-radius: 2px;
    transition-duration: 100ms !important;
  }
  .Toastify__toast--error {
  }
  .Toastify__toast--warning {
  }
  .Toastify__toast--success {
  }
  .Toastify__toast-body {
    padding: 0;
  }
  .Toastify__progress-bar {
  }
  .Toastify__slide-enter {
  }
`;

export default StyledToastContainer;
