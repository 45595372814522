import Cookies from 'components/ui/Cookies';
import Footer from 'components/ui/Footer';
import useAppSelector from 'hooks/useAppSelector';
import { useTranslation } from 'react-i18next';
import { SkeletonTheme } from 'react-loading-skeleton';
import styled from 'styled-components/macro';
import MainNavigation from './MainNavigation';

const Layout = ({ children }: { children: React.ReactNode }) => {
    const { t } = useTranslation();
    const { shop } = useAppSelector(({ shop }) => shop);

    return (
        <SkeletonTheme baseColor="#f6f6f6" highlightColor="#efefef">
            <StyledContainer>
                <MainNavigation loginLabel={t('signin')} logoutLabel={t('signout')} />
                <StyledMain>{children}</StyledMain>
                <Footer
                    contactPhone={shop?.contactPhone}
                    contactEmail={shop?.contactEmail}
                    contactName={shop?.contactName}
                    company={shop?.company}
                    companyText={shop?.companyText}
                />
                <Cookies />
            </StyledContainer>
        </SkeletonTheme>
    );
};

const StyledMain = styled.main`
    width: 100%;
    background-color: rgb(244, 244, 244);
    max-width: ${({ theme }) => theme.breakpoints.xl}px;
    margin: 0px auto;
    align-items: center;
    flex-grow: 2;

    @media (min-width: 576px) {
        .main {
            margin-top: 110px;
        }
    }
`;

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
`;
export default Layout;
