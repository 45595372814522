"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PriceDiscountToJSON = exports.PriceDiscountFromJSONTyped = exports.PriceDiscountFromJSON = exports.instanceOfPriceDiscount = void 0;
var runtime_1 = require("../runtime");
/**
 * Check if a given object implements the PriceDiscount interface.
 */
function instanceOfPriceDiscount(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfPriceDiscount = instanceOfPriceDiscount;
function PriceDiscountFromJSON(json) {
    return PriceDiscountFromJSONTyped(json, false);
}
exports.PriceDiscountFromJSON = PriceDiscountFromJSON;
function PriceDiscountFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'regularPrice': !(0, runtime_1.exists)(json, 'regularPrice') ? undefined : json['regularPrice'],
        'discountPercent': !(0, runtime_1.exists)(json, 'discountPercent') ? undefined : json['discountPercent'],
    };
}
exports.PriceDiscountFromJSONTyped = PriceDiscountFromJSONTyped;
function PriceDiscountToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'regularPrice': value.regularPrice,
        'discountPercent': value.discountPercent,
    };
}
exports.PriceDiscountToJSON = PriceDiscountToJSON;
