import Flex from 'components/ui/Flex';
// import useAppDispatch from 'hooks/useAppDispatch';
// import useWindowSize from 'hooks/useWindowSize';
import { ProductImage } from 'microshop-api';
import { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Col } from 'reactstrap';
import styled from 'styled-components';
import ProductImages from './ProductImages';

const ProductDetailPageImage = ({ loading = false }: { loading: boolean }) => {
    // const dispatch = useAppDispatch();
    // const windowSize = useWindowSize();

    // const { product } = useAppSelector(({ product }) => product);
    const [currentSlideImageIndex, setCurrentSlideImageIndex] = useState<number>(0);
    // const selectedImage = images[currentSlideImageIndex];

    // const { pending: productPending, product, variation } = useAppSelector(({ product }) => product);
    // const hideAdditionalMenu = useAppSelector(({ product }) => product.productSettings?.hideAdditionalMenu);

    // const { commerceType } = useAppSelector(({ siteDefinition }) => siteDefinition);

    // const { productCategoryMap, categories, pageRootUrl } = useAppSelector(({ assortment }) => assortment);
    // const parentCategoryId = product?.productNumber && productCategoryMap[product?.productNumber].code;
    // const parentCategory = parentCategoryId ? categories[parentCategoryId] : null;

    // const refinementsEnabled = commerceType === CommerceType.B2BResellers || commerceType === CommerceType.B2B;

    // const showLogoApplicator = refinementsEnabled && !product?.refinementProduct && selectedImage?.angle !== 'none';

    return (
        <Col xs="12" md="5" xl="6" className="p-0 p-md-auto pe-0 position-relative">
            <ImageButtonWrapper justify="between" className="w-100 pt-3 px-4">
                <Flex></Flex>
                {/* {windowSize.width >= 1400 ? (
                    <Breadcrumbs className="pb-2" activePageName={product?.productName} />
                ) : (
                    parentCategory && (
                        <Link to={getCategoryPath(parentCategory, pageRootUrl).url} noStyle>
                            <Flex align="center">
                                <IconButton icon={faArrowLeft} />
                                <ImageButtonLabel className="ms-1 px-1">{parentCategory?.name}</ImageButtonLabel>
                            </Flex>
                        </Link>
                    )
                )} */}
                {/* <MenuButton icon={faEllipsisV}>
                    {(close) => (
                        <ProductImageMenu
                            productNumber={product?.productNumber ?? ''}
                            selectedImage={selectedImage?.imageId ?? ''}
                            displayLogoApplicator={false}
                            onlogoApplicatorClick={() => {}}
                            close={close}
                        />
                    )}
                </MenuButton> */}
            </ImageButtonWrapper>
            <ImageContainer className="">
                {loading ? (
                    <>
                        <Skeleton height={'70vh'}></Skeleton>
                        <Skeleton height={50}></Skeleton>
                    </>
                ) : (
                    <ProductImages onImageChange={(currentIndex: number) => setCurrentSlideImageIndex(currentIndex)} />
                )}
            </ImageContainer>
        </Col>
    );
};

export default ProductDetailPageImage;

// const ImageButtonLabel = styled.label`
//     margin-bottom: 0;
//     cursor: pointer;
//     /* Om texten hamnar över bilden i visssa skärmstorlekar */
//     background: rgba(255, 255, 255, 0.6);
//     border-radius: 1px;
// `;

const ImageButtonWrapper = styled(Flex)`
    position: absolute;
    top: 0;
    left: 0;
    font-size: 14px;
    z-index: 2;
`;

const ImageContainer = styled.div``;
