"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShopProductAddOnTypeToJSON = exports.ShopProductAddOnTypeFromJSONTyped = exports.ShopProductAddOnTypeFromJSON = exports.ShopProductAddOnType = void 0;
/**
 *
 * @export
 */
exports.ShopProductAddOnType = {
    Optional: 0,
    Mandatory: 1
};
function ShopProductAddOnTypeFromJSON(json) {
    return ShopProductAddOnTypeFromJSONTyped(json, false);
}
exports.ShopProductAddOnTypeFromJSON = ShopProductAddOnTypeFromJSON;
function ShopProductAddOnTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.ShopProductAddOnTypeFromJSONTyped = ShopProductAddOnTypeFromJSONTyped;
function ShopProductAddOnTypeToJSON(value) {
    return value;
}
exports.ShopProductAddOnTypeToJSON = ShopProductAddOnTypeToJSON;
