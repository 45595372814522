import { faCheckDouble, faTable, faThList } from '@fortawesome/pro-solid-svg-icons';
import Flex from 'components/ui/Flex';
import IconButton from 'components/ui/IconButton';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import { OrderLayout, setOrderLayout } from 'store/reducers/productSlice';
import styled from 'styled-components/macro';

const ProductBuyTypeSelect = ({ hasVariations }: { hasVariations: boolean }) => {
    const dispatch = useAppDispatch();
    const { orderLayout: selectedOrderLayout } = useAppSelector(({ product }) => product);

    if (!hasVariations) {
        return null;
    }

    return (
        <SelectButtons className="pe-2 pe-md-4" justify="end">
            <SelectButton
                square
                large
                icon={faCheckDouble}
                onClick={() => dispatch(setOrderLayout(OrderLayout.Normal))}
                selected={selectedOrderLayout === OrderLayout.Normal}
                color={selectedOrderLayout === OrderLayout.Normal ? 'text' : 'textLight'}
            ></SelectButton>
            <SelectButton
                square
                large
                icon={faTable}
                onClick={() => dispatch(setOrderLayout(OrderLayout.Matrix))}
                selected={selectedOrderLayout === OrderLayout.Matrix}
                color={selectedOrderLayout === OrderLayout.Matrix ? 'text' : 'textLight'}
            ></SelectButton>
            <SelectButton
                square
                large
                icon={faThList}
                onClick={() => dispatch(setOrderLayout(OrderLayout.MatrixCompact))}
                selected={selectedOrderLayout === OrderLayout.MatrixCompact}
                color={selectedOrderLayout === OrderLayout.MatrixCompact ? 'text' : 'textLight'}
            />
        </SelectButtons>
    );
};

export default ProductBuyTypeSelect;

const SelectButtons = styled(Flex)``;

const SelectButton = styled(IconButton)<{ selected: boolean }>`
    border-radius: 0;
    margin-left: 2px;
    font-size: 11px;

    ${({ selected, theme }) =>
        selected &&
        `
    background-color: ${theme.colors.fillWhite};
    color: ${theme.colors.text} !important;
  `};
`;
