"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CartItemVariationToJSON = exports.CartItemVariationFromJSONTyped = exports.CartItemVariationFromJSON = exports.instanceOfCartItemVariation = void 0;
var runtime_1 = require("../runtime");
var CartItemSku_1 = require("./CartItemSku");
var ProductImage_1 = require("./ProductImage");
/**
 * Check if a given object implements the CartItemVariation interface.
 */
function instanceOfCartItemVariation(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfCartItemVariation = instanceOfCartItemVariation;
function CartItemVariationFromJSON(json) {
    return CartItemVariationFromJSONTyped(json, false);
}
exports.CartItemVariationFromJSON = CartItemVariationFromJSON;
function CartItemVariationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'image': !(0, runtime_1.exists)(json, 'image') ? undefined : (0, ProductImage_1.ProductImageFromJSON)(json['image']),
        'number': !(0, runtime_1.exists)(json, 'number') ? undefined : json['number'],
        'color': !(0, runtime_1.exists)(json, 'color') ? undefined : json['color'],
        'colorCode': !(0, runtime_1.exists)(json, 'colorCode') ? undefined : json['colorCode'],
        'webColors': !(0, runtime_1.exists)(json, 'webColors') ? undefined : json['webColors'],
        'skus': !(0, runtime_1.exists)(json, 'skus') ? undefined : (json['skus'] === null ? null : json['skus'].map(CartItemSku_1.CartItemSkuFromJSON)),
        'split': !(0, runtime_1.exists)(json, 'split') ? undefined : json['split'],
        'collectionId': !(0, runtime_1.exists)(json, 'collectionId') ? undefined : json['collectionId'],
    };
}
exports.CartItemVariationFromJSONTyped = CartItemVariationFromJSONTyped;
function CartItemVariationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'image': (0, ProductImage_1.ProductImageToJSON)(value.image),
        'split': value.split,
        'collectionId': value.collectionId,
    };
}
exports.CartItemVariationToJSON = CartItemVariationToJSON;
