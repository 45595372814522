"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppContextTransportTypeToJSON = exports.AppContextTransportTypeFromJSONTyped = exports.AppContextTransportTypeFromJSON = exports.instanceOfAppContextTransportType = void 0;
var runtime_1 = require("../runtime");
var AppContextSettingLanguage_1 = require("./AppContextSettingLanguage");
/**
 * Check if a given object implements the AppContextTransportType interface.
 */
function instanceOfAppContextTransportType(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfAppContextTransportType = instanceOfAppContextTransportType;
function AppContextTransportTypeFromJSON(json) {
    return AppContextTransportTypeFromJSONTyped(json, false);
}
exports.AppContextTransportTypeFromJSON = AppContextTransportTypeFromJSON;
function AppContextTransportTypeFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'description': !(0, runtime_1.exists)(json, 'description') ? undefined : json['description'],
        'name': !(0, runtime_1.exists)(json, 'name') ? undefined : json['name'],
        'value': !(0, runtime_1.exists)(json, 'value') ? undefined : json['value'],
        'restricted': !(0, runtime_1.exists)(json, 'restricted') ? undefined : json['restricted'],
        'hideDeliveryAddress': !(0, runtime_1.exists)(json, 'hideDeliveryAddress') ? undefined : json['hideDeliveryAddress'],
        'languages': !(0, runtime_1.exists)(json, 'languages') ? undefined : (json['languages'] === null ? null : json['languages'].map(AppContextSettingLanguage_1.AppContextSettingLanguageFromJSON)),
    };
}
exports.AppContextTransportTypeFromJSONTyped = AppContextTransportTypeFromJSONTyped;
function AppContextTransportTypeToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'value': value.value,
        'restricted': value.restricted,
        'hideDeliveryAddress': value.hideDeliveryAddress,
        'languages': value.languages === undefined ? undefined : (value.languages === null ? null : value.languages.map(AppContextSettingLanguage_1.AppContextSettingLanguageToJSON)),
    };
}
exports.AppContextTransportTypeToJSON = AppContextTransportTypeToJSON;
