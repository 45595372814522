"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductStockToJSON = exports.ProductStockFromJSONTyped = exports.ProductStockFromJSON = exports.instanceOfProductStock = void 0;
var runtime_1 = require("../runtime");
var StockLevel_1 = require("./StockLevel");
var StockType_1 = require("./StockType");
/**
 * Check if a given object implements the ProductStock interface.
 */
function instanceOfProductStock(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfProductStock = instanceOfProductStock;
function ProductStockFromJSON(json) {
    return ProductStockFromJSONTyped(json, false);
}
exports.ProductStockFromJSON = ProductStockFromJSON;
function ProductStockFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'value': !(0, runtime_1.exists)(json, 'value') ? undefined : json['value'],
        'stockLevel': !(0, runtime_1.exists)(json, 'stockLevel') ? undefined : (0, StockLevel_1.StockLevelFromJSON)(json['stockLevel']),
        'type': !(0, runtime_1.exists)(json, 'type') ? undefined : (0, StockType_1.StockTypeFromJSON)(json['type']),
    };
}
exports.ProductStockFromJSONTyped = ProductStockFromJSONTyped;
function ProductStockToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'value': value.value,
        'stockLevel': (0, StockLevel_1.StockLevelToJSON)(value.stockLevel),
        'type': (0, StockType_1.StockTypeToJSON)(value.type),
    };
}
exports.ProductStockToJSON = ProductStockToJSON;
