import { Configuration, HTTPHeaders } from 'microshop-api';

export const config: Configuration = new Configuration({
    basePath: '',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
});

export const setConfiguration = function ({ token }: { token: string }) {
    const bearer = `Bearer ${token}`;
    const headers = {
        ...config.headers,
        Authorization: bearer,
    } as HTTPHeaders;

    config.config = {
        basePath: config.basePath,
        fetchApi: config.fetchApi,
        middleware: config.middleware,
        queryParamsStringify: config.queryParamsStringify,
        username: config.username,
        password: config.password,
        apiKey: config.apiKey,
        accessToken: config.accessToken,
        headers: headers,
        credentials: config.credentials,
    } as Configuration;
};
