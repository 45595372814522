import { CustomTag } from 'microshop-api';

type TagsProps = {
    tags?: CustomTag[];
    className?: string;
    compact?: boolean;
    children?: (tag: CustomTag, index: number) => JSX.Element;
};

const Tags: React.FC<TagsProps> = ({ tags, className, compact, children }) => {
    return null;
    // return (
    //     <TagsContainer className={className ? className : ''}>
    //         {tags?.map((tag, i) => {
    //             return tag.position !== TagPosition.Price ? (
    //                 <div key={i}>
    //                     {children ? (
    //                         <>{children && children(tag, i)}</>
    //                     ) : (
    //                         <TagBadge compact={compact} key={i} type={tag.type!} className="">
    //                             {tag.value}
    //                         </TagBadge>
    //                     )}
    //                 </div>
    //             ) : (
    //                 <TagPrice compact={compact} key={i}>
    //                     {tag.value}
    //                 </TagPrice>
    //             );
    //         })}
    //     </TagsContainer>
    // );
};

// const TagsContainer = styled.div`
//     display: flex;
//     flex-wrap: wrap;
//     max-width: 300px;

//     & > div {
//         margin-right: 2px;
//     }
// `;

export default Tags;
