import styled, { ThemeColors } from 'styled-components/macro';

interface TextProps {
    light?: boolean;
    large?: boolean;
    small?: boolean;
    pointer?: boolean;
    color?: keyof ThemeColors;
    underline?: boolean;
    strike?: boolean;
    fontSize?: number;
    uppercase?: boolean;
    textOverflow?: string;
    center?: boolean;
    bold?: boolean;
    nowrap?: boolean;
    className?: string;
    lineheight?: number;
}

const Text = styled.div<TextProps>`
  white-space: ${({ nowrap }) => (nowrap ? 'nowrap' : 'pre-line')};
  font-size: ${({ large, small, theme }) => (large ? '16px' : small ? '12px' : theme.typography.fontSize)};
  color: ${({ light, theme }) => (light ? theme.colors.textLight : theme.colors.text)};
  
  ${({ theme, color }) => color && `color: ${theme.colors[color]};`}
  ${({ bold }) => bold && 'font-weight: 500;'}
  ${({ underline }) => underline && 'text-decoration: underline;'}
  ${({ strike }) => strike && 'text-decoration: line-through;'}
  ${({ fontSize }) => fontSize && `font-size: ${fontSize}px;`}
  ${({ lineheight }) => lineheight && `line-height: ${lineheight}px;`}
  ${({ pointer }) => pointer && 'cursor: pointer;'}
  ${({ uppercase }) => uppercase && 'text-transform: uppercase;'}
  ${({ center }) => center && 'text-align: center;'}
  ${({ textOverflow }) =>
      textOverflow &&
      `
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: ${textOverflow}px;
    `}
`;

export const TextBox = styled(Text)`
    background-color: ${({ theme }) => theme.colors.fill};
    padding: 10px 15px;
`;

export default Text;
