"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShopThemeToJSON = exports.ShopThemeFromJSONTyped = exports.ShopThemeFromJSON = exports.instanceOfShopTheme = void 0;
var runtime_1 = require("../runtime");
/**
 * Check if a given object implements the ShopTheme interface.
 */
function instanceOfShopTheme(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfShopTheme = instanceOfShopTheme;
function ShopThemeFromJSON(json) {
    return ShopThemeFromJSONTyped(json, false);
}
exports.ShopThemeFromJSON = ShopThemeFromJSON;
function ShopThemeFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'main': !(0, runtime_1.exists)(json, 'main') ? undefined : json['main'],
        'footer': !(0, runtime_1.exists)(json, 'footer') ? undefined : json['footer'],
        'accent': !(0, runtime_1.exists)(json, 'accent') ? undefined : json['accent'],
        'lightness': !(0, runtime_1.exists)(json, 'lightness') ? undefined : json['lightness'],
        'fontBody': !(0, runtime_1.exists)(json, 'fontBody') ? undefined : json['fontBody'],
        'fontHeader': !(0, runtime_1.exists)(json, 'fontHeader') ? undefined : json['fontHeader'],
    };
}
exports.ShopThemeFromJSONTyped = ShopThemeFromJSONTyped;
function ShopThemeToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'main': value.main,
        'footer': value.footer,
        'accent': value.accent,
        'lightness': value.lightness,
        'fontBody': value.fontBody,
        'fontHeader': value.fontHeader,
    };
}
exports.ShopThemeToJSON = ShopThemeToJSON;
