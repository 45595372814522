import App from 'App';
import 'i18n';
import ReactDOM from 'react-dom/client';
import 'react-loading-skeleton/dist/skeleton.css';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import store from 'store';

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container!);
root.render(
    <Provider store={store}>
        <Router>
            <App />
        </Router>
    </Provider>,
);
