"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.FilterTypeToJSON = exports.FilterTypeFromJSONTyped = exports.FilterTypeFromJSON = exports.FilterType = void 0;
/**
 *
 * @export
 */
exports.FilterType = {
    Normal: 0,
    Color: 1,
    Checkbox: 2
};
function FilterTypeFromJSON(json) {
    return FilterTypeFromJSONTyped(json, false);
}
exports.FilterTypeFromJSON = FilterTypeFromJSON;
function FilterTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.FilterTypeFromJSONTyped = FilterTypeFromJSONTyped;
function FilterTypeToJSON(value) {
    return value;
}
exports.FilterTypeToJSON = FilterTypeToJSON;
