"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SkuPriceValueToJSON = exports.SkuPriceValueFromJSONTyped = exports.SkuPriceValueFromJSON = exports.instanceOfSkuPriceValue = void 0;
var runtime_1 = require("../runtime");
var PriceDiscount_1 = require("./PriceDiscount");
var PriceValue_1 = require("./PriceValue");
/**
 * Check if a given object implements the SkuPriceValue interface.
 */
function instanceOfSkuPriceValue(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfSkuPriceValue = instanceOfSkuPriceValue;
function SkuPriceValueFromJSON(json) {
    return SkuPriceValueFromJSONTyped(json, false);
}
exports.SkuPriceValueFromJSON = SkuPriceValueFromJSON;
function SkuPriceValueFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'num': !(0, runtime_1.exists)(json, 'num') ? undefined : json['num'],
        'str': !(0, runtime_1.exists)(json, 'str') ? undefined : json['str'],
        'discount': !(0, runtime_1.exists)(json, 'discount') ? undefined : (0, PriceDiscount_1.PriceDiscountFromJSON)(json['discount']),
        'box': !(0, runtime_1.exists)(json, 'box') ? undefined : (0, PriceValue_1.PriceValueFromJSON)(json['box']),
        'valid': !(0, runtime_1.exists)(json, 'valid') ? undefined : json['valid'],
        'sku': !(0, runtime_1.exists)(json, 'sku') ? undefined : json['sku'],
    };
}
exports.SkuPriceValueFromJSONTyped = SkuPriceValueFromJSONTyped;
function SkuPriceValueToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'num': value.num,
        'str': value.str,
        'discount': (0, PriceDiscount_1.PriceDiscountToJSON)(value.discount),
        'box': (0, PriceValue_1.PriceValueToJSON)(value.box),
        'valid': value.valid,
        'sku': value.sku,
    };
}
exports.SkuPriceValueToJSON = SkuPriceValueToJSON;
