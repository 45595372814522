"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShopFilterInputToJSON = exports.ShopFilterInputFromJSONTyped = exports.ShopFilterInputFromJSON = exports.instanceOfShopFilterInput = void 0;
var runtime_1 = require("../runtime");
var FilterInputItem_1 = require("./FilterInputItem");
var FixedFilters_1 = require("./FixedFilters");
/**
 * Check if a given object implements the ShopFilterInput interface.
 */
function instanceOfShopFilterInput(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfShopFilterInput = instanceOfShopFilterInput;
function ShopFilterInputFromJSON(json) {
    return ShopFilterInputFromJSONTyped(json, false);
}
exports.ShopFilterInputFromJSON = ShopFilterInputFromJSON;
function ShopFilterInputFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'filters': !(0, runtime_1.exists)(json, 'filters') ? undefined : (json['filters'] === null ? null : json['filters'].map(FilterInputItem_1.FilterInputItemFromJSON)),
        'assortmentIds': !(0, runtime_1.exists)(json, 'assortmentIds') ? undefined : json['assortmentIds'],
        'fixedFilters': !(0, runtime_1.exists)(json, 'fixedFilters') ? undefined : (json['fixedFilters'] === null ? null : json['fixedFilters'].map(FixedFilters_1.FixedFiltersFromJSON)),
        'category': !(0, runtime_1.exists)(json, 'category') ? undefined : json['category'],
    };
}
exports.ShopFilterInputFromJSONTyped = ShopFilterInputFromJSONTyped;
function ShopFilterInputToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'filters': value.filters === undefined ? undefined : (value.filters === null ? null : value.filters.map(FilterInputItem_1.FilterInputItemToJSON)),
        'assortmentIds': value.assortmentIds,
        'fixedFilters': value.fixedFilters === undefined ? undefined : (value.fixedFilters === null ? null : value.fixedFilters.map(FixedFilters_1.FixedFiltersToJSON)),
        'category': value.category,
    };
}
exports.ShopFilterInputToJSON = ShopFilterInputToJSON;
