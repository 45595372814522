"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductStockSkuToJSON = exports.ProductStockSkuFromJSONTyped = exports.ProductStockSkuFromJSON = exports.instanceOfProductStockSku = void 0;
var runtime_1 = require("../runtime");
var CommDate_1 = require("./CommDate");
var PriceInformation_1 = require("./PriceInformation");
var ProductStock_1 = require("./ProductStock");
/**
 * Check if a given object implements the ProductStockSku interface.
 */
function instanceOfProductStockSku(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfProductStockSku = instanceOfProductStockSku;
function ProductStockSkuFromJSON(json) {
    return ProductStockSkuFromJSONTyped(json, false);
}
exports.ProductStockSkuFromJSON = ProductStockSkuFromJSON;
function ProductStockSkuFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': !(0, runtime_1.exists)(json, 'name') ? undefined : json['name'],
        'sku': !(0, runtime_1.exists)(json, 'sku') ? undefined : json['sku'],
        'sort': !(0, runtime_1.exists)(json, 'sort') ? undefined : json['sort'],
        'ean': !(0, runtime_1.exists)(json, 'ean') ? undefined : json['ean'],
        'inboxItems': !(0, runtime_1.exists)(json, 'inboxItems') ? undefined : json['inboxItems'],
        'availableDate': !(0, runtime_1.exists)(json, 'availableDate') ? undefined : (0, CommDate_1.CommDateFromJSON)(json['availableDate']),
        'stock': !(0, runtime_1.exists)(json, 'stock') ? undefined : (json['stock'] === null ? null : json['stock'].map(ProductStock_1.ProductStockFromJSON)),
        'price': !(0, runtime_1.exists)(json, 'price') ? undefined : (0, PriceInformation_1.PriceInformationFromJSON)(json['price']),
    };
}
exports.ProductStockSkuFromJSONTyped = ProductStockSkuFromJSONTyped;
function ProductStockSkuToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'sku': value.sku,
        'ean': value.ean,
        'inboxItems': value.inboxItems,
        'availableDate': (0, CommDate_1.CommDateToJSON)(value.availableDate),
        'stock': value.stock === undefined ? undefined : (value.stock === null ? null : value.stock.map(ProductStock_1.ProductStockToJSON)),
        'price': (0, PriceInformation_1.PriceInformationToJSON)(value.price),
    };
}
exports.ProductStockSkuToJSON = ProductStockSkuToJSON;
