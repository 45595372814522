"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OptionsToJSON = exports.OptionsFromJSONTyped = exports.OptionsFromJSON = exports.instanceOfOptions = void 0;
var runtime_1 = require("../runtime");
var DeliveryAddressInput_1 = require("./DeliveryAddressInput");
/**
 * Check if a given object implements the Options interface.
 */
function instanceOfOptions(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfOptions = instanceOfOptions;
function OptionsFromJSON(json) {
    return OptionsFromJSONTyped(json, false);
}
exports.OptionsFromJSON = OptionsFromJSON;
function OptionsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'deliveryAddresses': !(0, runtime_1.exists)(json, 'deliveryAddresses') ? undefined : (json['deliveryAddresses'] === null ? null : json['deliveryAddresses'].map(DeliveryAddressInput_1.DeliveryAddressInputFromJSON)),
    };
}
exports.OptionsFromJSONTyped = OptionsFromJSONTyped;
function OptionsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'deliveryAddresses': value.deliveryAddresses === undefined ? undefined : (value.deliveryAddresses === null ? null : value.deliveryAddresses.map(DeliveryAddressInput_1.DeliveryAddressInputToJSON)),
    };
}
exports.OptionsToJSON = OptionsToJSON;
