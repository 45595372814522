import ProductList from 'components/Product/ProductList';
import Breadcrumbs from 'components/ui/Breadcrumbs';
import Flex from 'components/ui/Flex';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import { useEffect } from 'react';
import { getFilters, loadProducts } from 'store/reducers/assortmentSlice';

const Products = () => {
    const { products, assortmentPageInfo, filtersShow, loading, categories, currentPathName } = useAppSelector(
        ({ assortment }) => assortment,
    );

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(loadProducts());
        dispatch(getFilters());
    }, [dispatch, categories, currentPathName]);

    return (
        <Flex column>
            <Breadcrumbs className="pb-2" activePage={currentPathName} />
            <h2 className="px-3 f2-700">{categories[currentPathName]?.name ?? ''}</h2>
            <ProductList
                products={products}
                assortmentPageInfo={assortmentPageInfo}
                loading={loading}
                enableFilters={true}
                showFilters={filtersShow}
            />
        </Flex>
    );
};
export default Products;
