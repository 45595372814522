import { useTranslation } from 'react-i18next';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

const ShopError = ({ errorKey }: { errorKey: string }) => {
    const { t } = useTranslation();

    return (
        <Modal isOpen={true} centered={true}>
            <ModalHeader>{t('error')}</ModalHeader>
            <ModalBody>{t(errorKey)}</ModalBody>
        </Modal>
    );
};

export default ShopError;
