import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import useOnClickOutside from 'hooks/useCloseOnClickOutside';
import { useRef } from 'react';
import styled, { ThemeColors } from 'styled-components';
import Flex from './Flex';
import HeaderIconButton from './HeaderIconButton';

type Props = {
    headerColor?: keyof ThemeColors;
    visible?: boolean;
    childSlideIn?: boolean;
    children: {
        headerLogo?: React.ReactNode;
        headerContent?: React.ReactNode;
        content?: React.ReactNode;
        footerContent?: React.ReactNode;
    };
    onClose: () => void;
};

const SlideIn = ({ children, onClose, headerColor, visible, childSlideIn }: Props) => {
    const ref = useOnClickOutside(onClose, visible, 'slide-in-wrapper');
    const scrollRef = useRef<HTMLDivElement>(null);

    return (
        <Wrapper column ref={ref} visible={visible} childSlideIn={childSlideIn} className="slide-in-wrapper">
            <Header className="p-3" justify="between" align="center" bgColor={headerColor}>
                {children.headerLogo ? children.headerLogo : null}
                {children.headerContent ? children.headerContent : null}
                <Close onClick={onClose} icon={faTimes} bgColor={'transparent'} />
            </Header>
            <Inner column ref={scrollRef}>
                {children.content}
            </Inner>
            {children.footerContent !== 0 && <Footer>{children.footerContent}</Footer>}
        </Wrapper>
    );
};

const Wrapper = styled(Flex)<{ visible?: boolean; childSlideIn?: boolean }>`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    overflow: auto;
    overscroll-behavior: contain;
    background-color: ${({ theme }) => theme.colors.fillWhite};
    z-index: ${({ childSlideIn }) => (childSlideIn ? 1001 : 1000)};
    transition: opacity 0.2s ease, transform 0.2s ease-in-out, visibility 0.2s ease;

    ${({ visible }) =>
        visible
            ? `
        opacity: 1;
        transform: none;
        visibility: visible;
        `
            : `
        opacity: 0;
        transform: translateX(220px);
        visibility: hidden;
      `};

    ${({ theme }) => theme.media.lg} {
        ${({ theme }) => theme.shadows.soft};
        border-radius: 4px;
        width: 420px;
        height: calc(100vh - 4rem);
        top: 2rem;
        right: ${({ childSlideIn }) => (childSlideIn ? '26rem' : '0rem')};
        z-index: ${({ childSlideIn }) => (childSlideIn ? 1000 : 1001)};
    }
`;

const Header = styled(Flex)<{ bgColor?: keyof ThemeColors }>`
    background-color: ${({ theme, bgColor }) => (bgColor ? theme.colors[bgColor] : theme.main)};
    ${({ theme }) => theme.borders.underline1};
    ${({ theme }) => theme.colors.fill};
    height: 71px;
    border-color: ${({ theme }) => theme.colors.border};
`;

const Close = styled(HeaderIconButton)``;

const Inner = styled(Flex)`
    flex-grow: 1;
    color: ${(props) => props.theme.colors.text};
    background-color: ${({ theme }) => theme.colors.fillWhite};
    -webkit-overflow-scrolling: touch;
    overscroll-behavior: contain;
    overflow: auto;
`;

const Footer = styled.div`
    border-top: 1px solid ${({ theme }) => theme.colors.fill};
`;

export default SlideIn;
