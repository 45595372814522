import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Identity } from 'microshop-api';
import services from 'services';
import { RootState } from 'store';

type UserState = {
    pending: boolean;
    userIdentity?: Identity | null;
    // priceViewSetting: PriceViewSetting;
    // priceLists: CustomerPriceList[];
    // customerUsers: {
    //     rejected?: string;
    //     loading: boolean;
    //     users: UserInfo[];
    // };
    // orderSubscriptions: {
    //     rejected?: boolean;
    //     loading: boolean;
    //     subscriptions: WebOrderSubscription[];
    // };
    // successfullyAuthenticated: boolean;
    // sellingPending: boolean;
};

const initialState: UserState = {
    pending: false,
    // successfullyAuthenticated: false,
    // priceViewSetting: PriceViewSetting.Retail,
    // priceLists: [],
    // customerUsers: {
    //     loading: false,
    //     users: [],
    // },
    // orderSubscriptions: {
    //     loading: false,
    //     subscriptions: [],
    // },
    // sellingPending: false,
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(loadCustomer.pending, (state) => {
            state.pending = true;
        });
        builder.addCase(loadCustomer.rejected, (state) => {
            state.pending = false;
        });
        builder.addCase(loadCustomer.fulfilled, (state, action) => {
            state.pending = false;
            state.userIdentity = action.payload;
        });
    },
});

export const loadCustomer = createAsyncThunk<
    Identity | null,
    void,
    {
        state: RootState;
    }
>('user/customer', async (_, { getState, rejectWithValue }) => {
    const isB2BShop = getState().shop.shop?.isB2BShop;

    try {
        if (isB2BShop) {
            return services.customer.customerGet();
        }
        return null;
    } catch (e) {
        return rejectWithValue({ error: 'An exception occured when loading the customer' });
    }
});

export const {} = userSlice.actions;

export default userSlice.reducer;
