import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components/macro';

interface FilterBoxProps {
    text: string | null | undefined;
    onRemove: () => void;
    className?: string;
}
const FilterBox: React.FC<FilterBoxProps> = ({ text, onRemove, className }) => {
    const themeContext = useContext(ThemeContext);

    return (
        <Box className={`d-inline-block px-2 py-1 me-1 ${className}`} onClick={onRemove}>
            <Text>{text}</Text>
            <FontAwesomeIcon
                className="ms-2"
                size="sm"
                fontSize={14}
                icon={faTimes}
                color={themeContext.colors.textLight}
            />
        </Box>
    );
};

const Box = styled.button`
    border: none;
    background-color: ${({ theme }) => theme.colors.transparent};
    transition: background-color 0.2s ease;
    :hover {
        background-color: ${(props) => props.theme.colors.fill};
    }
`;

const Text = styled.span`
    font-size: 12px;
    color: ${({ theme }) => theme.colors.textLight};
`;

export default FilterBox;
