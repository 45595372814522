import { faCalendarAlt } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useAppSelector from 'hooks/useAppSelector';
import React, { forwardRef } from 'react';
import ReactDatePicker, { CalendarContainer } from 'react-datepicker';
import styled, { ThemeColors } from 'styled-components/macro';
import Input from './Input';

type Filter = 'None' | 'Weekdays' | 'Future' | 'History';

type DatepickerProps = {
    selectedDate?: Date | null;
    startDate?: Date;
    endDate?: Date;
    onChange: (date: Date | [Date, Date] | null, event: React.SyntheticEvent<any, Event>) => void;
    header?: string;
    customInput?: React.ReactNode;
    className?: string;
    bgColor?: keyof ThemeColors;
    showIcon?: boolean;
    filter?: Filter;
    autofocus?: boolean;
    selectsRange?: boolean;
    minDate?: Date;
};
const Datepicker: React.FC<DatepickerProps> = ({
    startDate,
    endDate,
    selectedDate,
    onChange,
    header,
    customInput,
    className,
    bgColor,
    showIcon,
    filter = 'Weekdays',
    autofocus,
    selectsRange,
    minDate,
}) => {
    const language = useAppSelector(({ shop }) => shop.info?.language);

    const DatepickerActions = ({ className, children }: { className: string; children: React.ReactNode[] }) => {
        return (
            <>
                <Header className="f1-400 mb-1">{header}</Header>
                <CalendarContainer className={className}>{children}</CalendarContainer>
            </>
        );
    };

    const DatePickerInput = forwardRef(
        (props: React.InputHTMLAttributes<HTMLInputElement>, ref: React.Ref<HTMLInputElement>) => (
            <DateWrapper>
                <DateInput
                    ref={ref}
                    autoFocus={autofocus}
                    onClick={props.onClick}
                    bgColor={bgColor}
                    lightness={0.8}
                    className={`${props.className} ${showIcon ? 'text-left' : ''}`}
                    value={(props.value ?? '') as string}
                    onChange={props.onChange}
                />
                {showIcon && (
                    <IconWrapper onClick={props.onClick}>
                        <Icon icon={faCalendarAlt} />
                    </IconWrapper>
                )}
            </DateWrapper>
        ),
    );

    return (
        <ReactDatePicker
            className={className}
            calendarContainer={header ? DatepickerActions : CalendarContainer}
            filterDate={getFilter(filter)}
            selected={selectedDate}
            startDate={startDate}
            endDate={endDate}
            locale={language || 'en'}
            dateFormat="P"
            minDate={minDate}
            customInput={customInput ? <CustomInput>{customInput}</CustomInput> : <DatePickerInput />}
            onChange={onChange}
            showPopperArrow={false}
            popperPlacement="bottom-end"
            selectsRange={selectsRange}
        />
    );
};

function getFilter(type?: Filter) {
    if (!type || type === 'None') return;

    if (type === 'Weekdays') return filterWeekdays;
    if (type === 'History') return filterHistory;
    if (type === 'Future') return filterFuture;
}

const filterWeekdays = (date: Date) => {
    const day = date.getDay();
    return day !== 0 && day !== 6;
};

const filterFuture = (date: Date) => {
    const currentDate = new Date();
    return currentDate < date;
};

const filterHistory = (date: Date) => {
    const currentDate = new Date();
    return currentDate > date;
};

export default Datepicker;

const DateWrapper = styled.div`
    left: 200px;
`;
const IconWrapper = styled.div`
    position: absolute;
    right: 15px;
    width: 14px;
    display: flex;
    top: 0;
    height: calc(100% - 2px);
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

const Icon = styled(FontAwesomeIcon)`
    color: ${({ theme }) => theme.colors.textLight};
    font-size: 16px;
`;

const DateInput = styled(Input)<{ bgColor?: keyof ThemeColors }>`
    width: 100%;
`;

const Header = styled.div`
    background: ${({ theme }) => theme.colors.fillWhite};
    color: ${({ theme }) => theme.colors.text};
    text-align: center;
    font-size: 13px;
    padding: 1rem;
    ${({ theme }) => theme.shadows.soft};
`;

const CustomInput = styled.div``;
