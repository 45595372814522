import { darken } from 'polished';
import { createGlobalStyle } from 'styled-components/macro';

export const ReactDatepicker = createGlobalStyle`
  .react-datepicker {
    border:1px solid ${({ theme }) => theme.colors.borderLight};
    border-radius: 0;
    border: 0;
    ${({ theme }) => theme.shadows.soft};
    font-size: 0.9rem;
  }

  .react-datepicker__header {
    border-top-right-radius: 0 !important;
    border-top-left-radius: 0 !important;
    padding: 16px 0 8px;
    border: 0;
    background: ${({ theme }) => theme.colors.fillWhite};
  }

  .react-datepicker__navigation {
    top: 16px;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    background: ${({ theme }) => theme.colors.accent};
    color: ${({ theme }) => theme.colors.accentText};

    &:hover {
      color: ${({ theme }) => theme.colors.accentText};
      background: ${({ theme }) => darken(0.08, theme.colors.accent)}
    }
  }
`;
