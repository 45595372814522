"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CartCustomProductToJSON = exports.CartCustomProductFromJSONTyped = exports.CartCustomProductFromJSON = exports.instanceOfCartCustomProduct = void 0;
var runtime_1 = require("../runtime");
/**
 * Check if a given object implements the CartCustomProduct interface.
 */
function instanceOfCartCustomProduct(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfCartCustomProduct = instanceOfCartCustomProduct;
function CartCustomProductFromJSON(json) {
    return CartCustomProductFromJSONTyped(json, false);
}
exports.CartCustomProductFromJSON = CartCustomProductFromJSON;
function CartCustomProductFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !(0, runtime_1.exists)(json, 'id') ? undefined : json['id'],
        'inputs': !(0, runtime_1.exists)(json, 'inputs') ? undefined : json['inputs'],
        'quantity': !(0, runtime_1.exists)(json, 'quantity') ? undefined : json['quantity'],
    };
}
exports.CartCustomProductFromJSONTyped = CartCustomProductFromJSONTyped;
function CartCustomProductToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'inputs': value.inputs,
        'quantity': value.quantity,
    };
}
exports.CartCustomProductToJSON = CartCustomProductToJSON;
