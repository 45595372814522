import { CartItemSkuAddOn, CartSummary } from 'microshop-api';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import CartRowProduct from './CartRowProduct';

export type QuantityChangedFunction = (input: {
    sku: string | number;
    qty: number;
    addons?: CartItemSkuAddOn[];
    collectionId?: number | null;
    split?: string | null;
    immediate?: boolean;
}) => void;

type CartItemsProps = {
    onChangeQuantity: QuantityChangedFunction;
    onRemove: (sku: string) => void;
    onSetComment: (sku: string, comment: string) => void;
    forceSize?: any;
    cart: CartSummary;
};

const CartItems = ({ onChangeQuantity, onRemove, onSetComment, cart, forceSize }: CartItemsProps) => {
    const { t } = useTranslation();
    if (!cart) return null;
    // const invalidProducts = !!cart.items?.find((p) => p.variations?.find((v) => v.invalid));

    return (
        <>
            <SubHeader className="f1-700">{t('cart')}</SubHeader>
            <ListWrapper className="f1-500 w-100">
                {cart.items?.map((product, i) => {
                    return (
                        <CartRowProduct
                            onChangeQuantity={onChangeQuantity}
                            onRemove={onRemove}
                            onSetComment={onSetComment}
                            key={`${product.number}_${i}`}
                            product={product}
                            forceSize={forceSize}
                        />
                    );
                })}

                {/* {invalidProducts && (
                <InvalidProductsWrapper align="center">
                <CellIcon color="red" icon={faExclamationTriangle} className="ms-2 me-3" />
                <InfoText>
                {t(
                    'cart.errorInvalidProducts',
                    'Some products are not compatible in the same order. The invalid products will remain in the cart but not included in the current order.',
                    )}
                    </InfoText>
                    </InvalidProductsWrapper> 
                    )}
                */}
            </ListWrapper>
        </>
    );
};

export default CartItems;

const SubHeader = styled.h4`
    font-size: 22px;
`;
// const CellIcon = styled(FontAwesomeIcon)`
//     font-size: 16px;
// `;
const ListWrapper = styled.div`
    font-size: 12px;
`;

// const InvalidProductsWrapper = styled(Flex)<{ small?: boolean }>`
//     font-size: 12px;
//     padding: 25px 0;
//     ${({ small }) => small && 'max-width: 420px;'}
// `;

// const InfoText = styled.div`
//     color: ${({ theme }) => theme.colors.textLight};
// `;
