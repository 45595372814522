import { lighten } from 'polished';
import styled, { ThemeColors } from 'styled-components/macro';

type LinkStyleProps = {
    color?: keyof ThemeColors;
    fontSize?: string;
};
const LinkStyle = styled.span<LinkStyleProps>`
    text-decoration: underline;
    color: ${({ theme, color }) => (color ? theme.colors[color] : theme.colors.text)};
    font-size: ${({ fontSize }) => (fontSize ? fontSize : 'initial')};
    cursor: pointer;
    :hover,
    :focus,
    :visited {
        color: ${({ theme, color }) => lighten(0.1, color ? theme.colors[color] : theme.colors.text)};
    }
`;

export default LinkStyle;
