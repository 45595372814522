import ProductDetailPage from 'components/Product/ProductDetailPage';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import { useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useParams } from 'react-router-dom';
import { getProduct } from 'store/reducers/productSlice';
import ShopError from './ShopError';

const Product = () => {
    const { productNumber } = useParams();
    const { product, loading, error } = useAppSelector(({ shop, product }) => ({ ...shop, ...product }));
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (productNumber) {
            dispatch(getProduct({ pnrOrSlug: productNumber! }));
        }
    }, [dispatch, productNumber]);

    if (loading) {
        return <Skeleton height={500} className="w-100 me-2" />;
    }

    if (error) {
        return <ShopError errorKey="product_not_found" />;
    }

    return product ? <ProductDetailPage /> : null;
};
export default Product;
