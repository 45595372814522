"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppContextSettingLanguageToJSON = exports.AppContextSettingLanguageFromJSONTyped = exports.AppContextSettingLanguageFromJSON = exports.instanceOfAppContextSettingLanguage = void 0;
var runtime_1 = require("../runtime");
/**
 * Check if a given object implements the AppContextSettingLanguage interface.
 */
function instanceOfAppContextSettingLanguage(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfAppContextSettingLanguage = instanceOfAppContextSettingLanguage;
function AppContextSettingLanguageFromJSON(json) {
    return AppContextSettingLanguageFromJSONTyped(json, false);
}
exports.AppContextSettingLanguageFromJSON = AppContextSettingLanguageFromJSON;
function AppContextSettingLanguageFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'description': !(0, runtime_1.exists)(json, 'description') ? undefined : json['description'],
        'name': !(0, runtime_1.exists)(json, 'name') ? undefined : json['name'],
        'language': !(0, runtime_1.exists)(json, 'language') ? undefined : json['language'],
    };
}
exports.AppContextSettingLanguageFromJSONTyped = AppContextSettingLanguageFromJSONTyped;
function AppContextSettingLanguageToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'description': value.description,
        'name': value.name,
        'language': value.language,
    };
}
exports.AppContextSettingLanguageToJSON = AppContextSettingLanguageToJSON;
