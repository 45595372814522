"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListProductToJSON = exports.ListProductFromJSONTyped = exports.ListProductFromJSON = exports.instanceOfListProduct = void 0;
var runtime_1 = require("../runtime");
var CustomTag_1 = require("./CustomTag");
var ListVariation_1 = require("./ListVariation");
var PriceInformation_1 = require("./PriceInformation");
var ProductImage_1 = require("./ProductImage");
var TagType_1 = require("./TagType");
/**
 * Check if a given object implements the ListProduct interface.
 */
function instanceOfListProduct(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfListProduct = instanceOfListProduct;
function ListProductFromJSON(json) {
    return ListProductFromJSONTyped(json, false);
}
exports.ListProductFromJSON = ListProductFromJSON;
function ListProductFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'productName': !(0, runtime_1.exists)(json, 'productName') ? undefined : json['productName'],
        'productBrandName': !(0, runtime_1.exists)(json, 'productBrandName') ? undefined : json['productBrandName'],
        'productNumber': !(0, runtime_1.exists)(json, 'productNumber') ? undefined : json['productNumber'],
        'image': !(0, runtime_1.exists)(json, 'image') ? undefined : (0, ProductImage_1.ProductImageFromJSON)(json['image']),
        'variations': !(0, runtime_1.exists)(json, 'variations') ? undefined : (json['variations'] === null ? null : json['variations'].map(ListVariation_1.ListVariationFromJSON)),
        'hasVariations': !(0, runtime_1.exists)(json, 'hasVariations') ? undefined : json['hasVariations'],
        'pictures': !(0, runtime_1.exists)(json, 'pictures') ? undefined : (json['pictures'] === null ? null : json['pictures'].map(ProductImage_1.ProductImageFromJSON)),
        'slug': !(0, runtime_1.exists)(json, 'slug') ? undefined : json['slug'],
        'availableFrom': !(0, runtime_1.exists)(json, 'availableFrom') ? undefined : (new Date(json['availableFrom'])),
        'price': !(0, runtime_1.exists)(json, 'price') ? undefined : (0, PriceInformation_1.PriceInformationFromJSON)(json['price']),
        'tags': !(0, runtime_1.exists)(json, 'tags') ? undefined : (json['tags'] === null ? null : json['tags'].map(TagType_1.TagTypeFromJSON)),
        'productInternalNumber': !(0, runtime_1.exists)(json, 'productInternalNumber') ? undefined : json['productInternalNumber'],
        'customTags': !(0, runtime_1.exists)(json, 'customTags') ? undefined : (json['customTags'] === null ? null : json['customTags'].map(CustomTag_1.CustomTagFromJSON)),
        'gender': !(0, runtime_1.exists)(json, 'gender') ? undefined : json['gender'],
        'color': !(0, runtime_1.exists)(json, 'color') ? undefined : json['color'],
    };
}
exports.ListProductFromJSONTyped = ListProductFromJSONTyped;
function ListProductToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'productName': value.productName,
        'productBrandName': value.productBrandName,
        'productNumber': value.productNumber,
        'image': (0, ProductImage_1.ProductImageToJSON)(value.image),
        'variations': value.variations === undefined ? undefined : (value.variations === null ? null : value.variations.map(ListVariation_1.ListVariationToJSON)),
        'pictures': value.pictures === undefined ? undefined : (value.pictures === null ? null : value.pictures.map(ProductImage_1.ProductImageToJSON)),
        'availableFrom': value.availableFrom === undefined ? undefined : (value.availableFrom.toISOString()),
        'price': (0, PriceInformation_1.PriceInformationToJSON)(value.price),
        'tags': value.tags === undefined ? undefined : (value.tags === null ? null : value.tags.map(TagType_1.TagTypeToJSON)),
        'productInternalNumber': value.productInternalNumber,
        'customTags': value.customTags === undefined ? undefined : (value.customTags === null ? null : value.customTags.map(CustomTag_1.CustomTagToJSON)),
        'gender': value.gender,
        'color': value.color,
    };
}
exports.ListProductToJSON = ListProductToJSON;
