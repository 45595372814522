"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DocumentToJSON = exports.DocumentFromJSONTyped = exports.DocumentFromJSON = exports.instanceOfDocument = void 0;
var runtime_1 = require("../runtime");
/**
 * Check if a given object implements the Document interface.
 */
function instanceOfDocument(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfDocument = instanceOfDocument;
function DocumentFromJSON(json) {
    return DocumentFromJSONTyped(json, false);
}
exports.DocumentFromJSON = DocumentFromJSON;
function DocumentFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': !(0, runtime_1.exists)(json, 'name') ? undefined : json['name'],
        'url': !(0, runtime_1.exists)(json, 'url') ? undefined : json['url'],
        'type': !(0, runtime_1.exists)(json, 'type') ? undefined : json['type'],
    };
}
exports.DocumentFromJSONTyped = DocumentFromJSONTyped;
function DocumentToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {};
}
exports.DocumentToJSON = DocumentToJSON;
