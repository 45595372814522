import Flex from 'components/ui/Flex';
import { ListProduct } from 'microshop-api';
import { useRef } from 'react';
import { AssortmentPageInfo } from 'store/reducers/assortmentSlice';
import styled from 'styled-components/macro';
import ProductCardList from './ProductCardList';
import ProductFilter from './ProductFilter';
import ProductListHeader from './ProductListHeader';

const ProductList = ({
    products,
    assortmentPageInfo,
    loading,
    enableFilters,
    showFilters,
    center,
}: {
    products: ListProduct[];
    assortmentPageInfo?: AssortmentPageInfo;
    loading: boolean;
    enableFilters?: boolean;
    showFilters?: boolean;
    center?: boolean;
}) => {
    const stickyRef = useRef<HTMLDivElement>(null);

    return (
        <Flex className="w-100 px-3 mx-0" ref={stickyRef}>
            {enableFilters && <ProductFilter offsetTop={91} />}
            <ProductListWrapper showFilters={showFilters}>
                {enableFilters && <ProductListHeader />}
                <ProductSearchResult
                    center={center}
                    loading={loading}
                    products={products}
                    assortmentPageInfo={assortmentPageInfo}
                />
            </ProductListWrapper>
        </Flex>
    );
};

interface IProductSearchResult {
    loading: boolean;
    products: ListProduct[];
    assortmentPageInfo?: AssortmentPageInfo;
    category?: any;
    categoryPath?: any;
    center?: boolean;
}

const ProductSearchResult: React.FC<IProductSearchResult> = ({
    loading,
    products,
    assortmentPageInfo,
    category,
    categoryPath,
    center,
}) => {
    return (
        <ProductCardList
            products={products}
            assortmentPageInfo={assortmentPageInfo}
            pending={loading}
            morePending={false}
            category={category}
            categoryPath={categoryPath}
            center={center}
            gtm
        />
    );
};

const ProductListWrapper = styled.div<{ showFilters?: boolean }>`
    flex-grow: 1;
    /* max-width: ${({ showFilters }) => (showFilters ? 'calc(100% - 275px)' : '100%')}; */
`;

export default ProductList;
