import Input from 'components/ui/Input';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { CartDelivery, deliverySet } from 'store/reducers/cartSlice';
import styled from 'styled-components/macro';
import { Valid } from './ValidIcon';

type DeliveryInfoProps = {};

export const DeliveryInfo: React.FC<DeliveryInfoProps> = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const delivery = useAppSelector(({ cart }) => cart.b2bCheckout.delivery);
    const valid = useAppSelector(({ cart }) => cart.b2bCheckout.valid.info);

    const setTextDelivery = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(
            deliverySet({
                name: event.target.name as keyof CartDelivery,
                value: event.target.value,
            }),
        );
    };

    return (
        <DeliveryWrapper>
            <Row>
                <DeliveryCol md="12">
                    <Row>
                        <Col md={6} className="mt-2 form-group">
                            <Input
                                label={t('shipment.packageLabel', 'Package label')}
                                name="packageLabel"
                                value={delivery?.packageLabel ?? ''}
                                onChange={setTextDelivery}
                                required={false}
                                validate={valid === Valid.Invalid}
                                maxLength={30}
                            />
                            {/* {cmsOptions.packageLabelDescription && (
                                <FieldDescription>{cmsOptions.packageLabelDescription}</FieldDescription>
                            )} */}
                        </Col>
                        <Col md={6} className="mt-2 form-group">
                            <Input
                                label={t('shipment.yourReference', 'Your reference')}
                                name="reference"
                                value={delivery?.reference ?? ''}
                                onChange={setTextDelivery}
                                required={false}
                                validate={valid === Valid.Invalid}
                                showValidateIcon={false}
                                maxLength={20}
                            />
                            {/* {cmsOptions.referenceDescription && (
                                <FieldDescription>{cmsOptions.referenceDescription}</FieldDescription>
                            )} */}
                        </Col>
                        <Col md={6} className="mt-2 form-group">
                            <Input
                                label={t('shipment.yourOrderNumber', 'Your ordernumber')}
                                name="orderReference"
                                value={delivery?.orderReference ?? ''}
                                onChange={setTextDelivery}
                                type="text"
                                required={false}
                                validate={valid === Valid.Invalid}
                                maxLength={35}
                            />
                            {/* {cmsOptions.yourOrderNumberDescription && (
                                <FieldDescription>{cmsOptions.yourOrderNumberDescription}</FieldDescription>
                            )} */}
                        </Col>
                        <Col md={12} className="mt-2 form-group">
                            <Input
                                label={t('general.comment', 'Comment')}
                                name="comment"
                                value={delivery?.comment ?? ''}
                                onChange={setTextDelivery}
                                type="text"
                                required={false}
                                validate={valid === Valid.Invalid}
                            />
                            {/* {cmsOptions.orderMessageDescription && (
                                <FieldDescription className="mt-2">
                                    {cmsOptions.orderMessageDescription}
                                </FieldDescription>
                            )} */}
                        </Col>
                    </Row>
                </DeliveryCol>
            </Row>
        </DeliveryWrapper>
    );
};
export default DeliveryInfo;

const DeliveryWrapper = styled.div``;

const DeliveryCol = styled(Col)`
    .form-group {
        margin-bottom: 0.5rem;
    }
`;

const FieldDescription = styled.div`
    font-size: 12px;
    color: ${({ theme }) => theme.colors.textFaded};
`;
