"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.MarkToJSON = exports.MarkFromJSONTyped = exports.MarkFromJSON = exports.instanceOfMark = void 0;
var runtime_1 = require("../runtime");
var ProductImage_1 = require("./ProductImage");
/**
 * Check if a given object implements the Mark interface.
 */
function instanceOfMark(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfMark = instanceOfMark;
function MarkFromJSON(json) {
    return MarkFromJSONTyped(json, false);
}
exports.MarkFromJSON = MarkFromJSON;
function MarkFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'text': !(0, runtime_1.exists)(json, 'text') ? undefined : json['text'],
        'imageKey': !(0, runtime_1.exists)(json, 'imageKey') ? undefined : json['imageKey'],
        'image': !(0, runtime_1.exists)(json, 'image') ? undefined : (0, ProductImage_1.ProductImageFromJSON)(json['image']),
    };
}
exports.MarkFromJSONTyped = MarkFromJSONTyped;
function MarkToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'text': value.text,
        'imageKey': value.imageKey,
        'image': (0, ProductImage_1.ProductImageToJSON)(value.image),
    };
}
exports.MarkToJSON = MarkToJSON;
