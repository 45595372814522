import styled from 'styled-components/macro';

const flexValues: any = {
    between: 'space-between',
    start: 'flex-start',
    end: 'flex-end',
    center: 'center',
    stretch: 'stretch',
    baseline: 'baseline',
};

interface IFlex {
    align?: 'center' | 'start' | 'end' | 'stretch' | 'baseline';
    justify?: 'between' | 'start' | 'end' | 'center';
    column?: boolean;
    $wrap?: boolean;
    $grow?: number;
    $reverse?: boolean;
}

const Flex = styled.div<IFlex>`
    display: flex;
    ${({ column, $reverse }) =>
        (column || $reverse) && `flex-direction: ${column ? ($reverse ? 'column-reverse' : 'column') : 'row-reverse'}`};
    ${({ align }) => align && `align-items: ${flexValues[align]};`}
    ${({ justify }) => justify && `justify-content: ${flexValues[justify]}`};
    ${({ $wrap }) => $wrap && `flex-wrap: wrap`};
    ${({ $grow }) => $grow && `flex-grow: ${$grow}`};
`;

export default Flex;
