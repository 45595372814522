"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrderDetailsToJSON = exports.OrderDetailsFromJSONTyped = exports.OrderDetailsFromJSON = exports.instanceOfOrderDetails = void 0;
var runtime_1 = require("../runtime");
/**
 * Check if a given object implements the OrderDetails interface.
 */
function instanceOfOrderDetails(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfOrderDetails = instanceOfOrderDetails;
function OrderDetailsFromJSON(json) {
    return OrderDetailsFromJSONTyped(json, false);
}
exports.OrderDetailsFromJSON = OrderDetailsFromJSON;
function OrderDetailsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'orderType': !(0, runtime_1.exists)(json, 'orderType') ? undefined : json['orderType'],
        'transportType': !(0, runtime_1.exists)(json, 'transportType') ? undefined : json['transportType'],
        'completeDelivery': !(0, runtime_1.exists)(json, 'completeDelivery') ? undefined : json['completeDelivery'],
        'dispatchNotification': !(0, runtime_1.exists)(json, 'dispatchNotification') ? undefined : json['dispatchNotification'],
        'confirmedNotification': !(0, runtime_1.exists)(json, 'confirmedNotification') ? undefined : json['confirmedNotification'],
    };
}
exports.OrderDetailsFromJSONTyped = OrderDetailsFromJSONTyped;
function OrderDetailsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'orderType': value.orderType,
        'transportType': value.transportType,
        'completeDelivery': value.completeDelivery,
        'dispatchNotification': value.dispatchNotification,
        'confirmedNotification': value.confirmedNotification,
    };
}
exports.OrderDetailsToJSON = OrderDetailsToJSON;
