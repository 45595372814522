import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components/macro';

type UspProps = {
    usps: string[];
    className?: string;
};
const Usp: React.FC<UspProps> = ({ className, usps }) => {
    return (
        <Wrapper className={`${className ? className : ''}`}>
            <List className="f1-500">
                {usps.map((usp, i) => (
                    <li className="mb-3" key={'usp_' + i}>
                        <FontAwesomeIcon className="me-3" size="lg" aria-hidden="true" icon={faCheck} />
                        {usp}
                    </li>
                ))}
            </List>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    background: ${({ theme }) => theme.colors.accent};
    color: ${({ theme }) => theme.colors.accentText};

    ${({ theme }) => theme.media.xl} {
        width: 200%;
        padding-right: 100% !important;
    }
`;

const List = styled.ul`
    list-style-type: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 18px;
    padding: 28px 20px 20px 20px;
    padding: 2em 1.5em 1em 1.5em;
    margin: 0;
`;

export default Usp;
